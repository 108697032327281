import { Tab, Tabs } from 'react-bootstrap';
import classes from './SubmitOffer.module.scss';
import { Input } from '../../../components/Tools/useFormComponents/exports';
import Documents from './Documents';
import { TextArea } from '../../../components/Tools/useFormComponents/TextArea/TextArea';
import { useFieldArray } from 'react-hook-form';
import { useEffect, useState } from 'react';
import Button from '../../../components/UI/Button/Button';
import { v4 } from 'uuid';
import CloseIcon from '../../../assets/icons/CloseAccent.svg';

type Props = {
  t_product: any;
  t: any;
  register: any;
  productId: number;
  getFieldState: any;
  control: any;
  getValues: any;
  errors: any;
  isAuction: boolean;
};

function OfferTab({ t_product, t, register, productId, getFieldState, control, errors, isAuction }: Props) {
  const [tab, setTab] = useState(0);

  const handleAddOffer = () => {
    append({ catalog_number: '', quantity: 1, price: 1, comment: '', delivery_due_days: 1, documents: [], id: v4() });
  };

  const { fields, append, remove, update, isValid }: any = useFieldArray({
    control: control,
    name: `productItems.${productId}.offers`,
    keyName: 'id',
  });

  useEffect(() => {
    if (isValid) {
      return;
    }

    for (const offer of fields) {
      if (
        errors.productItems &&
        errors.productItems[productId] &&
        errors.productItems[productId].hasOwnProperty('offers')
      ) {
        for (let i = 0; i < errors.productItems[productId].offers.length; i++) {
          if (errors.productItems[productId].offers[i]) {
            setTab(i);
            break;
          }
        }
      }
    }
  }, [errors]);

  return (
    <>
      {!isAuction && (
        <div className={classes.add_offer}>
          <Button
            style={{
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '16px',
              color: '#0044bb',
              cursor: 'pointer',
              textAlign: 'center',
              padding: '6px 0px',
              marginRight: '25px',
            }}
            onClick={() => {
              handleAddOffer();
            }}
            label={t_product.add_offer}
            noDefaultStyle
            class="ee"
          />
        </div>
      )}
      <Tabs defaultActiveKey={tab} activeKey={tab} onSelect={key => key && setTab(Number(key))}>
        {fields &&
          fields?.map((offer, id) => {
            return (
              <Tab
                eventKey={id}
                title={
                  <span>
                    {t_product.offer} {id + 1}{' '}
                    <img
                      style={id === 0 ? { display: 'none' } : {}}
                      className={classes.close_icon}
                      src={CloseIcon}
                      onClick={() => {
                        remove(id);
                      }}
                      alt="Close"
                    />
                  </span>
                }
                key={offer.id}
              >
                <div className={classes.label}>{t_product.offer_details}</div>
                <div className={classes.space}>
                  <div className={classes.split_form}>
                    <div className={classes.split_form_inner}>
                      <Input
                        label={t.offers.due_date_offered}
                        {...register(`productItems.${productId}.offers.${id}.delivery_due_days`)}
                        error={getFieldState(`productItems.${productId}.offers.${id}.delivery_due_days`).error?.message}
                      />
                    </div>
                    <div className={classes.split_form_inner}>
                      <Input
                        label={t.offers.catalog_number}
                        {...register(`productItems.${productId}.offers.${id}.catalog_number`)}
                        error={getFieldState(`productItems.${productId}.offers.${id}.catalog_number`).error?.message}
                      />
                    </div>
                  </div>
                  <div className={`${classes.quantity_price} ${classes.split_form}`}>
                    <div className={`${classes.price_wrapper} ${classes.split_form_inner}`}>
                      <Input
                        label={t_product.price}
                        {...register(`productItems.${productId}.offers.${id}.price`)}
                        error={getFieldState(`productItems.${productId}.offers.${id}.price`).error?.message}
                        customInputWidth="85%"
                      />
                      <div className={classes.currency}>({t.currencies.MKD})</div>
                    </div>

                    <div className={classes.split_form_inner}>
                      <Input
                        label={t.offers.offered_quantity}
                        {...register(`productItems.${productId}.offers.${id}.quantity`)}
                        error={getFieldState(`productItems.${productId}.offers.${id}.quantity`).error?.message}
                      />
                    </div>
                  </div>
                </div>
                <Documents control={control} productId={productId} offerId={id} />
                <div className={classes.comment_wrapper}>
                  <div className={classes.label}>{t_product.comment}</div>
                  <TextArea
                    label={t_product.add_comment}
                    {...register(`productItems.${productId}.offers.${id}.comment`)}
                    error={getFieldState(`productItems.${productId}.offers.${id}.comment`).error?.message}
                  />
                </div>
              </Tab>
            );
          })}
      </Tabs>
    </>
  );
}

export default OfferTab;
