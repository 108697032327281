import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IBaseField } from '../../../../../models/field-validation.model';
import { RootState } from '../../../../../store';
import SelectComponent from '../../../Select/Select';
import { MONTHS, YEARS } from '../../constants/constants';
import { IMonthYear } from './model/month-year.model';
import classes from './MonthYear.module.scss';

const MonthYear = (props: IMonthYear) => {
  const translations = useSelector(
    (state: RootState) => state.translations.translations[state.translations.appLanguage]['components']
  );

  const today = new Date();
  const todayMonth = today.getMonth() + 1;
  const todayYear = today.getFullYear();
  const hasInitValue = props.month !== null && props.month !== undefined;

  const labelMonth = MONTHS.find(m => {
    if (hasInitValue) return m.month === +props.month;
    else return m.month === +todayMonth;
  })?.label;

  const valueMonth = hasInitValue ? +props.month : todayMonth + 1;

  const monthField: IBaseField<any> = {
    value: {
      label: labelMonth ? translations[labelMonth] : '',
      value: valueMonth,
    },
  };
  const yearField: IBaseField<any> = {
    value: {
      label: hasInitValue ? props.year : todayYear,
      value: hasInitValue ? props.year : todayYear,
    },
  };

  const onMonthSelected = val => {
    props.onMonthSelect(val?.value);
  };

  const onYearSelected = val => {
    props.onYearSelect(val?.value);
  };

  return (
    <React.Fragment>
      <div className={classes.Container}>
        <div className={classes.Month}>
          <div className={classes.MonthContainer}>
            <SelectComponent
              label=""
              name="month"
              field={monthField}
              class="light"
              borderless
              options={MONTHS.map((m, index) => {
                return {
                  label: translations[m.label],
                  value: m.month,
                };
              })}
              onItemSelected={onMonthSelected}
            />
          </div>
        </div>
        <div className={classes.Year}>
          <div className={classes.YearContainer}>
            <SelectComponent
              label=""
              name="year"
              field={yearField}
              class="light"
              borderless
              options={YEARS.map(y => {
                return {
                  label: y,
                  value: y,
                };
              })}
              onItemSelected={onYearSelected}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MonthYear;
