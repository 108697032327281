import { onChildAdded, onChildRemoved, serverTimestamp } from 'firebase/database';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams, useLocation } from 'react-router-dom';
import { faCheck, faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { MESSAGES, MESSAGES_STATUS } from '../../../api/config/routes';
import { baseHttp } from '../../../api/http/base';
import { RootState } from '../../../store';
import ChatInput from '../ChatInput/ChatInput';
import ChatHeader from './ChatHeader/ChatHeader';
import Message from './Message/Message';
import { IChatPreview, IMessage } from './model/chat-preview.model';
import ConfirmModal from '../../../components/UI/ConfirmModal/ConfirmModal';

import classes from './ChatPreview.module.scss';

const ChatPreview = (props: IChatPreview) => {
  const { messageId } = useParams();
  const [searchParams] = useSearchParams();
  const location: any = useLocation();
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [chagChanged, setChatChanged] = useState<boolean>(false);
  const [oldMessageId, setOldMessageId]: any = useState('');
  const [replay, setReplay] = useState<string>('');
  const [showCopySuccess, setShowCopySuccess] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState({
    show: false,
    message_id: '',
  });
  const [headerUserData, setHeaderUserData] = useState<string>('');

  const t = useSelector(
    (state: RootState) => state.translations.translations[state.translations.appLanguage]['global']
  );

  const loggedUser = useSelector((state: RootState) => state.auth.user.user);
  const userId = loggedUser.id;

  // user get
  useEffect(() => {
    if (oldMessageId !== messageId) {
      if (location.state && location.state.company) {
        setHeaderUserData(location?.state?.company);
      } else {
        setHeaderUserData('');
      }
      setOldMessageId(messageId);
      setReplay('');
    }
    setChatChanged(true);
    setTimeout(() => {
      setChatChanged(false);
    }, 1000);
  }, [messageId]);

  // message get
  useEffect(() => {
    const db = baseHttp.getDatabase();
    const messages = baseHttp.ref(db, `${MESSAGES}/${messageId}`);
    const query = baseHttp.query(messages);

    if (oldMessageId !== messageId) {
      setMessages([]);
    }

    const unsub = onChildAdded(query, messagesSnapshot => {
      const message: any = messagesSnapshot.val();
      const id = messagesSnapshot.key;
      message.messageId = id;
      const data: IMessage = message;

      if (!data) {
        setMessages([]);
      } else {
        setMessages(state => {
          let newState = [...state];
          newState.push(data);

          setTimeout(() => {
            const el = document.getElementById('chat-container');
            el?.scrollTo(0, el.scrollHeight);
          }, 100);

          return newState;
        });
      }
    });

    return () => {
      unsub();
    };
  }, [messageId, userId]);

  // on message remove
  useEffect(() => {
    const db = baseHttp.getDatabase();
    const messages = baseHttp.ref(db, `${MESSAGES}/${messageId}`);
    const query = baseHttp.query(messages);

    const unsub = onChildRemoved(query, messagesSnapshot => {
      const message: any = messagesSnapshot.val();
      const id = messagesSnapshot.key;
      message.messageId = id;
      const data: IMessage = message;

      setMessages(state => {
        return state.filter(m => m.messageId !== data.messageId);
      });
    });

    return () => {
      unsub();
    };
  }, [messageId, userId]);

  // set message seen status
  useEffect(() => {
    const dbRef = baseHttp.getDatabase();
    const messageStatus = baseHttp.ref(dbRef, `${MESSAGES_STATUS}/${messageId}`);
    const query = baseHttp.query(messageStatus);

    baseHttp.get(query).then(statusSnapshot => {
      const status = statusSnapshot.val();

      const seen_state = {
        ...status,
        [userId]: true,
      };

      baseHttp.set(baseHttp.ref(dbRef, `${MESSAGES_STATUS}/${messageId}`), seen_state);
    });
  }, [messageId, userId]);

  const onMessageSendHandler = (message: any, files: any[]) => {
    const hasReplay = replay && replay.length > 0;
    const msg: IMessage = {
      senderId: userId,
      receiverId: searchParams.get('receiverId')!,
      created_at: serverTimestamp(),
      text: message?.trim(),
      files: files,
    };

    if (hasReplay) {
      msg.replay = replay.trim();
    }

    const db = baseHttp.getDatabase();
    baseHttp.push(baseHttp.ref(db, `${MESSAGES}/${messageId}`), msg).then(() => {
      if (hasReplay) {
        setReplay('');
      }
      const el = document.getElementById('chat-container');
      el?.scrollTo(0, el.scrollHeight);
    });

    const seen_state = {
      [userId]: true,
      [searchParams.get('receiverId')!]: false,
    };
    baseHttp.set(baseHttp.ref(db, `${MESSAGES_STATUS}/${messageId}`), seen_state);
  };

  const onCopySuccess = () => {
    setShowCopySuccess(true);
    setTimeout(() => {
      setShowCopySuccess(false);
    }, 2000);
  };

  const confirmRemoveMessage = message_id => {
    if (message_id && message_id.length > 0) {
      setShowConfirmModal({
        show: true,
        message_id,
      });
    }
    document.body.click();
  };

  const removeMessage = () => {
    const { message_id } = showConfirmModal;
    setShowConfirmModal({
      show: false,
      message_id: '',
    });
    if (message_id && message_id.length > 0) {
      const db = baseHttp.getDatabase();
      baseHttp.remove(baseHttp.ref(db, `${MESSAGES}/${messageId}/${message_id}`));
    }
  };

  return (
    <React.Fragment>
      <div className={classes.Container}>
        <ChatHeader lastMessage={messages[messages.length - 1]} company={headerUserData} />

        <div className={`${classes.Messages} ${messages.length === 0 ? classes.no_messages : ''}`} id="chat-container">
          {messages.length > 0 ? (
            messages.map((msg, ind) => {
              return (
                <Message
                  message={msg}
                  key={ind}
                  sent={userId === msg.senderId}
                  received={userId === msg.receiverId}
                  initials={headerUserData.substring(0, 2)}
                  replayOnMessage={message => {
                    if (msg.files && msg.files.length > 0) {
                      let fileNames = '';

                      for (let file in msg.files) {
                        if (file === '0') {
                          fileNames += `${msg.files[file].name}`;
                        } else {
                          fileNames += `, ${msg.files[file].name}`;
                        }
                      }

                      setReplay(fileNames);
                      return;
                    }
                    return setReplay(message);
                  }}
                  showCopiedMessage={onCopySuccess}
                  removeMessage={confirmRemoveMessage}
                />
              );
            })
          ) : (
            <div className={classes.no_messages}>{t['no_messages']}</div>
          )}
          {showCopySuccess && (
            <div className={classes.copied_success}>
              <FontAwesomeIcon icon={faCheck} size={'2x'} color={'#FFFFFF'} />
              {t.copied}
            </div>
          )}
        </div>
        {replay && replay.length > 0 && (
          <div className={classes.replay_wrapper}>
            <div className={classes.replay} dangerouslySetInnerHTML={{ __html: replay }}></div>
            <div className={classes.close_btn} onClick={() => setReplay('')}>
              <FontAwesomeIcon icon={faClose} size={'1x'} color={'#606060'} />
            </div>
          </div>
        )}
        <ChatInput onMessageSend={onMessageSendHandler} small chatChanged={chagChanged} />
      </div>
      <ConfirmModal
        show={showConfirmModal.show}
        toggleModal={() =>
          setShowConfirmModal({
            show: false,
            message_id: '',
          })
        }
        confirm={() => removeMessage()}
        title={t.remove_message_title}
        body={t.remove_message_body}
        confirmBtnClass={classes.confirm_btn}
        confirmBtnIcon={<span className={classes.icon}></span>}
        confirmBtnText={t.delete}
      />
    </React.Fragment>
  );
};

export default ChatPreview;
