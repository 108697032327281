import { createSlice } from '@reduxjs/toolkit';
import { ISidebar } from './sidebar.model';

const initialState: ISidebar = {
  isOpen: false,
  anonymousCreate: false,
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: initialState,
  reducers: {
    toggle: state => {
      state.isOpen = !state.isOpen;
    },
    toggleAnonymous: state => {
      state.anonymousCreate = !state.anonymousCreate;
    },
  },
});

export const sidebarActions = sidebarSlice.actions;
export default sidebarSlice.reducer;
