import { client } from '../services/http/instance';
import { useFetch } from './useFetch';

interface Country {
  id: number;
  name: string;
  code: string;
}

export function useCountries() {
  return useFetch(() => client.get<Country[]>('/countries').then(({ data }) => data));
}
