import AsyncSelect from 'react-select/async';
import { forwardRef, useMemo } from 'react';

import { useCountries } from '../hooks/useCountries';
import classes from './UI/Select/Select.module.scss';
import CaretDownAccent from '../assets/icons/CaretDownAccent.svg';

interface Props {
  value?: number;
  onChange?(value: number): void;
  error?: string;
  label?: string;
  required?: boolean;
}

export const CountrySelect = forwardRef<any, Props>(({ onChange, value, error, label, required }, ref) => {
  const { isLoading, data } = useCountries();
  const countries = useMemo(() => data?.map(country => ({ value: country.id, label: country.name })) ?? [], [data]);
  const option = countries.find(x => x.value === value);

  return (
    <div className={['custom-select', error && classes.ErrorInput].filter(Boolean).join(' ')}>
      {label && (
        <label>
          {label}
          {required && <span className={classes.RequiredIndicator}>*</span>}
        </label>
      )}
      <AsyncSelect
        defaultOptions={countries}
        isLoading={isLoading}
        placeholder=""
        ref={ref}
        classNamePrefix="custom-select"
        value={option}
        onChange={option => {
          if (option) {
            onChange?.(option.value);
          }
        }}
        loadOptions={(value, callback) => {
          callback(countries.filter(country => country.label.toLowerCase().includes(value.toLowerCase())));
        }}
        filterOption={(option, value) => option.label.toLowerCase().includes(value.toLowerCase())}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => {
            return <img className={classes.DropdownIndicator} src={CaretDownAccent} alt="CaretDownAccent" />;
          },
        }}
      />
      {error && (
        <small id="emailHelp" className={`${classes.ErrorText} form-text text-muted`}>
          {error}
        </small>
      )}
    </div>
  );
});
