import React from 'react';
import CompanyInfo from './Components/CompanyInfo/CompanyInfo';
import BaseInfo from './Components/BaseInfo/BaseInfo';
import AdHeader from './Components/Header/AdHeader';
import classes from './NewAdForm.module.scss';

const NewAdForm = () => {
  return (
    <React.Fragment>
      <AdHeader />
      <CompanyInfo />
      <div className={classes.Card}>
        <BaseInfo />
      </div>
    </React.Fragment>
  );
};

export default NewAdForm;
