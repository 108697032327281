import { useFetch } from '../../hooks/useFetch';
import { Industry } from '../../queries';
import { client } from '../../services/http/instance';

export interface Profile {
  user: {
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    cell_phone_number: string | null;
    newsletter: boolean | null;
    company: {
      id: number;
      maticen_broj: string;
      sostojba: string | null;
      logo: string | null;
      edinstven_danocen_broj: string;
      email: string;
      fax: string | null;
      naziv: string;
      country_id: number | null;
      municipality: string;
      phone_number: string;
      postal_code: string;
      web_location: string;
      address: string;
      city: string;
      company_info: string;
      industry: Industry[];
    };
  };
}

export function useProfile() {
  return useFetch(() => client.get<Profile>('/user/get-details').then(({ data }) => data));
}
