import React from 'react';
import classes from './LoginBackground.module.scss';

import Background from './../../../../assets/login-bg.svg';
import LoginText from '../LoginText/LoginText';

const LoginBackground = () => {
  return (
    <React.Fragment>
      <div className={classes.LoginLayout}>
        <LoginText />
        <div className={classes.Overlay}></div>
        <img className={`${classes.BackgroundImage}`} src={Background} alt={Background}></img>
      </div>
    </React.Fragment>
  );
};

export default LoginBackground;
