import React from 'react';
import { useSelector } from 'react-redux';
import ContentFooter from '../../components/Layout/ContentFooter/ContentFooter';
import Page from '../../components/Layout/Page/Page';
import { RootState } from '../../store';
import classes from './TermsAndServices.module.scss';

const TermsAndServices = () => {
  const translations = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage]['toc'];
  });

  return (
    <React.Fragment>
      <Page
        borderlessFullscreen
        white
        // footerComponent={<ContentFooter />}
      >
        <div className={classes.Container}>
          <h5 className="mb-4">{translations['Правила и Услови']}</h5>

          {translations.Sections.map((section, sectionIndex) => {
            return (
              <>
                <div className={classes.Section} key={sectionIndex}>
                  <h5 className={classes.Title}>{section.Title}</h5>

                  {section.Content.map((content, contentIndex) => {
                    return <p key={`${sectionIndex}-${contentIndex}`}>{content}</p>;
                  })}
                </div>
              </>
            );
          })}
        </div>
      </Page>
    </React.Fragment>
  );
};

export default TermsAndServices;
