import { IProduct } from '../../models/data/advertisement.model';
import { v4 as uuid } from 'uuid';
import * as _ from 'lodash';

import Chair from './../../assets/products-dummy/Chair.svg';
import Chair1 from './../../assets/products-dummy/Chair1.svg';
import Computer from './../../assets/products-dummy/Computer.svg';
import Laptop from './../../assets/products-dummy/Laptop.svg';
import Laptop1 from './../../assets/products-dummy/Laptop1.svg';
import Purifier from './../../assets/products-dummy/Purifier.svg';
import { categories, currencies } from '../../pages/NewAd/NewAdForm/Components/BaseInfo/fields/fields';

const ALL_PRODUCTS: IProduct[] = [];

export const getRandomProducts = (): IProduct[] => {
  const totalProducts = Math.floor(1 + Math.random() * 6);
  const shuffled = _.shuffle(ALL_PRODUCTS);

  return shuffled.slice(0, totalProducts);
};
