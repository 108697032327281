import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, Control, useWatch } from 'react-hook-form';
import { Profile } from './queries';
import classes from './Profile.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import {
  ChangeEvent,
  forwardRef,
  InputHTMLAttributes,
  TextareaHTMLAttributes,
  useEffect,
  useMemo,
  useState,
} from 'react';
import * as Yup from 'yup';
import BasePhoneInput, { Props as BasePhoneInputProps, isValidPhoneNumber } from 'react-phone-number-input/input';
import { Industry } from '../../queries';
import Tabs from '../../components/UI/Tabs/Tabs';
import Switch from '../../components/UI/Switch/Switch';
import { useNavigate } from 'react-router-dom';
import { usePrevious } from '../../hooks/usePrevious';
import { client } from '../../services/http/instance';
import { showNotification } from '../../notification_functions/notifications_functions';
import GlobalLoader from '../../components/UI/GlobalLoader/GlobalLoader';
import placeholder from '../../assets/imgPlaceholder.png';
import { isBase64 } from '../../utils/isBase64';
import { CountrySelect } from '../../components/CountrySelect';
import ChangePasswordModal from './modals/ChangePasswordModal';
import FileSaver from 'file-saver';
import { toBase64 } from '../../utils/toBase64';
import { authActions } from '../../store/auth/auth';
import Checkbox from '../../components/UI/Checkbox/Checkbox';

interface Props {
  data: Profile;
  industries: Industry[];
}

type Section = 'company' | 'user';

interface BaseInputProps {
  label: string;
  error?: string;
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement>, BaseInputProps {}

// if similar component is used in other forms, extract this component as a global one
const Input = forwardRef<HTMLInputElement, InputProps>(({ label, required, error, ...props }, ref) => {
  return (
    <div className={[classes.input_wrapper, error && classes.has_error].filter(Boolean).join(' ')}>
      <label>
        {label}
        {required && <span>*</span>}
      </label>
      <input ref={ref} {...props} />
      <div className={classes.error}>{error}</div>
    </div>
  );
});

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement>, BaseInputProps {}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(({ label, error, ...props }, ref) => {
  return (
    <div className={[classes.company_info_wrapper, error && classes.has_error].filter(Boolean).join(' ')}>
      <label>{label}</label>
      <textarea ref={ref} {...props} />
      <div className={classes.error}>{error}</div>
    </div>
  );
});

interface PhoneInputProps extends BasePhoneInputProps<InputHTMLAttributes<HTMLInputElement>>, BaseInputProps {}

// if the component is used similarly in other forms, extract the component as global one
const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>(({ error, required, label, ...props }, ref) => {
  return (
    <div className={[classes.input_wrapper, error && classes.has_error].filter(Boolean).join(' ')}>
      <label>
        {label}
        {required && <span>*</span>}
      </label>
      <BasePhoneInput {...props} ref={ref} />
      <div className={classes.error}>{error}</div>
    </div>
  );
});

function CompanyLogo({ src, onChange }: { src: string; onChange?(url: string): void }) {
  const t = useSelector((state: RootState) => state.translations.translations[state.translations.appLanguage]);

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0];

    if (!file) {
      return;
    }

    toBase64(file).then(result => {
      if (result) {
        onChange?.(result);
      }
    });
  }

  return (
    <div className={classes.image_container}>
      <div className={classes.image_wrapper}>
        <img src={src} alt="Company logo" />
      </div>
      <div className={classes.upl_img_wrapper}>
        <div className={classes.icon}></div>
        <label htmlFor="logo" className={classes.text}>
          {t.profile.change_image}
        </label>
        <input
          id="logo"
          type="file"
          onChange={handleChange}
          accept="image/png, image/jpeg"
          style={{ display: 'none' }}
        />
      </div>
    </div>
  );
}

export function ProfileForm({ data, industries }: Props) {
  const t = useSelector((state: RootState) => state.translations.translations[state.translations.appLanguage]);
  const [tab, setTab] = useState<Section>('company');
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user: any = useSelector((state: RootState) => state.auth.user.user);

  const toggleChangePasswordModal = () => {
    setOpenChangePasswordModal(prev => !prev);
  };

  const validationSchema = useMemo(
    () =>
      Yup.object({
        company: Yup.object({
          name: Yup.string().trim().required(t.profile.input_required),
          idNumber: Yup.string()
            .trim()
            .required(t.profile.input_required)
            .matches(/^\d+$/, t.profile.invalid_id_number)
            .length(7, t.profile.invalid_id_number),
          taxNumber: Yup.string()
            .trim()
            .required(t.profile.input_required)
            .test('len', t.profile.invalid_tax_number, val => [13, 15].includes(val.length)),
          address: Yup.string().trim().required(t.profile.input_required),
          city: Yup.string().trim().required(t.profile.input_required),
          municipality: Yup.string().trim().required(t.profile.input_required),
          phoneNumber: Yup.string()
            .trim()
            .required(t.profile.input_required)
            .test('phone', t.profile.invalid_phone_number, value => isValidPhoneNumber(value)),
          // faxNumber: Yup.string()
          //   .trim()
          //   .required(t.profile.input_required)
          //   .test('phone', t.profile.invalid_phone_number, value => isValidPhoneNumber(value)),
          email: Yup.string().trim().required(t.profile.input_required).email(t.profile.invalid_email),
          webLink: Yup.string().trim().nullable(),
          postalCode: Yup.string().trim().required(t.profile.input_required).max(4, t.profile.invalid_postal_number),
          info: Yup.string().trim().max(1000, t.profile.info_too_long).nullable(),
          logo: Yup.string(),
          report: Yup.mixed<string | File>(),
          countryId: Yup.number().required(t.profile.input_required),
          industries: Yup.array().of(Yup.number()).min(1, t.profile.at_least_one_Industry),
        }),
        user: Yup.object({
          firstName: Yup.string().trim().required(t.profile.input_required),
          lastName: Yup.string().trim().required(t.profile.input_required),
          phoneNumber: Yup.string()
            .trim()
            .required(t.profile.input_required)
            .test('phone', t.profile.invalid_phone_number, value => isValidPhoneNumber(value)),
          cellphoneNumber: Yup.string().trim(),
          newsletter: Yup.bool(),
        }),
      }),
    [t]
  );

  const {
    formState: { errors, submitCount, isValid, isSubmitting },
    control,
    setValue,
    register,
    handleSubmit,
  } = useForm<Yup.InferType<typeof validationSchema>>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      company: {
        name: data.user.company.naziv,
        idNumber: data.user.company.maticen_broj,
        taxNumber: data.user.company.edinstven_danocen_broj,
        address: data?.user.company.address,
        city: data?.user.company.city,
        email: data?.user.company.email,
        info: data?.user.company.company_info ?? undefined,
        // faxNumber: data?.user.company.fax ?? '',
        phoneNumber: data?.user.company.phone_number,
        municipality: data?.user.company.municipality,
        postalCode: data?.user.company.postal_code,
        webLink: data?.user.company.web_location,
        countryId: data.user.company.country_id ?? undefined,
        logo: data.user.company.logo
          ? `${process.env.REACT_APP_STATIC_URL}/companies/${data.user.company.id}/logos/${data.user.company.logo}`
          : '',
        report: data.user.company.sostojba ?? undefined,
        industries: data?.user.company.industry.map(item => item.id) ?? [],
      },
      user: {
        firstName: data.user.first_name,
        lastName: data.user.last_name,
        phoneNumber: data.user.phone_number,
        cellphoneNumber: data.user.cell_phone_number ?? undefined,
        newsletter: !!data.user.newsletter,
      },
    },
  });

  const prevSubmitCount = usePrevious(submitCount);

  useEffect(() => {
    if (isValid || prevSubmitCount === submitCount) {
      return;
    }

    for (const section of ['company', 'user'] as Section[]) {
      const sectionErrors = errors[section] ?? {};
      const hasErrors = !!Object.keys(sectionErrors).length;

      if (hasErrors) {
        setTab(section);
        break;
      }
    }
  }, [errors, tab, isValid, submitCount, prevSubmitCount]);

  return (
    <>
      <GlobalLoader show={isValid && isSubmitting} />
      <div className={classes.tabs_wrapper}>
        {/* <div className={classes.title}>{t.profile.subtitle}</div> */}
        <div className={classes.tabs}>
          <Tabs
            tabs={[
              {
                id: 1,
                label: t.profile.tab_one_title,
                active: tab === 'company',
              },
              {
                id: 2,
                label: t.profile.tab_two_title,
                active: tab === 'user',
              },
            ]}
            onClick={num => setTab(num === 1 ? 'company' : 'user')}
          />
        </div>
      </div>
      <form
        noValidate
        onSubmit={handleSubmit(async values => {
          try {
            const logo = values.company.logo && isBase64(values.company.logo) ? values.company.logo : undefined;
            const report = values.company.report instanceof File ? await toBase64(values.company.report) : undefined;

            const reportName = values.company.report instanceof File ? values.company.report?.name : '';

            await client.put('/profile', { ...values, company: { ...values.company, logo, report, reportName } });
            showNotification(t.profile.success_message, 'success');

            dispatch(
              authActions.updateUser({
                ...user,
                naziv: values.company.name,
              })
            );
          } catch {
            showNotification(t.profile.error_message, 'danger');
          }
        })}
      >
        <div className={classes.tabs_content_wrapper}>
          {tab === 'company' && (
            <>
              <Controller
                control={control}
                name="company.logo"
                render={({ field }) => (
                  <CompanyLogo src={field.value ?? placeholder} onChange={url => setValue('company.logo', url)} />
                )}
              />

              <div className={classes.tab_one} id="tab_one_form">
                <div className={classes.col_one}>
                  <Input
                    label={t.profile.operator_name_label}
                    type="text"
                    {...register('company.name')}
                    error={errors.company?.name?.message}
                  />
                  <Input
                    label={t.profile.single_tax_label}
                    type="text"
                    {...register('company.taxNumber')}
                    error={errors.company?.taxNumber?.message}
                  />
                  <Input
                    label={t.profile.economic_operator_number_label}
                    type="text"
                    {...register('company.idNumber')}
                    error={errors.company?.idNumber?.message}
                  />
                  <Input
                    label={t.profile.address_label}
                    required
                    type="text"
                    {...register('company.address')}
                    error={errors.company?.address?.message}
                  />
                  <div className={classes.inputs_container}>
                    <Input
                      label={t.profile.city_town_label}
                      required
                      type="text"
                      {...register('company.city')}
                      error={errors.company?.city?.message}
                    />
                    <Input
                      label={t.profile.municipality_label}
                      required
                      type="text"
                      {...register('company.municipality')}
                      error={errors.company?.municipality?.message}
                    />
                  </div>
                </div>
                <div className={classes.col_two}>
                  <Controller
                    control={control}
                    name="company.phoneNumber"
                    render={({ field, fieldState }) => (
                      <PhoneInput
                        required
                        ref={field.ref}
                        value={field.value}
                        onChange={value => setValue('company.phoneNumber', value ?? '', { shouldValidate: true })}
                        label={t.profile.phone}
                        error={fieldState.error?.message}
                      />
                    )}
                  />
                  {/* <Controller
                    control={control}
                    name="company.faxNumber"
                    render={({ field, fieldState }) => (
                      <PhoneInput
                        required
                        ref={field.ref}
                        value={field.value}
                        onChange={value => setValue('company.faxNumber', value ?? '', { shouldValidate: true })}
                        label={t.profile.fax_number_label}
                        error={fieldState.error?.message}
                      />
                    )}
                  /> */}
                  <Input
                    label={t.profile.email}
                    required
                    type="email"
                    autoComplete="new-email"
                    {...register('company.email')}
                    error={errors.company?.email?.message}
                  />
                  <Input
                    label={t.profile.website_label}
                    type="text"
                    {...register('company.webLink')}
                    error={errors.company?.webLink?.message}
                  />
                  <Input
                    label={t.profile.postal_number}
                    required
                    {...register('company.postalCode')}
                    error={errors.company?.postalCode?.message}
                  />
                  <Controller
                    control={control}
                    name="company.countryId"
                    render={({ field, fieldState }) => (
                      <CountrySelect
                        label={t.global.choose_country}
                        required
                        {...field}
                        error={fieldState.error?.message}
                      />
                    )}
                  />
                </div>
              </div>
              <TextArea
                rows={5}
                label={t.profile.company_short_info}
                {...register('company.info')}
                error={errors.company?.info?.message}
              />
              <Controller
                control={control}
                name="company.report"
                render={({ field }) => (
                  <div className={classes.current_state_wrapper}>
                    <label>{t.profile.current_state_label}</label>
                    <div className={[classes.file_wrapper, classes.no_file].filter(Boolean).join(' ')}>
                      <>
                        <div className={classes.file_text}>
                          <div className={classes.file_info}>
                            <div
                              className={classes.name}
                              onClick={() => {
                                if (field.value instanceof File) {
                                  FileSaver.saveAs(field.value);
                                } else {
                                  // TODO: download from server
                                }
                              }}
                            >
                              {!field.value
                                ? t.profile.upload_file
                                : field.value instanceof File
                                ? field.value.name
                                : field.value}
                            </div>
                          </div>
                          <div className={classes.actions}>
                            <label htmlFor="report" className={classes.attach}></label>
                            <input
                              ref={field.ref}
                              type="file"
                              name="report"
                              id="report"
                              style={{ display: 'none' }}
                              accept="application/pdf"
                              onChange={e => {
                                const file = e.target.files?.[0];

                                if (file?.type !== 'application/pdf') {
                                  e.target.value = '';
                                  showNotification(t.global.invalid_report_document, 'danger');

                                  return;
                                }

                                if (file) {
                                  setValue('company.report', file);
                                }
                              }}
                            />
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                )}
              />
              <div className={classes.more_ads_info_wrapper}>
                <label>{t.profile.info_about_ads}</label>
                <div className={classes.checkboxes_wrapper}>
                  <Controller
                    control={control}
                    name="company.industries"
                    render={({ field, fieldState }) => (
                      <>
                        <h6 className="pb-2 mb-2">
                          Дејност на компанијата * <br />
                          {fieldState.error?.message && (
                            <small className={`${classes.error} `}>{fieldState.error?.message}</small>
                          )}
                        </h6>
                        {industries.map(item => (
                          <div key={`industry-${item.id}`} className={classes.MarginSeparator}>
                            <Checkbox
                              label={item.name}
                              value={item.id}
                              checked={field.value?.includes(item.id)}
                              id={`industry-${item.id}`}
                              onValueChange={value => {
                                if (field.value?.includes(item.id)) {
                                  setValue(
                                    'company.industries',
                                    field.value.filter(id => id !== item.id),
                                    { shouldValidate: true }
                                  );

                                  return;
                                }

                                setValue('company.industries', [...(field.value ?? []), item.id], {
                                  shouldValidate: true,
                                });
                              }}
                            />
                          </div>
                        ))}
                      </>
                    )}
                  />
                </div>
              </div>
            </>
          )}
          {tab === 'user' && (
            <div className={classes.tab_two}>
              <div className={classes.col_one}>
                <Input
                  label={t.profile.name}
                  required
                  {...register('user.firstName')}
                  error={errors.user?.firstName?.message}
                />
                <Input
                  label={t.profile.surname}
                  required
                  {...register('user.lastName')}
                  error={errors.user?.lastName?.message}
                />
                <Controller
                  control={control}
                  name="user.cellphoneNumber"
                  render={({ field, fieldState }) => (
                    <PhoneInput
                      ref={field.ref}
                      value={field.value}
                      onChange={value => setValue('user.cellphoneNumber', value ?? '', { shouldValidate: true })}
                      label={t.profile.mobile_number}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </div>
              <div className={classes.col_two}>
                <Input label={t.profile.email} defaultValue={data.user.email} disabled />
                <Controller
                  control={control}
                  name="user.phoneNumber"
                  render={({ field, fieldState }) => (
                    <PhoneInput
                      required
                      ref={field.ref}
                      value={field.value}
                      onChange={value => setValue('user.phoneNumber', value ?? '', { shouldValidate: true })}
                      label={t.profile.phone}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </div>
            </div>
          )}
        </div>
        <div className={classes.footer_wrapper}>
          <div className={classes.info_wrapper}>
            <div className={classes.title}>{t.profile.info_title}</div>
            <Controller
              control={control}
              name="user.newsletter"
              render={({ field }) => (
                <Switch
                  label={t.profile.info_label}
                  name="user.newsletter"
                  checked={field.value}
                  onChange={value => setValue('user.newsletter', value)}
                  switchPosition="start"
                />
              )}
            />
          </div>
          <div className={classes.buttons_wrapper}>
            <button className={classes.cancel} type="button" onClick={() => navigate(-1)}>
              {t.global.cancel}
            </button>
            <button className={classes.changePassword} type="button" onClick={toggleChangePasswordModal}>
              {t.auth.change_password}
            </button>
            <button className={classes.save} type="submit">
              {t.global.save}
            </button>
          </div>
        </div>
      </form>
      {openChangePasswordModal && <ChangePasswordModal show toggleChangePasswordModal={toggleChangePasswordModal} />}
    </>
  );
}
