import { LocalStorageEnum } from '../enums/local-storage-keys';
import qs from 'query-string';

const API_URL = process.env.REACT_APP_API_URL;

export function download(url: string) {
  const token = localStorage.getItem(LocalStorageEnum.UserToken);

  window.location.href = qs.stringifyUrl({ url: `${API_URL}${url}`, query: { token } });
}
