import { useMemo, useState } from 'react';
import SlidingPane from 'react-sliding-pane';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { RootState } from '../../../store';
import { httpPost } from '../../../services/http';
import Button from '../../../components/UI/Button/Button';
import { showNotification } from '../../../notification_functions/notifications_functions';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import classes from './SubmitOffer.module.scss';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import OfferTab from './OfferTab';
import GlobalLoader from '../../../components/UI/GlobalLoader/GlobalLoader';
import { v4 } from 'uuid';

const SubmitOfferModal = props => {
  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage];
  });

  const [isLoading, setIsLoading] = useState(false);

  const t_global = t.global;
  const t_product = t.product;

  const offerValidationSchema = useMemo(
    () =>
      Yup.object({
        productItems: Yup.array().of(
          Yup.object().shape({
            checked: Yup.boolean(),
            productId: Yup.number().typeError(t.create_ad.must_be_number).required(t.profile.input_required),
            offers: Yup.array()
              .when('checked', {
                is: true,
                then: schema =>
                  schema
                    .of(
                      Yup.object().shape({
                        id: Yup.string(),
                        catalog_number: Yup.string().required(t.profile.input_required),
                        quantity: Yup.number().typeError(t.create_ad.must_be_number).required(t.profile.input_required),
                        price: Yup.number()
                          .typeError(t.create_ad.must_be_number)
                          .min(1, t.create_ad.input_must_be_one)
                          .required(t.profile.input_required),
                        comment: Yup.string().required(t.profile.input_required),
                        delivery_due_days: Yup.number()
                          .typeError(t.create_ad.must_be_number)
                          .required(t.profile.input_required),
                        documents: Yup.array()
                          .of(
                            Yup.object().shape({
                              name: Yup.string(),
                              file: Yup.string(),
                            })
                          )
                          .required(t.profile.input_required),
                      })
                    )
                    .required(t.profile.input_required),
                otherwise: schema => schema.of(Yup.object()),
              })
              .required(t.profile.input_required),
          })
        ),
      }),
    [t]
  );

  const {
    formState: { errors, isValid, isSubmitting, touchedFields },
    control,
    setValue,
    register,
    handleSubmit,
    getValues,
    getFieldState,
  } = useForm<Yup.InferType<typeof offerValidationSchema>>({
    resolver: yupResolver(offerValidationSchema),
    defaultValues: {
      productItems:
        props.products &&
        props.products?.map(product => ({
          checked: false,
          productId: product.id,
          offers: [
            { catalog_number: '', quantity: 1, price: 1, comment: '', delivery_due_days: 1, documents: null, id: v4() },
          ],
        })),
    },
  });

  return (
    <SlidingPane
      className={classes.modal_style}
      overlayClassName={classes.overlay_style}
      isOpen={props.isPaneOpen}
      title={t_product.offer}
      onRequestClose={props.onSidebarClose}
      from="right"
      subtitle={
        <div className={classes.footer_wrapper}>
          <div className={classes.close_btn} onClick={props.onSidebarClose}>
            {t_global.cancel}
          </div>
          <Button
            label={t_product.submit_offer}
            type="submit"
            form="productPost"
            loading={isLoading}
            // disabled={Object.keys(errors).length > 0}
            onClick={handleSubmit(async (values: any) => {
              setIsLoading(true);
              try {
                const findCheckedProducts = values.productItems.some(product => product.checked);

                if (!findCheckedProducts) {
                  showNotification(t_product.at_lease_one_product, 'danger');
                  setIsLoading(false);

                  return;
                }

                const response = await httpPost<any, any>('/offers', {
                  data: await values.productItems.filter(product => {
                    if (product.checked) {
                      delete product.checked;
                      return product;
                    }
                  }),
                });
                props.onConfirm();
                props.onSidebarClose();
                showNotification(t.global.success, 'success');
                setIsLoading(false);
              } catch (e: any) {
                setValue(
                  'productItems',
                  getValues('productItems')?.map(item => ({ ...item, checked: false }))
                );

                if (e?.error_code === 'offer_limit_exceeded' && e?.product_id) {
                  showNotification(`${t_product.offer_limit_exceeded_with_id} #${e?.product_id}`, 'danger');
                } else if (e?.message.includes('must not have more')) {
                  showNotification(t_product.offer_limit_cramped, 'danger');
                } else {
                  showNotification(t.global.error_message, 'danger');
                }
                props.onSidebarClose();
                setIsLoading(false);
              }
            })}
          />
        </div>
      }
    >
      <form noValidate id="productPost" onSubmit={e => e.preventDefault()}>
        <div className={classes.body_wrapper}>
          <div className={classes.products_wrapper}>
            {props?.products &&
              props?.products.length > 0 &&
              props.products.map((product, index) => {
                return (
                  <div key={index} className={classes.product_wrapper}>
                    <div className={classes.checkbox_wrapper}>
                      <Controller
                        control={control}
                        name={`productItems.${index}.checked`}
                        render={({ field, fieldState }) => (
                          <label htmlFor={`check_product_${index}`}>
                            <input
                              {...register(`productItems.${index}.checked`)}
                              type="checkbox"
                              name={`product_${index}`}
                              id={`check_product_${index}`}
                              disabled={isLoading}
                              onChange={e => {
                                setValue(`productItems.${index}.checked`, !getValues(`productItems.${index}.checked`));
                              }}
                            />
                            <span className={classes.checkmark}></span>
                          </label>
                        )}
                      />
                    </div>
                    <div className={classes.product}>
                      <div className={classes.product_details}>
                        <div className={classes.product_details_split}>
                          <div className={classes.product_details_inner}>
                            <div className={classes.title}>
                              #{product?.id} <br /> <br />
                              {product?.ime}
                            </div>
                            <div className={classes.category}>{product?.kategorija}</div>
                            <div className={classes.description}>{product?.opis}</div>
                          </div>
                        </div>
                        <div className={classes.product_details_split_2}>
                          <div className={classes.product_details_inner}>
                            <div className={classes.title}>{t.offers.due_date_asked}</div>
                            <div>{props.deliveryDueDays}</div>
                          </div>
                        </div>
                        <div className={classes.quamtity_wrapper}>
                          <div className={classes.label}>{t_product.quantity_requested}</div>
                          <div className={classes.value}>{product?.kolicina}</div>
                        </div>
                      </div>
                      <div className={classes.additional_info_wrapper}>
                        <OfferTab
                          control={control}
                          getFieldState={getFieldState}
                          productId={index}
                          register={register}
                          t={t}
                          t_product={t_product}
                          key={index}
                          getValues={getValues}
                          errors={errors}
                          isAuction={props.advertisment.auction_start_date || props.advertisment.auction_end_date}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </form>
      <GlobalLoader show={isLoading} />
    </SlidingPane>
  );
};

export default SubmitOfferModal;
