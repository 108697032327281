import React from 'react';
import classes from './Loader.module.scss';
import { ILoader } from './model';

export const Loader = (props: ILoader) => {
  let classList = [classes.Loader];

  if (props.size === 'large') {
    classList.push(classes.Large);
  }

  if (!props.size || props.size === 'small') {
    classList.push(classes.Small);
  }

  return (
    <React.Fragment>
      <div className={classList.join(' ')}></div>
    </React.Fragment>
  );
};
