import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAuction, IAuctionOffer } from './auction.model';

const initialState: IAuction = {
  lastOffers: [
    // { bidder: '#3123467764', offerAmount: 22000 },
    // { bidder: '#3663341256', offerAmount: 34400 },
    // { bidder: '#9111040400', offerAmount: 28500 },
    // { bidder: '#3210099404', offerAmount: 31800 },
    // { bidder: '#1231344050', offerAmount: 33300 },
    // { bidder: '#0098231789', offerAmount: 31500 },
  ],
};

const auctionSlice = createSlice({
  name: 'auction',
  initialState: initialState,
  reducers: {
    addNewOffer: (state, action: PayloadAction<IAuctionOffer>) => {
      state.lastOffers.push(action.payload);
    },
  },
});

export const auctionActions = auctionSlice.actions;
export default auctionSlice.reducer;
