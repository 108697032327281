import React from 'react';
import { useDispatch } from 'react-redux';

import { IPrimaryNavbar } from '../models/primary-navbar.model';
import Hamburger from '../../../../UI/Hamburger/Hamburger';
import { sidebarActions } from '../../../../../store/sidebar/sidebar';
import GlobalActions from './GlobalActions/GlobalActions';
// import GlobalSearch from "./GlobalSearch/GlobalSearch";

import classes from './GeneralNavbar.module.scss';

const GeneralNavbar = (props: IPrimaryNavbar) => {
  const dispath = useDispatch();

  const toggleSidebarHandler = () => {
    dispath(sidebarActions.toggle());
  };

  return (
    <React.Fragment>
      <div className="container h-100">
        <div className={classes.PrimaryNavbarContent}>
          <div className="flex-grow-1">
            <div className="d-flex" style={{ alignItems: 'center' }}>
              {/* <GlobalSearch /> */}
              <GlobalActions />
            </div>
          </div>
          {!props.hideSidebar && (
            <div className="d-block d-xl-none">
              <Hamburger onClick={toggleSidebarHandler} mode={'open'} />
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default GeneralNavbar;
