import { createSlice } from '@reduxjs/toolkit';
import { ITransalte } from './translations.model';

import translationsMk from './../../assets/i18n/mk.json';
import translationsEn from './../../assets/i18n/en.json';
import translationsAl from './../../assets/i18n/al.json';

const initialState: ITransalte = {
  appLanguage: 'mk',
  translations: {
    mk: translationsMk,
    en: translationsEn,
    al: translationsAl,
  },
};

const translationsSlice = createSlice({
  name: 'translations',
  initialState: initialState,
  reducers: {
    changeLanguage: (state, action) => {
      state.appLanguage = action.payload;
    },
    loadTranslations: (state, action) => {
      state.translations = action.payload;
    },
  },
});

export const translationsActions = translationsSlice.actions;
export default translationsSlice.reducer;
