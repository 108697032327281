import React, { useEffect, useState, useRef } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, EditorState, ContentState, convertFromHTML } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { IEditor } from './model/editor.model';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { v4 } from 'uuid';
import { IFileModel } from '../Upload/UploadButton/model/UploadButton.model';

import DropPdf from './../../../assets/icons/DropPdf.svg';

import classes from './Editor.module.scss';

const { REACT_APP_MAX_CHAT_FILES } = process.env;

const FileUploadComponent = ({ files }) => {
  const onClickHandler = () => {
    const el = document.getElementById('chat_file_upload');
    if (el) {
      el.click();
    }
  };

  const maxFiles = REACT_APP_MAX_CHAT_FILES ? parseInt(REACT_APP_MAX_CHAT_FILES) : 0;
  files = files ? files : 0;
  if (files === 0 || files < maxFiles) {
    return (
      <div onClick={onClickHandler} className={classes.attach_button}>
        <div className={classes.icon}></div>
      </div>
    );
  } else {
    return null;
  }
};

const CustomEditor = (props: IEditor) => {
  const _editorRef: any = useRef(null);

  const { REACT_APP_FILES_TYPE } = process.env;

  const translations = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage]['components'];
  });

  const [styleType, setStyleType] = useState<boolean>(false);

  const blocksFromHTML = convertFromHTML(props.field ? props.field.value! : '');
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)
    )
  );

  useEffect(() => {
    if (props?.removeFiles) {
      const el: any = document.getElementById('chat_file_upload');
      if (el) {
        el.value = '';
      }
    }
  }, [props?.removeFiles]);

  const onEditorStateChange = (value: EditorState) => {
    const contentState = value.getCurrentContent();
    const hasContent = contentState.hasText() || contentState.getBlockMap().first().getType() !== 'unstyled';
    setStyleType(hasContent);

    if (value) {
      const totalChars = convertToRaw(value.getCurrentContent()).blocks[0].text.length;
      if (props.maxCharacters && totalChars > props.maxCharacters) {
        return;
      }

      setEditorState(value);
      props.onValueChange?.(draftToHtml(convertToRaw(editorState.getCurrentContent())).trim(), _editorRef);
    }
  };

  // https://jpuri.github.io/react-draft-wysiwyg/#/docs
  const toolbarOptions = {
    options: ['inline', 'colorPicker', 'emoji', 'textAlign', 'list'],
    inline: {
      options: ['bold', 'italic', 'underline', 'strikethrough'],
    },
    textAlign: {
      inDropdown: true,
    },
    list: {
      options: ['ordered', 'unordered', 'indent', 'outdent'],
    },
  };

  const totalCharacters = convertToRaw(editorState.getCurrentContent()).blocks[0].text.length;

  const handleTextLength = () => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;

    // const totalChars = convertToRaw(editorState.getCurrentContent())
    //     .blocks[0].text.length;

    if (currentContentLength === +props.maxCharacters!) {
      return 'handled';
    }
  };

  if (!props.field?.value && props.field?.value.length !== totalCharacters) {
    const newState = EditorState.push(editorState, ContentState.createFromText(''), 'remove-range');
    setEditorState(newState);
  }

  const onDropHandler = ev => {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
    let files: File[] = [];

    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      [...ev.dataTransfer.items].forEach((item, i) => {
        // If dropped items aren't files, reject them
        if (item.kind === 'file') {
          const file: any = item.getAsFile();
          file.blob = new Blob([file]);
          files.push(file);
        }
      });
    } else {
      // Use DataTransfer interface to access the file(s)
      [...ev.dataTransfer.files].forEach((file, i) => {
        file.blob = new Blob([file]);
        files.push(file);
      });
    }

    const filesArray = Array.prototype.slice.call(files);

    Promise.all(filesArray.map(fileToDataURL)).then((res: any[]) => {
      const mapped = res.map(file => {
        return {
          ...file,
          id: v4(),
        };
      });

      props.onFileUpload?.(mapped);
    });
  };

  const onChangeHandler = event => {
    const files = event.target.files;
    if (!files) return;

    const filesArray = Array.prototype.slice.call(files);

    Promise.all(filesArray.map(fileToDataURL)).then((res: any[]) => {
      const mapped = res.map(file => {
        return {
          ...file,
          id: v4(),
        };
      });

      props.onFileUpload?.(mapped);
    });
  };

  const fileToDataURL = (file: File) => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = function (event) {
        resolve({
          id: v4(),
          parentId: '',
          blob: event.target?.result,
          name: file.name,
          type: file.name.split('.').pop(),
          fileType: file.type,
          size: file.size,
          File: file,
        } as IFileModel);
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <React.Fragment>
      <div
        onDrop={e => {
          if (props.showDropArea) {
            onDropHandler(e);
          }
        }}
        className={`custom-wysiwyg ${classes.Container} ${
          props.field?.errorMessage && props.field.touched ? 'error' : ''
        } ${props.small ? 'small-editor' : ''} ${props.borderless ? 'borderless-editor' : ''}`}
      >
        <div className={classes.HeaderContainer}>
          <div className={classes.Label}>{props.label}</div>
          <div className={classes.CharCounter}>
            <span className={classes.TotalCharacters}>{totalCharacters}</span>
            {props.maxCharacters && <span className={classes.MaxCharacters}>/{props.maxCharacters}</span>}
            <span className={classes.CharLabel}>{translations['карактери']}</span>
          </div>
        </div>
        <Editor
          ref={_editorRef}
          editorState={editorState}
          toolbarClassName="custom-toolbar"
          wrapperClassName="custom-wrapper"
          editorClassName="custom-editor"
          value
          toolbar={toolbarOptions}
          toolbarCustomButtons={[<FileUploadComponent files={props?.files} />]}
          onChange={value => {
            props.textLength?.(value.blocks[0].text.trim().length);
          }}
          placeholder={styleType ? '' : `${props.placeholder || props.label}...`}
          onEditorStateChange={onEditorStateChange}
          handleBeforeInput={handleTextLength}
          handlePastedText={handleTextLength}
          handleReturn={event => {
            const { shiftKey, keyCode, key } = event;
            if (!shiftKey && (keyCode === 13 || key === 'Enter') && props?.sendMessage) {
              const message = draftToHtml(convertToRaw(editorState.getCurrentContent())).trim();
              const message_length = convertToRaw(editorState.getCurrentContent()).blocks[0].text.length;
              props.sendMessage(message, message_length);
              setEditorState(() => EditorState.createEmpty());
              props.textLength?.(0);
              return 'handled';
            }
          }}
        />

        {props.showDropArea ? (
          <div
            className={`${classes.DropArea} ${props.isChat ? classes.IsChat : ''} ${
              totalCharacters > 0 ? classes.Opacity : ''
            }`}
          >
            <div className={classes.DropContainerBorder}>
              <div className={classes.Border}></div>
            </div>
            <div className={classes.DropContainerImg}>
              <img src={DropPdf} alt="DropPdf" />
            </div>
          </div>
        ) : (
          <input
            type="file"
            id="chat_file_upload"
            multiple={true}
            accept={REACT_APP_FILES_TYPE}
            style={{
              display: 'none',
            }}
            onChange={onChangeHandler}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default CustomEditor;
