import React from 'react';

import Spinner from '../../../assets/icons/spinner.gif';

import classes from './GlobalLoader.module.scss';

const GlobalLoader = (props: { show?: boolean }) => {
  return (
    <React.Fragment>
      <div
        className={classes.Container}
        style={
          props.hasOwnProperty('show')
            ? props.show
              ? { visibility: 'visible' }
              : { visibility: 'hidden' }
            : { visibility: 'visible' }
        }
      >
        <div className={classes.Loader}>
          <img src={Spinner} alt="spinner" />
        </div>
      </div>
    </React.Fragment>
  );
};

export default GlobalLoader;
