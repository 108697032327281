import React from 'react';
import { IUploadedFile } from './model/UploadedFile.model';
import classes from './UploadedFile.module.scss';

import CloseAccent from './../../../../../assets/icons/CloseAccent.svg';
import dayjs from 'dayjs';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileExcel, faFileWord, faFileImage, faFileLines } from '@fortawesome/free-solid-svg-icons';

import PdfIcon from './../../../../../assets/icons/Pdf.svg';

const FILE_ICON_BY_FILE_TYPE = {
  xlsx: faFileExcel,
  xls: faFileExcel,
  txt: faFileLines,
  pdf: faFilePdf,
  png: faFileImage,
  jpg: faFileImage,
  jpeg: faFileImage,
  doc: faFileWord,
  docx: faFileWord,
};

const UploadedFile = (props: IUploadedFile) => {
  const bytesToSize = (bytes: number): { value: number; size: string } => {
    const sizes = ['b', 'kb', 'MB', 'GB', 'TB'];
    if (bytes === 0)
      return {
        value: 0,
        size: 'byte',
      };
    const i = Math.floor(Math.log(bytes) / Math.log(1024));

    return {
      value: Math.round(bytes / Math.pow(1024, i)),
      size: sizes[i],
    };
  };

  let currentFileSize: { value: number; size: string } = {
    value: 0,
    size: '',
  };

  currentFileSize = bytesToSize(props.file.size);

  const onClearHandler = () => {
    props.removeFile();
  };

  const fileExtension = props.file.name.split('.').pop();
  const fileIcon = FILE_ICON_BY_FILE_TYPE[fileExtension!];

  let uploadedFiles = (
    <React.Fragment>
      <div className={classes.UploadedFileSingle}>
        <div className={classes.FileInput}>
          <div className={classes.FileIcon}>
            {fileExtension === 'pdf' ? (
              <img src={PdfIcon} alt="file" />
            ) : (
              <FontAwesomeIcon icon={fileIcon} size={'2x'} color={'#cfdae6ab'} />
            )}
          </div>
          <div className={classes.FileInfo}>
            <div className={classes.FileName}>{props.file.name}</div>
            <div
              className={`${classes.FileSizeLimit} ${
                currentFileSize.value > (props.maxAllowedMB || 3) ? classes.InvalidSize : null
              }`}
            >
              {`${currentFileSize.value}${currentFileSize.size}`} од {props.maxAllowedMB || 3}MB
            </div>
          </div>
        </div>
        <div className={classes.FileProgress}>
          <div className={classes.ProgressBar}></div>
          <div className={classes.ClearButton}>
            <button onClick={onClearHandler} type="button">
              <img src={CloseAccent} alt="close" />
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  if (props.oneLine) {
    if (!props.minimal) {
      uploadedFiles = (
        <React.Fragment>
          <div className={classes.UploadedFileOneLine}>
            <div className={classes.FileInput}>
              <div className={classes.FileIcon}>
                {fileExtension === 'pdf' ? (
                  <img src={PdfIcon} alt="file" />
                ) : (
                  <FontAwesomeIcon icon={fileIcon} size={'2x'} color={'#cfdae6ab'} />
                )}
              </div>
              <div className={classes.FileName}>{props.file.name}</div>
              <div style={{ alignSelf: 'center' }}>•</div>
              <div
                className={`${classes.FileSizeLimit} ${
                  currentFileSize.value > (props.maxAllowedMB || 3) ? classes.InvalidSize : null
                }`}
              >
                {`${currentFileSize.value}${currentFileSize.size}`} од {props.maxAllowedMB || 3}MB
              </div>
              <div className={classes.FileProgress}>
                <div className={classes.ProgressBar}></div>
                <div className={classes.ClearButton}>
                  <button onClick={onClearHandler} type="button">
                    <img src={CloseAccent} alt="close" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      uploadedFiles = (
        <React.Fragment>
          <div className={`${classes.UploadedFileOneLine} ${classes.Minimal}`}>
            <div className={classes.FileInput}>
              <div className={classes.FileIcon}>
                {fileExtension === 'pdf' ? (
                  <img src={PdfIcon} alt="file" />
                ) : (
                  <FontAwesomeIcon icon={fileIcon} size={'2x'} color={'#cfdae6ab'} />
                )}
              </div>
              <div className={classes.FileName}>{props.file.name}</div>
              {/* <div style={{ alignSelf: "center" }}>•</div>
							<div
								className={`${classes.FileSizeLimit} ${
									currentFileSize.value >
									(props.maxAllowedMB || 3)
										? classes.InvalidSize
										: null
								}`}
							>
								{`${currentFileSize.value}${currentFileSize.size}`}{" "}
								од {props.maxAllowedMB || 3}MB
							</div> */}
              <button className={classes.MinimalButton} onClick={onClearHandler} type="button">
                <img src={CloseAccent} alt="close" />
              </button>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  if (props.multiple) {
    uploadedFiles = (
      <React.Fragment>
        <div className={classes.UploadedFilesMultiple}>
          <div className={classes.FileIcon}>
            <FontAwesomeIcon icon={fileIcon} size={'4x'} color={'#cfdae6ab'} />
          </div>
          <div className={classes.FileInfo}>
            <div className={classes.FileName}>{props.file.name}</div>
            <div className={classes.FileAdditionalInfo}>
              {dayjs().format('DD.MM.YYYY во hh:mm')} • {`${currentFileSize.value}${currentFileSize.size}`}
            </div>
          </div>
          <div className={classes.ClearButton}>
            <button onClick={onClearHandler}>
              <img src={CloseAccent} alt="close" />
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }

  if (props.chatOneLine) {
    uploadedFiles = (
      <React.Fragment>
        <div className={`${classes.UploadedFileSingle} ${classes.chat_file_upload}`}>
          <div className={classes.FileInput}>
            <div className={classes.FileInfo}>
              <div className={classes.FileIcon}>
                {fileExtension === 'pdf' ? (
                  <img src={PdfIcon} alt="file" />
                ) : (
                  <FontAwesomeIcon
                    icon={fileIcon}
                    size={'2x'}
                    color={'#cfdae6ab'}
                    style={{ width: '17px', height: '24px' }}
                  />
                )}
              </div>
              <div className={classes.FileName}>{props.file.name}</div>
              <div className={classes.dot_icon}></div>
              <div
                className={`${classes.FileSizeLimit} ${
                  currentFileSize.value > (props.maxAllowedMB || 3) ? classes.InvalidSize : null
                }`}
              >
                {`${currentFileSize.value}${currentFileSize.size}`} од {props.maxAllowedMB || 3}MB
              </div>
            </div>
            <div className={classes.FileProgress}>
              <div className={classes.ProgressBar}></div>
              <div className={classes.ClearButton}>
                <button onClick={onClearHandler} type="button">
                  <img src={CloseAccent} alt="close" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  return uploadedFiles;
};

export default UploadedFile;
