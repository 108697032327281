import axios from 'axios';
import { LocalStorageEnum } from '../../enums/local-storage-keys';

let token = localStorage.getItem(LocalStorageEnum.UserToken);

/**
 * @deprecated
 */
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL ?? 'https://api-tender.echo-dev.net/api/v1',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]')!.getAttribute('content')!,
    Authorization: `Bearer ${token}`,
  },
});

export const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

client.interceptors.request.use(
  config => {
    const token = localStorage.getItem(LocalStorageEnum.UserToken);

    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      },
    };
  },
  error => Promise.reject(error)
);

export default axiosInstance;
