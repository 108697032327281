import React, { useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RootState } from '../../../../store';
import { RoutesEnum } from '../../../../enums/routes';

import MessageIcon from '../../../../assets/icons/Message.svg';
import NoImageIcon from '../../../../assets/icons/No-image-icon.svg';

import classes from './ConfirmModalCompany/ConfirmModalCompany.module.scss';
import { download } from '../../../../utils/download';
import { AdditionalOffersQuery, OfferProduct, OfferProductStatus, useCompanyOffers } from '../../../../queries/offer';
import { Ad } from '../../../../queries/ad';
import { getLogoUrl } from '../../../../utils/company';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import { OfferStatusDisplay } from '../../../../components/OfferStatusDisplay';
import dayjs from 'dayjs';
import { Axios } from 'axios';
import GlobalLoader from '../../../../components/UI/GlobalLoader/GlobalLoader';

interface Props {
  show: boolean;
  confirmBtnText?: string | null;
  data: any;
  ad: Ad;
  onHandleClose: () => void;
  onHandleConfirm: (status: OfferProductStatus, id: string) => void;
  own?: boolean;
  productId: number;
}

export function OfferCompanyModal({
  show,
  data,
  ad,
  confirmBtnText,
  onHandleClose,
  onHandleConfirm,
  own,
  productId,
}: Props) {
  const [query, setQuery] = useState<AdditionalOffersQuery>({
    pagination: { page: 1, size: 10 },
  });
  const offers = useCompanyOffers(data.company.id, productId, query);

  const navigate = useNavigate();

  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage];
  });
  const t_global = t.global;

  const columns = useMemo(
    () => [
      {
        dataField: 'product.title',
        text: t.product.product,
        sort: true,
      },
      {
        dataField: 'catalog_number',
        text: t.offers.catalog_number,
        sort: true,
      },
      {
        dataField: 'quantity',
        text: t.offers.offered_quantity,
        sort: true,
      },
      {
        dataField: 'amount.price',
        text: t.product.price,
        sort: true,
      },
      {
        dataField: 'full_price',
        text: t.offers.total_price,
        formatter: (cell, row) => {
          return Number(row.amount.price) * Number(row.quantity);
        },
        sort: true,
      },
      {
        dataField: 'delivery_due_days',
        text: t.offers.due_date_asked,
        formatter: (cell, row) => ad.delivery_due_days,
        sort: true,
      },
      {
        dataField: 'delivery_due_days',
        text: t.offers.due_date_offered,
        sort: true,
      },
      {
        dataField: 'amount.status',
        text: t.offers.status,
        formatter: (cell, row) => <OfferStatusDisplay status={cell} />,
        sort: true,
      },
      {
        dataField: 'actions',
        text: '',
        // hidden: ad && !dayjs().isAfter(dayjs(ad?.auction_end_date)),
        formatter: (cell, row) => {
          if (!own && row.amount.status === OfferProductStatus.PENDING)
            return (
              <div className={classes.actions}>
                <div className={classes.buttons_wrapper}>
                  <button
                    className={classes.refuse_btn}
                    onClick={() => onHandleConfirm(OfferProductStatus.DECLINED, row.id)}
                  >
                    <span className={classes.icon}></span>
                    {t.my_adds_offers.reject_offer}
                  </button>
                  <button
                    className={classes.accept_btn}
                    onClick={() => onHandleConfirm(OfferProductStatus.ACCEPTED, row.id)}
                  >
                    <span className={classes.icon}></span>
                    {confirmBtnText || t.my_adds_offers.accept_offer}
                  </button>
                </div>
              </div>
            );
        },
      },
    ],
    [t]
  );

  function caret(order, column) {
    if (!order) {
      return (
        <span style={{ display: 'inline-block', position: 'relative', top: '-5px' }}>
          <span className={classes.sort_wrapper}>
            <span className={`${classes.caret} ${classes.caret_up}`}></span>
            <span className={classes.caret}></span>
          </span>
        </span>
      );
    } else if (order === 'asc') {
      return (
        <span style={{ display: 'inline-block', position: 'relative', top: '-2px' }}>
          <span className={classes.sort_wrapper}>
            <span className={`${classes.caret} ${classes.caret_up}`}></span>
          </span>
        </span>
      );
    } else if (order === 'desc') {
      return (
        <span style={{ display: 'inline-block', position: 'relative', top: '-2px' }}>
          <span className={classes.sort_wrapper}>
            <span className={classes.caret}></span>
          </span>
        </span>
      );
    } else {
      return null;
    }
  }

  const total = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {`${t_global['table_total_text']} ${from} ${t_global['table_total_text_1']} ${to} ${t_global['table_total_text_2']} ${size} ${t_global['table_total_text_3']}`}
    </span>
  );

  const paginationOptions = {
    custom: true,
    totalSize: offers.data?.total ?? 0,
    page: query.pagination.page,
    sizePerPage: query.pagination.size,
    paginationSize: 4,
    pageStartIndex: 1,
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    hideSizePerPage: false,
    hidePageListOnlyOnePage: false,
    firstPageText: '<<',
    prePageText: '<',
    nextPageText: '>',
    lastPageText: '>>',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: total,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
    ],
  };

  const onTableChange = (_type, { page, sizePerPage, sortField, sortOrder }) => {
    setQuery(prev => ({ ...prev, pagination: { page, size: sizePerPage }, sort: { sortBy: sortField, sortOrder } }));
  };

  return (
    <React.Fragment>
      <Modal show={show} className={classes.modal_wrapper} centered size="xl" onHide={onHandleClose}>
        <Modal.Header className={classes.custom_header} closeButton>
          <Modal.Title>{t.my_adds_offers.company}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={classes.custom_body}>
          <div className={classes.company_info_wrapper}>
            <div className={classes.image_wrapper}>
              <div className={classes.image_container}>
                <img
                  src={getLogoUrl(data.company)}
                  alt="company logo"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = NoImageIcon;
                  }}
                />
              </div>
              <div className={classes.company_name}>{data.company.naziv}</div>
            </div>
            <div className={classes.company_info}>
              <div className={classes.company_name}>{data.company.naziv}</div>
              <div className={classes.website}>{data.company.web_location}</div>
              <div className={classes.email}>{data.company.email}</div>
              <div className={classes.address}>{data.company.address}</div>
              <div className={classes.phone}>{data.company.phone_number}</div>
            </div>
            <div className={classes.message_icon}>
              <div className={classes.image_wrapper} onClick={() => navigate(RoutesEnum.Messages)}>
                <img src={MessageIcon} alt="message icon" />
              </div>
            </div>
          </div>
          <div className={classes.contact_wrapper}>
            <div className={classes.title}>{t.my_adds_offers.contact_person}</div>
            <div className={classes.contact_info_wrapper}>
              <div className={classes.name}>{ad.contact_name}</div>
              {/* <div className={classes.email}>{ad.contact_email}</div> */}
              <div className={classes.phone}>{ad.contact_phone}</div>
            </div>
          </div>
          <div className={classes.table_wrapper}>
            <div className={classes.table_container}>
              <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => (
                  <div>
                    <BootstrapTable
                      keyField="id"
                      remote={{ pagination: true }}
                      onTableChange={onTableChange}
                      data={offers.data?.data ?? []}
                      columns={columns}
                      classes={classes.offers_table}
                      noDataIndication={offers.isLoading ? t_global.loading : t_global.no_data}
                      sort={{ sortCaret: caret }}
                      {...paginationTableProps}
                    />
                    <div className={classes.pagination_wrapper}>
                      <PaginationTotalStandalone {...paginationProps} />
                      <div>
                        <PaginationListStandalone {...paginationProps} />
                        <SizePerPageDropdownStandalone {...paginationProps} />
                      </div>
                    </div>
                  </div>
                )}
              </PaginationProvider>
            </div>
          </div>
          <div className={classes.comment_wrapper}>
            <div className={classes.title}>{t.my_adds_offers.comment}</div>
            <textarea
              name="comment"
              placeholder={t.my_adds_offers.comment}
              rows={4}
              value={data.comment ?? ''}
              disabled={true}
            ></textarea>
          </div>
          {/* {data.documents.length > 0 && (
            <div className={classes.additional_docs_wrapper}>
              <div className={classes.title}>{t.my_adds_offers.additional_docs}</div>
              <div className={classes.docs_wrapper}>
                {data.documents.map((doc, i) => {
                  return (
                    <div
                      className={classes.doc}
                      onClick={() => {
                        download(`/offers/${doc.offer_id}/files/${doc.id}`);
                      }}
                    >
                      <div className={classes.icon}></div>
                      {doc.file_name}
                    </div>
                  );
                })}
              </div>
            </div>
          )} */}
        </Modal.Body>
        {/* <Modal.Footer className={classes.custom_footer}>
          <div className={classes.price_wrapper}>
            <div className={classes.label}>{t.my_adds_offers.offered_price}:</div>
            <div className={classes.price}>{data.offer_price}</div>
          </div>
          
        </Modal.Footer> */}
      </Modal>
    </React.Fragment>
  );
}
