import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import classes from './Countdown.module.scss';
import { ICountdown } from './model';

const Countdown = (props: ICountdown) => {
  const countDownDate =
    props.mode === 'auction' || props.mode === 'auction_prev' || props.mode === 'light'
      ? new Date(props.date).getTime()
      : new Date(`${props.date} ${props.time ?? '23:59:59'}`).getTime();

  const [countDown, setCountDown] = useState(countDownDate - new Date().getTime());

  const translations = useSelector(
    (state: RootState) => state.translations.translations[state.translations.appLanguage]['counter']
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  if (countDown < 0) {
    if (props?.onFinish) {
      props.onFinish();
    }
    return (
      <div style={{ textAlign: 'center' }}>
        {props.returnTranslation ? props.returnTranslation : dayjs(props.date).format('DD.MM.YYYY')}
      </div>
    );
  }

  // const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return (
    <React.Fragment>
      {(!props.mode || props.mode === 'dark') && (
        <div className={classes.Container}>
          {/* <div className={classes.Timer}>
            <div className={classes.Value}>
              {days <= 9 && days !== 0 ? '0' : ''}
              {days}
            </div>
            <div className={classes.Label}>{translations['денови']}</div>
          </div> */}
          <div className={classes.Timer}>
            <div className={classes.Value}>
              {hours <= 9 && hours !== 0 ? '0' : ''}
              {hours}
            </div>
            <div className={classes.Label}>{translations['часови']}</div>
          </div>
          <div className={classes.Timer}>
            <div className={classes.Value}>
              {minutes <= 9 && minutes !== 0 ? '0' : ''}
              {minutes}
            </div>
            <div className={classes.Label}>{translations['минути']}</div>
          </div>
          <div className={classes.Timer}>
            <div className={classes.Value}>
              {seconds <= 9 && seconds !== 0 ? '0' : ''}
              {seconds}
            </div>
            <div className={classes.Label}>{translations['секунди']}</div>
          </div>
        </div>
      )}

      {(props.mode === 'light' || props.mode === 'auction_prev') && (
        <div className={classes.ContainerLight}>
          {/* <div className={classes.Timer}>
            <div className={classes.Value}>{days < 10 ? `0${days}` : days}</div>
            <div className={classes.Label}>{translations['денови']}</div>
          </div> */}
          {/* <div className={classes.Colon}>:</div> */}
          <div className={classes.Timer}>
            <div className={classes.Value}>{hours < 10 ? `0${hours}` : hours}</div>
            <div className={classes.Label}>{translations['часови']}</div>
          </div>
          <div className={classes.Colon}>:</div>
          <div className={classes.Timer}>
            <div className={classes.Value}>{minutes < 10 ? `0${minutes}` : minutes}</div>
            <div className={classes.Label}>{translations['минути']}</div>
          </div>
          <div className={classes.Colon}>:</div>
          <div className={classes.Timer}>
            <div className={classes.Value}>{seconds < 10 ? `0${seconds}` : seconds}</div>
            <div className={classes.Label}>{translations['секунди']}</div>
          </div>
        </div>
      )}

      {props.mode === 'separated' && (
        <div className={classes.SeparatedContainer}>
          {/* <div className={classes.Timer}>
            <div className={classes.Value}>
              {days <= 9 && days !== 0 ? '0' : ''}
              {days}
            </div>
            <div className={classes.Label}>{translations['денови']}</div>
          </div> */}
          <div className={classes.Timer}>
            <div className={classes.Value}>
              {hours <= 9 && hours !== 0 ? '0' : ''}
              {hours}
            </div>
            <div className={classes.Label}>{translations['часови']}</div>
          </div>
          <div className={classes.Timer}>
            <div className={classes.Value}>
              {minutes <= 9 && minutes !== 0 ? '0' : ''}
              {minutes}
            </div>
            <div className={classes.Label}>{translations['минути']}</div>
          </div>
          <div className={classes.Timer}>
            <div className={classes.Value}>
              {seconds <= 9 && seconds !== 0 ? '0' : ''}
              {seconds}
            </div>
            <div className={classes.Label}>{translations['секунди']}</div>
          </div>
        </div>
      )}

      {props.mode === 'auction' && (
        <div className={classes.container_auction}>
          {/* {days > 0 && (
            <>
              <div className={classes.timer}>
                <div className={classes.value}>{days < 10 ? `0${days}` : days}</div>
                <div className={classes.label}>{translations['денови']}</div>
              </div>
              <div className={classes.colon}>:</div>
            </>
          )} */}
          <div className={classes.timer}>
            <div className={classes.value}>{hours < 10 ? `0${hours}` : hours}</div>
            <div className={classes.label}>{translations['часови']}</div>
          </div>
          <div className={classes.colon}>:</div>
          <div className={classes.timer}>
            <div className={classes.value}>{minutes < 10 ? `0${minutes}` : minutes}</div>
            <div className={classes.label}>{translations['минути']}</div>
          </div>
          <div className={classes.colon}>:</div>
          <div className={classes.timer}>
            <div className={classes.value}>{seconds < 10 ? `0${seconds}` : seconds}</div>
            <div className={classes.label}>{translations['секунди']}</div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Countdown;
