import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';

import { RootState } from '../../../../../../../store';

import classes from './TermsAndConditions.module.scss';

const TermsAndConditionsModal = props => {
  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage];
  });

  return (
    <React.Fragment>
      <Modal show={props.show} size="xl" centered>
        <Modal.Body className={classes.body_style}>
          <div className={classes.modal_title}>{t.create_ad.terms_and_conditions}</div>
          <div className={classes.content_container}>
            <div className={classes.title}>{t.create_ad.copyright_protection}</div>
            <div
              className={classes.text}
              dangerouslySetInnerHTML={{
                __html: t.create_ad.copyright_protection_text,
              }}
            ></div>
            <div className={classes.title}>{t.create_ad.responsibility}</div>
            <div
              className={classes.text}
              dangerouslySetInnerHTML={{
                __html: t.create_ad.responsibility_text,
              }}
            ></div>
            <div className={classes.title}>{t.create_ad.protection_of_privacy}</div>
            <div
              className={classes.text}
              dangerouslySetInnerHTML={{
                __html: t.create_ad.protection_of_privact_text,
              }}
            ></div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className={classes.close_btn} onClick={props.toggleModal}>
            {t.global.close}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default TermsAndConditionsModal;
