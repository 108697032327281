import React, { useState, useReducer, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isValidPhoneNumber } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input/input';
import { useNavigate } from 'react-router-dom';
import * as FileSaver from 'file-saver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileExcel, faFileWord, faFileLines } from '@fortawesome/free-solid-svg-icons';

import Page from '../../components/Layout/Page/Page';
import Tabs from '../../components/UI/Tabs/Tabs';
import Switch from '../../components/UI/Switch/Switch';
import { RootState } from '../../store';
import { httpPost, httpGet } from '../../services/http';
import { showNotification, hideNotifications } from '../../notification_functions/notifications_functions';
import GlobalLoader from '../../components/UI/GlobalLoader/GlobalLoader';

import FileIcon from '../../assets/icons/Acrobat_Reader_icon.svg';
import imagePlaceholder from '../../assets/imgPlaceholder.png';

import 'react-phone-number-input/style.css';
import classes from './Profile.module.scss';
import { useProfile } from './queries';
import { ProfileForm } from './ProfileForm';
import { useIndustries } from '../../queries';

const ProfilePage = () => {
  const FILE_ICON_BY_FILE_TYPE = {
    xlsx: faFileExcel,
    xls: faFileExcel,
    txt: faFileLines,
    pdf: faFilePdf,
    doc: faFileWord,
    docx: faFileWord,
  };

  const { REACT_APP_FILES_TYPE } = process.env;

  const navigate = useNavigate();

  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage];
  });

  const [activeTab, setActiveTab] = useState(1);
  const [sendNotifications, setSendNotifications] = useState<boolean>(false);
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const [categories, setCategories]: any = useState([]);
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const { data, isLoading } = useProfile();
  const { data: industries } = useIndustries();

  const [tabOneData, setTabOneData] = useState<any>({
    image: '',
    naziv: '',
    edinstven_danocen_broj: '',
    maticen_broj_ekonomski_operator: '',
    address: '',
    city: '',
    municipality: '',
    phone_number: '',
    fax: '',
    company_email: '',
    website: '',
    postal_code: '',
    ads_info: [],
    company_info: '',
    current_state: {
      name: '',
      fileDocument: '',
      size: 0,
      type: '',
    },
  });
  const [tabOneErrors, setTabOneErrors] = useState({
    image: '',
    address: '',
    city: '',
    municipality: '',
    phone_number: '',
    fax: '',
    company_email: '',
    website: '',
    postal_code: '',
  });

  const [tabTwoErrors, setTabTwoErrors] = useState({
    first_name: '',
    last_name: '',
    mobile_number: '',
    email: '',
    password: '',
    user_phone_number: '',
    postal_number: '',
  });
  const [tabTwoData, setTabTwoData] = useState({
    first_name: '',
    last_name: '',
    mobile_number: '',
    email: '',
    password: '',
    user_phone_number: '',
    postal_number: '',
  });

  const getProfileDetails = async () => {
    try {
      const response = await httpGet<any>('/user/get-details?XDEBUG_SESSION_START=phpstorm');
      if (response?.data?.user) {
        const { user } = response.data;
        const { company } = user;

        const company_files = company?.company_files?.length > 0 ? company.company_files : null;
        let image = '';
        let file = '';
        if (company_files) {
          const get_image = company_files.filter(file => file.type === '2');
          if (get_image.length > 0) {
            image = get_image[0]?.file_name || '';
          }
          const get_file = company_files.filter(file => file.type === '1');
          if (get_file.length > 0) {
            file = get_file[0]?.file_name || '';
          }
        }
        setTabOneData({
          image,
          naziv: company?.naziv,
          edinstven_danocen_broj: company?.edinstven_danocen_broj,
          maticen_broj_ekonomski_operator: company?.maticen_broj,
          address: company?.address,
          city: company?.city,
          municipality: company?.municipality,
          phone_number: company?.phone_number,
          fax: company?.fax,
          company_email: company?.email,
          website: company?.web_location,
          postal_code: company?.postal_code,
          ads_info: company?.industry && company?.industry?.length > 0 ? company.industry.map(cat => cat.id) : [],
          company_info: '',
          current_state: {
            name: file ? file.split('/').pop() : '',
            fileDocument: file,
            size: 0,
            type: '',
          },
        });

        setSendNotifications(user?.newsletter);

        setTabTwoData({
          first_name: user?.first_name,
          last_name: user?.last_name,
          mobile_number: user?.cell_phone_number,
          email: user?.email,
          password: '',
          user_phone_number: user?.phone_number,
          postal_number: user?.postal_code,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleImageChange = event => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = e => {
      if (e.target) {
        setTabOneData({
          ...tabOneData,
          image: e.target.result,
        });
      }
    };

    reader.readAsDataURL(file);
  };

  const fetchCategories = async () => {
    try {
      const response = await httpGet<any>('/industry/get?XDEBUG_SESSION_START=phpstorm');
      if (response?.data?.data.length > 0) {
        setCategories(response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCategories();
    getProfileDetails();
  }, []);

  useEffect(() => () => hideNotifications(), []);

  const onInputChange = (tab, event) => {
    let { name, value, required } = event.target;
    if (value === undefined) {
      value = '';
    }
    if (tab === 'tab_one') {
      const errors = tabOneErrors;
      setTabOneData({
        ...tabOneData,
        [name]: value,
      });
      if (required) {
        if (value.length === 0) {
          errors[name] = t.profile.input_required;
        } else {
          errors[name] = '';
          validateField(tab, name, value, errors);
        }
      } else {
        validateField(tab, name, value, errors);
      }
    } else if (tab === 'tab_two') {
      const errors = tabTwoErrors;
      setTabTwoData({
        ...tabTwoData,
        [name]: value,
      });
      if (required) {
        if (value.length === 0) {
          errors[name] = t.profile.input_required;
        } else {
          errors[name] = '';
          validateField(tab, name, value, errors);
        }
      } else {
        validateField(tab, name, value, errors);
      }
    }
  };

  const validateField = (tab, fieldName, value, errors) => {
    switch (fieldName) {
      case 'company_email':
      case 'email':
        const emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        errors[fieldName] = emailValid || value.length === 0 ? '' : t.profile.invalid_email;
        break;
      case 'password':
        const passwordValid = value.length >= 6;
        errors[fieldName] = passwordValid ? '' : t.profile.wrong_password;
        break;
      case 'postal_number':
        errors[fieldName] = value.length < 5 ? '' : t.profile.invalid_postal_number;
        break;
      case 'mobile_number':
      case 'user_phone_number':
      case 'phone_number':
      case 'fax':
        errors[fieldName] = isValidPhoneNumber(value) ? '' : t.profile.invalid_phone_number;
        break;
      default:
        break;
    }

    if (tab === 'tab_one') {
      setTabOneErrors(errors);
    } else if (tab === 'tab_two') {
      setTabTwoErrors(errors);
    }
  };

  const onCheckboxChange = e => {
    const { cat_id, checked } = e.target;
    const newData = { ...tabOneData };
    if (checked) {
      newData.ads_info = [...newData.ads_info, cat_id];
    } else {
      newData.ads_info = newData.ads_info.filter(n => n !== cat_id);
    }
    setTabOneData(newData);
  };

  const scrolltoId = id => {
    const access: any = document.getElementById(id);
    if (access) {
      access.scrollIntoViewIfNeeded();
    }
  };

  const onDokumentChange = e => {
    const file = e.target.files[0];
    let reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = e => {
      if (e.target) {
        const filename = file.name;
        const dataFile = e.target.result as string;
        const type = filename.split('.').pop();
        let size = file.size;
        if (size > 0) {
          switch (true) {
            case size / 1024 >= 1:
              size = `${Math.floor(size / 1024)} KB`;
              break;
            case size / 1024 / 1024 >= 1:
              size = `${Math.floor(size / 1024 / 1024)} MB`;
              break;
            default:
              size = `${Math.floor(size)} B`;
              break;
          }
        }
        setTabOneData({
          ...tabOneData,
          current_state: {
            name: filename,
            fileDocument: dataFile,
            size,
            type,
          },
        });
      }
    };
  };

  const onDocumentRemove = () => {
    setTabOneData({
      ...tabOneData,
      current_state: {
        name: '',
        fileDocument: '',
        size: 0,
        type: '',
      },
    });
  };

  const saveData = async () => {
    const inputsTabOne = document.querySelectorAll('#tab_one_form input[required]');
    const inputsTabTwo = document.querySelectorAll('#tab_two_form > div input[required]');
    const invalidFieldsTabOne = tabOneErrors;
    const invalidFieldsTabTwo = tabTwoErrors;
    let hasErrorTabOne = false;
    let hasErrorTabTwo = false;

    inputsTabOne.forEach(input => {
      if (input.getAttribute('value') === '') {
        const nameAttr: any = input.getAttribute('name');
        invalidFieldsTabOne[nameAttr] = t.profile.input_required;
        hasErrorTabOne = true;
      } else if (input.getAttribute('name') === 'phone_number' || input.getAttribute('name') === 'fax') {
        if (!isValidPhoneNumber(input.getAttribute('value') || '')) {
          const nameAttr: any = input.getAttribute('name');
          invalidFieldsTabOne[nameAttr] = t.profile.invalid_phone_number;
          hasErrorTabOne = true;
        }
      }
    });

    inputsTabTwo.forEach(input => {
      if (input.getAttribute('value') === '') {
        const nameAttr: any = input.getAttribute('name');
        invalidFieldsTabTwo[nameAttr] = t.profile.input_required;
        hasErrorTabTwo = true;
      } else if (input.getAttribute('name') === 'mobile_number' || input.getAttribute('name') === 'user_phone_number') {
        if (!isValidPhoneNumber(input.getAttribute('value') || '')) {
          const nameAttr: any = input.getAttribute('name');
          invalidFieldsTabTwo[nameAttr] = t.profile.invalid_phone_number;
          hasErrorTabTwo = true;
        }
      }
    });

    if (hasErrorTabOne || hasErrorTabTwo) {
      if (hasErrorTabOne) {
        setActiveTab(1);
        setTimeout(() => {
          scrolltoId('tab_one_form');
        }, 100);
      } else if (hasErrorTabTwo) {
        setActiveTab(2);
        setTimeout(() => {
          scrolltoId('tab_two_form');
        }, 100);
      }
      setTabOneErrors(invalidFieldsTabOne);
      setTabTwoErrors(invalidFieldsTabTwo);
      forceUpdate();
    } else {
      setShowLoader(true);
      // tabOneData => here is data from Economic Operator tab
      // tabTwoData => here is data from Administrator tab
      // sendNotifications => here is value for Info about new ads (boolen)
      let dataToSend = Object.assign({}, tabOneData, tabTwoData);
      dataToSend = JSON.parse(JSON.stringify(dataToSend));
      dataToSend['newsletter'] = sendNotifications;

      if (dataToSend?.current_state?.name === '' && dataToSend?.current_state?.fileDocument === '') {
        delete dataToSend.current_state;
      }

      try {
        const response = await httpPost<any, any>('/user/profile/update?XDEBUG_SESSION_START=phpstorm', dataToSend);
        if (response?.message && response.message === 'Success') {
          showNotification(t.profile.success_message, 'success');
        } else {
          showNotification(t.profile.error_message, 'danger');
        }
        setShowLoader(false);
      } catch (error) {
        console.error(error);
        showNotification(t.profile.error_message, 'danger');
        setShowLoader(false);
      }
    }
  };

  const downloadDocument = (file, file_name) => {
    if (file && file_name) {
      FileSaver.saveAs(file, file_name);
    }
  };

  return (
    <React.Fragment>
      <Page>
        <div className={classes.profile_page_wrapper}>
          {/* <div className={classes.page_title}>{t.profile.title}</div> */}
          {!isLoading && <ProfileForm industries={industries?.data ?? []} data={data!} />}
          {/* <div className={classes.tabs_wrapper}>
            <div className={classes.title}>{t.profile.subtitle}</div>
            <div className={classes.tabs}>
              <Tabs
                tabs={[
                  {
                    id: 1,
                    label: t.profile.tab_one_title,
                    active: activeTab === 1,
                  },
                  {
                    id: 2,
                    label: t.profile.tab_two_title,
                    active: activeTab === 2,
                  },
                ]}
                onClick={num => setActiveTab(num)}
              />
            </div>
          </div>
          <div className={classes.tabs_content_wrapper}>
            <div style={activeTab === 1 ? { display: 'block' } : { display: 'none' }}>
              <div className={classes.image_container}>
                <div className={classes.image_wrapper}>
                  <img src={tabOneData.image ? tabOneData.image : imagePlaceholder} alt="Company img" />
                </div>
                <div className={classes.upl_img_wrapper}>
                  <div className={classes.icon}></div>
                  <label htmlFor="change_user_logo" className={classes.text}>
                    {t.profile.change_image}
                  </label>
                  <input
                    type="file"
                    name="user_logo"
                    id="change_user_logo"
                    onChange={handleImageChange}
                    accept="image/png, image/jpeg"
                    style={{ display: 'none' }}
                  />
                </div>
              </div>
              <form className={classes.tab_one} id="tab_one_form">
                <input type="hidden" value="hidden_input" />
                <div className={classes.col_one}>
                  <div className={classes.input_wrapper}>
                    <label>{t.profile.operator_name_label}</label>
                    <input type="text" defaultValue={tabOneData.naziv} disabled />
                    <div className={classes.error}></div>
                  </div>
                  <div className={classes.input_wrapper}>
                    <label>{t.profile.single_tax_label}</label>
                    <input type="text" defaultValue={tabOneData.edinstven_danocen_broj} disabled />
                    <div className={classes.error}></div>
                  </div>
                  <div className={classes.input_wrapper}>
                    <label>{t.profile.economic_operator_number_label}</label>
                    <input type="text" defaultValue={tabOneData.maticen_broj_ekonomski_operator} disabled />
                    <div className={classes.error}></div>
                  </div>
                  <div
                    className={`${classes.input_wrapper} ${tabOneErrors.address.length > 0 ? classes.has_error : ''}`}
                  >
                    <label>
                      {t.profile.address_label}
                      <span>*</span>
                    </label>
                    <input
                      type="text"
                      name="address"
                      value={tabOneData.address}
                      onChange={e => onInputChange('tab_one', e)}
                      required
                    />
                    <div className={classes.error}>{tabOneErrors.address}</div>
                  </div>
                  <div className={classes.inputs_container}>
                    <div
                      className={`${classes.input_wrapper} ${tabOneErrors.city.length > 0 ? classes.has_error : ''}`}
                    >
                      <label>
                        {t.profile.city_town_label}
                        <span>*</span>
                      </label>
                      <input
                        type="text"
                        name="city"
                        value={tabOneData.city}
                        onChange={e => onInputChange('tab_one', e)}
                        required
                      />
                      <div className={classes.error}>{tabOneErrors.city}</div>
                    </div>
                    <div
                      className={`${classes.input_wrapper} ${
                        tabOneErrors.municipality.length > 0 ? classes.has_error : ''
                      }`}
                    >
                      <label>
                        {t.profile.municipality_label}
                        <span>*</span>
                      </label>
                      <input
                        type="text"
                        name="municipality"
                        value={tabOneData.municipality}
                        onChange={e => onInputChange('tab_one', e)}
                        required
                      />
                      <div className={classes.error}>{tabOneErrors.municipality}</div>
                    </div>
                  </div>
                </div>
                <div className={classes.col_two}>
                  <div
                    className={`${classes.input_wrapper} ${
                      tabOneErrors.phone_number.length > 0 ? classes.has_error : ''
                    }`}
                  >
                    <label>
                      {t.profile.phone}
                      <span>*</span>
                    </label>
                    <PhoneInput
                      name="phone_number"
                      required
                      value={tabOneData.phone_number}
                      onChange={value =>
                        onInputChange('tab_one', {
                          target: {
                            value,
                            name: 'phone_number',
                            required: true,
                          },
                        })
                      }
                    />
                    <div className={classes.error}>{tabOneErrors.phone_number}</div>
                  </div>
                  <div className={`${classes.input_wrapper} ${tabOneErrors.fax.length > 0 ? classes.has_error : ''}`}>
                    <label>
                      {t.profile.fax_number_label}
                      <span>*</span>
                    </label>
                    <PhoneInput
                      name="fax"
                      required
                      value={tabOneData.fax}
                      onChange={value =>
                        onInputChange('tab_one', {
                          target: { value, name: 'fax', required: true },
                        })
                      }
                    />
                    <div className={classes.error}>{tabOneErrors.fax}</div>
                  </div>
                  <div
                    className={`${classes.input_wrapper} ${
                      tabOneErrors.company_email.length > 0 ? classes.has_error : ''
                    }`}
                  >
                    <label>
                      {t.profile.email}
                      <span>*</span>
                    </label>
                    <input
                      type="email"
                      autoComplete="new-email"
                      name="email"
                      value={tabOneData.company_email}
                      onChange={e => onInputChange('tab_one', e)}
                      required
                    />
                    <div className={classes.error}>{tabOneErrors.company_email}</div>
                  </div>
                  <div
                    className={`${classes.input_wrapper} ${tabOneErrors.website.length > 0 ? classes.has_error : ''}`}
                  >
                    <label>
                      {t.profile.website_label}
                      <span>*</span>
                    </label>
                    <input
                      type="text"
                      name="website"
                      value={tabOneData.website}
                      onChange={e => onInputChange('tab_one', e)}
                      required
                    />
                    <div className={classes.error}>{tabOneErrors.website}</div>
                  </div>
                  <div
                    className={`${classes.input_wrapper} ${
                      tabOneErrors.postal_code.length > 0 ? classes.has_error : ''
                    }`}
                  >
                    <label>
                      {t.profile.postal_number}
                      <span>*</span>
                    </label>
                    <input
                      type="number"
                      name="postal_number"
                      value={tabOneData.postal_code}
                      onChange={e => onInputChange('tab_one', e)}
                      required
                    />
                    <div className={classes.error}>{tabOneErrors.postal_code}</div>
                  </div>
                </div>
              </form>
              <div className={classes.company_info_wrapper}>
                <label>{t.profile.company_short_info}</label>
                <textarea
                  name="company_info"
                  rows={5}
                  value={tabOneData.company_info}
                  onChange={e => onInputChange('tab_one', e)}
                ></textarea>
              </div>
              <div className={classes.current_state_wrapper}>
                <label>{t.profile.current_state_label}</label>
                <div
                  className={`${classes.file_wrapper} ${
                    !tabOneData?.current_state?.name && !tabOneData?.current_state?.fileDocument ? classes.no_file : ''
                  }`}
                >
                  {tabOneData?.current_state?.name && tabOneData?.current_state?.fileDocument ? (
                    <>
                      <div className={classes.file_image}>
                        {tabOneData?.current_state?.type === 'pdf' ? (
                          <img src={FileIcon} alt="File icon" />
                        ) : (
                          <FontAwesomeIcon
                            icon={FILE_ICON_BY_FILE_TYPE[tabOneData?.current_state?.type]}
                            size={'3x'}
                            color={'#cfdae6ab'}
                          />
                        )}
                      </div>
                      <div className={classes.file_text}>
                        <div className={classes.file_info}>
                          <div
                            className={classes.name}
                            onClick={() =>
                              downloadDocument(tabOneData?.current_state?.fileDocument, tabOneData?.current_state?.name)
                            }
                            title={tabOneData?.current_state?.name}
                          >
                            {tabOneData?.current_state?.name}
                          </div>
                          <div className={classes.size}>
                            {tabOneData?.current_state?.size} {t.profile.of} 3 MB
                          </div>
                        </div>
                        <div className={classes.actions}>
                          <label htmlFor="attach_file" className={classes.attach}></label>
                          <input
                            type="file"
                            name="current_state"
                            id="attach_file"
                            style={{ display: 'none' }}
                            accept={REACT_APP_FILES_TYPE}
                            onChange={e => onDokumentChange(e)}
                          />
                          <div className={classes.remove} onClick={onDocumentRemove}></div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <label htmlFor="attach_file" className={classes.file_text}>
                      <div className={classes.file_info}>
                        <div className={classes.name}>{t.profile.upload_file}</div>
                      </div>
                      <div className={classes.actions}>
                        <div className={classes.attach}></div>
                        <input
                          type="file"
                          name="current_state"
                          id="attach_file"
                          style={{ display: 'none' }}
                          accept={REACT_APP_FILES_TYPE}
                          onChange={e => onDokumentChange(e)}
                        />
                      </div>
                    </label>
                  )}
                </div>
              </div>
              <div className={classes.more_ads_info_wrapper}>
                <label>{t.profile.info_about_ads}</label>
                <div className={classes.checkboxes_wrapper}>
                  {categories.map((checkbox, index) => {
                    return (
                      <label htmlFor={`checkbox_${checkbox.id}`} key={checkbox.id} className={classes.checkbox_wrapper}>
                        <input
                          type="checkbox"
                          name={`checkbox_${checkbox.name}`}
                          id={`checkbox_${checkbox.id}`}
                          value={checkbox.name}
                          checked={tabOneData?.ads_info?.includes(checkbox.id)}
                          onChange={e =>
                            onCheckboxChange({
                              target: {
                                cat_id: checkbox.id,
                                checked: e.target.checked,
                              },
                            })
                          }
                          disabled={true}
                        />
                        <span className={classes.checkmark}></span>
                        <div className={classes.text}>{checkbox.name}</div>
                      </label>
                    );
                  })}
                </div>
              </div>
            </div>
            <div style={activeTab === 2 ? { display: 'block' } : { display: 'none' }}>
              <form id="tab_two_form" className={classes.tab_two}>
                <input type="hidden" value="hidden_input" />
                <div className={classes.col_one}>
                  <div
                    className={`${classes.input_wrapper} ${
                      tabTwoErrors.first_name.length > 0 ? classes.has_error : ''
                    }`}
                  >
                    <label>
                      {t.profile.name}
                      <span>*</span>
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={tabTwoData.first_name}
                      onChange={e => onInputChange('tab_two', e)}
                      required
                    />
                    <div className={classes.error}>{tabTwoErrors.first_name}</div>
                  </div>
                  <div
                    className={`${classes.input_wrapper} ${tabTwoErrors.last_name.length > 0 ? classes.has_error : ''}`}
                  >
                    <label>
                      {t.profile.surname}
                      <span>*</span>
                    </label>
                    <input
                      type="text"
                      name="surname"
                      value={tabTwoData.last_name}
                      onChange={e => onInputChange('tab_two', e)}
                      required
                    />
                    <div className={classes.error}>{tabTwoErrors.last_name}</div>
                  </div>
                  <div
                    className={`${classes.input_wrapper} ${
                      tabTwoErrors.mobile_number.length > 0 ? classes.has_error : ''
                    }`}
                  >
                    <label>
                      {t.profile.mobile_number}
                      <span>*</span>
                    </label>
                    <PhoneInput
                      name="mobile_number"
                      required
                      value={tabTwoData.mobile_number}
                      onChange={value =>
                        onInputChange('tab_two', {
                          target: {
                            value,
                            name: 'mobile_number',
                            required: true,
                          },
                        })
                      }
                    />
                    <div className={classes.error}>{tabTwoErrors.mobile_number}</div>
                  </div>
                </div>
                <div className={classes.col_two}>
                  <div className={`${classes.input_wrapper} ${tabTwoErrors.email.length > 0 ? classes.has_error : ''}`}>
                    <label>{t.profile.email}</label>
                    <input
                      type="email"
                      autoComplete="new-email"
                      name="email"
                      value={tabTwoData.email}
                      onChange={e => onInputChange('tab_two', e)}
                      disabled
                    />
                    <div className={classes.error}>{tabTwoErrors.email}</div>
                  </div>
                  <div
                    className={`${classes.input_wrapper} ${tabTwoErrors.password.length > 0 ? classes.has_error : ''}`}
                  >
                    <label>
                      {t.profile.password}
                      <span>*</span>
                    </label>
                    <input
                      type="password"
                      autoComplete="new-password"
                      name="password"
                      value={tabTwoData.password}
                      onChange={e => onInputChange('tab_two', e)}
                      required
                    />
                    <div className={classes.error}>{tabTwoErrors.password}</div>
                  </div>
                  <div
                    className={`${classes.input_wrapper} ${
                      tabTwoErrors.user_phone_number.length > 0 ? classes.has_error : ''
                    }`}
                  >
                    <label>
                      {t.profile.phone}
                      <span>*</span>
                    </label>
                    <PhoneInput
                      name="user_phone_number"
                      required
                      value={tabTwoData.user_phone_number}
                      onChange={value =>
                        onInputChange('tab_two', {
                          target: {
                            value,
                            name: 'user_phone_number',
                            required: true,
                          },
                        })
                      }
                    />
                    <div className={classes.error}>{tabTwoErrors.user_phone_number}</div>
                  </div>
                  <div
                    className={`${classes.input_wrapper} ${
                      tabTwoErrors.postal_number.length > 0 ? classes.has_error : ''
                    }`}
                  >
                    <label>
                      {t.profile.postal_number}
                      <span>*</span>
                    </label>
                    <input
                      type="number"
                      name="postal_number"
                      value={tabTwoData.postal_number}
                      onChange={e => onInputChange('tab_two', e)}
                      required
                    />
                    <div className={classes.error}>{tabTwoErrors.postal_number}</div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className={classes.footer_wrapper}>
            <div className={classes.info_wrapper}>
              <div className={classes.title}>{t.profile.info_title}</div>
              <Switch
                label={t.profile.info_label}
                name="send_notifications"
                onChange={value => setSendNotifications(value)}
                switchPosition="start"
                type="only_switch_clicable"
              />
            </div>
            <div className={classes.buttons_wrapper}>
              <button className={classes.cancel} onClick={() => navigate(-1)}>
                {t.global.cancel}
              </button>
              <button className={classes.save} onClick={saveData}>
                {t.global.save}
              </button>
            </div>
          </div> */}
        </div>
      </Page>
      <GlobalLoader show={showLoader} />
    </React.Fragment>
  );
};

export default ProfilePage;
