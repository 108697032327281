import React from 'react';
import Button from '../../../Button/Button';
import classes from './Actions.module.scss';
import * as CSS from 'csstype';
import { ICalendarActions } from './model/action.model';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';

const Actions = (props: ICalendarActions) => {
  const translations = useSelector(
    (state: RootState) => state.translations.translations[state.translations.appLanguage]['components']
  );

  let styles: CSS.Properties = {
    fontSize: '14px',
  };

  return (
    <React.Fragment>
      <div className={classes.Container}>
        <Button
          label={translations['Откажи']}
          class="dark-gray"
          borderless
          fullWidth
          style={styles}
          onClick={props.onCancel}
        />
        <Button label={translations['Потврди датум']} borderless fullWidth style={styles} onClick={props.onConfirm} />
      </div>
    </React.Fragment>
  );
};

export default Actions;
