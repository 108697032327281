import { useSelector } from 'react-redux';
import clsx from 'clsx';

import classes from './OfferStatusDisplay.module.scss';
import { RootState } from '../store';
import { OfferStatus } from '../types/offer';

interface Props {
  status: OfferStatus;
}

export function OfferStatusDisplay({ status }: Props) {
  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage]['offers'];
  });

  return (
    <div
      className={clsx(classes.container, {
        [classes.pending]: status === OfferStatus.Pending,
        [classes.accepted]: status === OfferStatus.Accepted,
        [classes.rejected]: status === OfferStatus.Rejected,
      })}
    >
      <span></span>
      {status === OfferStatus.Accepted ? t.accepted : status === OfferStatus.Rejected ? t.declined : t.in_progress}
    </div>
  );
}
