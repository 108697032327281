import { useEffect, useRef, useState } from 'react';

// this is meant to be used as an alternative to react-query until we upgrade to react 18
export function useFetch<T>(fn: () => Promise<T>) {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState<T>();
  const [error, setError] = useState<Error | null>(null);
  const fetcher = useRef(fn);

  useEffect(() => {
    fetcher.current = fn;
  });

  useEffect(() => {
    async function fetch() {
      try {
        const data = await fetcher.current();

        setData(data);
      } catch (e) {
        setError(e as Error);
      } finally {
        setLoading(false);
      }
    }

    fetch();
  }, []);

  return {
    data,
    error,
    isLoading,
    isError: !!error,
  };
}
