import React from 'react';
import Page from '../../../components/Layout/Page/Page';
import classes from './Register.module.scss';
import PageFooter from '../../../components/Layout/Page/PageFooter/PageFooter';
import { StepForms } from './Step1/StepForms';

const Register = () => {
  return (
    <React.Fragment>
      <Page pageTitle="Регистрација на домашен економски оператор" hideSidebar>
        <div className={`${classes.RegisterContainer}`}>
          <StepForms />
        </div>
      </Page>
      <PageFooter />
    </React.Fragment>
  );
};

export default Register;
