import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';

import { RootState } from '../../../../../../../store';

import NoImageIcon from '../../../../../../../assets/icons/No-image-icon.svg';
import PlusIcon from '../../../../../../../assets/icons/Plus.svg';

import classes from './AttachImages.module.scss';

const AttachImagesModal = props => {
  const { REACT_APP_MAX_PRODUCT_IMAGES, REACT_APP_PRODUCT_IMAGES_TYPES }: any = process.env;

  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage];
  });

  // const [numOfImages, setNumOfImages] = useState<number>(1);
  const [selectedImages, setSelectedImages]: any = useState(
    props.images ?? [
      {
        file_url: '',
        existing: false,
        file_type: 1,
        uploaded: true,
      },
    ]
  );
  const [filledPrevious, setFilledPrevious] = useState(false);

  useEffect(() => {
    if (props?.images && props?.images?.length > 0) {
      setSelectedImages(props.images);

      if (props.images.every(image => image.file_url)) {
        setFilledPrevious(true);
      }
    }
  }, [props?.images]);

  useEffect(() => {
    if (selectedImages.every(image => image.file_url)) {
      setFilledPrevious(true);
    } else {
      setFilledPrevious(false);
    }
  }, [selectedImages]);

  const addMoreImages = () => {
    if (selectedImages.length < REACT_APP_MAX_PRODUCT_IMAGES) {
      setSelectedImages(prev => [
        ...prev,
        {
          file_url: '',
          existing: false,
          file_type: 1,
          uploaded: true,
        },
      ]);
    }

    setFilledPrevious(false);
  };

  const onImageChange = (e, index) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    e.target.value = '';

    reader.onload = e => {
      const el: any = document.getElementById(`link_${index}`);
      if (el) {
        el.value = null;
      }
      if (e.target) {
        const images = [...selectedImages];
        if (typeof images[index] !== 'undefined') {
          images[index] = {
            ...images[index],
            file_url: e.target.result,
            existing: false,
            file_type: 1,
            uploaded: true,
          };
        } else {
          images.push({
            file_url: e.target.result,
            existing: false,
            file_type: 1,
            uploaded: true,
          });
        }
        setSelectedImages(images);
      }
    };

    reader.readAsDataURL(file);
  };

  const onImageLinkChange = (e, index) => {
    if (e.target.value.length <= 1) setFilledPrevious(false);

    const newValues = [...selectedImages];
    if (e.target) {
      if (e.target.value.length > 0) {
        let checkURL = e.target.value.match(/(http(s)?:)/g);
        let file_type = 1;
        if (checkURL) {
          file_type = 2;
        }
        if (typeof newValues[index] !== 'undefined') {
          newValues[index] = {
            ...newValues[index],
            file_url: e.target.value,
            existing: false,
            file_type: 2,
            uploaded: false,
          };
        } else {
          newValues.push({
            file_url: e.target.value,
            existing: false,
            file_type: 2,
            uploaded: false,
          });
        }
      } else {
        newValues.splice(index, 1);
      }
      setSelectedImages(newValues);
    }
  };

  const onImagesAttach = () => {
    const newImages: any = [];
    selectedImages.forEach(img => {
      if (typeof img === 'object') {
        newImages.push(img);
      }
    });
    props.confirm(props.index, newImages);
    setSelectedImages([]);
  };

  const onRemoveImage = index => {
    const newFormValues = [...selectedImages];
    if (typeof newFormValues[index] !== 'undefined') {
      newFormValues[index] = '';
      const el: any = document.getElementById(`link_${index}`);
      if (el) {
        el.value = null;
      }
      setSelectedImages(newFormValues);
    }
  };

  return (
    <React.Fragment>
      <Modal show={props.show} className={classes.modal_wrapper} centered>
        <Modal.Header className={classes.custom_header}>
          <Modal.Title>{t.create_ad.attach_images_title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={classes.custom_body}>
          <div className={classes.attach_images_wrapper}>
            {selectedImages.map((n, index) => {
              const images = selectedImages[index];
              return (
                <div className={classes.attach_wrapper} key={index}>
                  <div
                    className={`${classes.image_wrapper} ${
                      typeof images === 'undefined' ? classes.no_image : images?.length === 0 ? classes.no_image : ''
                    }`}
                  >
                    <label htmlFor={`${index}_image`}>
                      <img
                        src={
                          typeof images !== 'undefined' && images?.hasOwnProperty('file_url')
                            ? !images.file_url.includes('https://') && !images.file_url.includes('data')
                              ? `${process.env.REACT_APP_STATIC_URL}/${images.file_url}`
                              : images?.file_url
                            : NoImageIcon
                        }
                        alt="img icon"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = NoImageIcon;
                        }}
                      />
                    </label>
                    {n.file_url && (
                      <div className={classes.remove_image} onClick={() => onRemoveImage(index)}>
                        <span className={classes.icon}></span>
                      </div>
                    )}
                    <input
                      type="file"
                      name={`${index}_image`}
                      style={{ display: 'none' }}
                      onChange={e => onImageChange(e, index)}
                      id={`${index}_image`}
                      accept={REACT_APP_PRODUCT_IMAGES_TYPES}
                    />
                  </div>
                  <div className={classes.link_wrapper}>
                    <div className={classes.text}>{t.create_ad.or_add_link_title}</div>
                    <div className={classes.link_input}>
                      <input
                        type="text"
                        id={`link_${index}`}
                        name={`link_${index}`}
                        value={typeof images !== 'undefined' && images?.uploaded === false ? images.file_url : ''}
                        onChange={e => onImageLinkChange(e, index)}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {selectedImages.length < REACT_APP_MAX_PRODUCT_IMAGES && filledPrevious && (
            <div className={classes.add_more_btn_wrapper} onClick={addMoreImages}>
              <div className={classes.icon_wrapper}>
                <img src={PlusIcon} alt="Add more" />
              </div>
              {t.create_ad.add_image}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className={classes.custom_footer}>
          <button className={classes.cancel_btn} onClick={props.toggleModal}>
            {t.global.cancel}
          </button>
          <button
            className={classes.confirm_btn}
            onClick={onImagesAttach}
            disabled={!filledPrevious}
            style={!filledPrevious ? { backgroundColor: 'lightgray' } : {}}
          >
            {t.global.attach}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default AttachImagesModal;
