import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { client } from '../services/http/instance';
import { Company } from '../types/company';
import { Paginated } from '../types/common';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { showNotification } from '../notification_functions/notifications_functions';

export interface OffersQuery {
  pagination: { size: number; page: number };
  sort?: { sortBy?: string; sortOrder?: string };
  filter?: { search?: string; dateFrom?: string; dateTo?: string };
}

export interface AdditionalOffersQuery {
  pagination: { size: number; page: number };
  sort?: { sortBy?: string; sortOrder?: string };
  // filter?: { search?: string; dateFrom?: string; dateTo?: string };
}

interface Product {
  id: number;
  ad_id: number;
  title: string;
  description: string;
  quantity: number;
  channel_name: string;
  created_at: string;
  updated_at: string;
}

export enum OfferProductStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  DECLINED = 'rejected',
  AUCTION = 4,
}

export interface OfferProduct {
  id: number;
  offer_id: number;
  product_id: number;
  offer_price: number;
  created_at: string;
  updated_at: string;
  status: OfferProductStatus;
  offer: Offer;
  product: Product;
}

interface Document {
  id: number;
  offer_id: number;
  file_name: string;
  file_url: string;
  file_type: number;
  created_at: string;
  updated_at: string;
}

export interface Offer {
  id: number;
  user_id: number;
  company_id: number;
  tender_id: number;
  status: number;
  created_at: string;
  updated_at: string;
  comment: string | null;
  company: Company;
  documents: Document[];
}

export function useProductOffers(productId: string, query: OffersQuery) {
  return useQuery({
    queryKey: ['products', productId, 'offers', query],
    queryFn: () =>
      client
        .get<Paginated<OfferProduct>>(`/products/${productId}/offers`, {
          params: { ...query.pagination, ...query.sort, ...query.filter },
        })
        .then(({ data }) => data),
    keepPreviousData: true,
  });
}

export function useCompanyOffers(companyId: string, productId: number, query: AdditionalOffersQuery) {
  return useQuery({
    queryKey: ['companies', companyId, 'offers', { ...query, productId }],
    queryFn: () =>
      client
        .get<Paginated<OfferProduct>>('/offers', {
          params: { ...query.pagination, ...query.sort, productId, companyId },
        })
        .then(({ data }) => data),
    keepPreviousData: true,
  });
}

export function useUpdateStatus() {
  const t = useSelector((state: RootState) => state.translations.translations[state.translations.appLanguage]);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, status }: { id: string; status: OfferProductStatus }) =>
      client.put(`/offers/${id}/status`, { status }),
    onSuccess: (_data, { status }) => {
      const message =
        status === OfferProductStatus.DECLINED
          ? t.my_adds_offers.offer_declined
          : status === OfferProductStatus.ACCEPTED
          ? t.my_adds_offers.offer_accepted
          : '';

      showNotification(message, 'success');

      // there might be better way to invalidate this?
      queryClient.invalidateQueries({
        predicate: query => query.queryKey[0] === 'ads' && query.queryKey[2] === 'offers',
        type: 'active',
      });
    },
    onError: (e: any) => {
      if (e.response.data.message && e.response.data.message.includes('still active')) {
        showNotification(t.my_adds_offers.still_active, 'danger');
        return;
      }
      showNotification(t.my_adds_offers.offer_error_message, 'danger');
    },
  });
}

export function useUpdateAuctionOfferStatus() {
  const t = useSelector((state: RootState) => state.translations.translations[state.translations.appLanguage]);

  return useMutation({
    mutationFn: ({ id, status }: { id: string; status: OfferProductStatus }) =>
      client.put(`/auction-offers/${id}/status`, { status }),
    onSuccess: (_data, { status }) => {
      const message =
        status === OfferProductStatus.DECLINED
          ? t.my_adds_offers.offer_declined
          : status === OfferProductStatus.ACCEPTED
          ? t.my_adds_offers.offer_accepted
          : '';

      showNotification(message, 'success');
    },
    onError: () => {
      showNotification(t.my_adds_offers.offer_error_message, 'danger');
    },
  });
}
