import { useQuery } from '@tanstack/react-query';

import { client } from '../services/http/instance';
import { Company } from '../types/company';

interface Industry {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface Ad {
  id: number;
  company_id: number;
  user_id: number;
  contact_name: string;
  contact_phone: string;
  contact_email: string;
  aukcija: boolean;
  kriterium: boolean;
  datum_isporaka: string;
  datum_oddrzuvanje_aukcija: string | null;
  vreme_oddrzuvanje_aukcija_od: string | null;
  vreme_oddrzuvanje_aukcija_do: string | null;
  datum_za_dostavuvanje: string;
  dostavuvanje_ponudi_vreme: string;
  datum_za_prasanja: string;
  vreme_za_prasanja: string | null;
  datum_za_odgovori: string | null;
  vreme_za_odgovori: string | null;
  anonymous: boolean;
  created_at: string;
  updated_at: string;
  title: string;
  industry_id: number;
  industry: Industry;
  company: Company;
  delivery_due_days: number;
}

export function useAd(id: string) {
  return useQuery({
    queryKey: ['ads', id],
    queryFn: () => client.get<Ad>(`/ads/${id}`).then(({ data }) => data),
  });
}
