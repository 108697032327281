import { AxiosRequestConfig } from 'axios';
import axiosInstance from './instance';
import { LocalStorageEnum } from '../../enums/local-storage-keys';

/**
 * @deprecated
 */
const httpPost = <T, P>(url: string, data: P, contentType = 'application/json') => {
  let token = localStorage.getItem(LocalStorageEnum.UserToken);
  const requestConfig: AxiosRequestConfig = {
    headers: {
      'Content-Type': contentType,
      Authorization: `Bearer ${token}`,
    },
  };

  return new Promise<T>((resolve, reject) => {
    axiosInstance
      .post<T>(url, data, requestConfig)
      .then(response => {
        const responseValue = response.data;
        return resolve(responseValue as T);
      })
      .catch(error => {
        return reject(error?.response?.data);
      });
  });
};

export default httpPost;
