import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import dayjs from 'dayjs';

import Page from '../../components/Layout/Page/Page';
import OfferHeader from './Components/Header/OfferHeader';
import { RootState } from '../../store';
import { hideNotifications } from '../../notification_functions/notifications_functions';
import NoImageIcon from '../../assets/icons/No-image-icon.svg';
import classes from './OffersPreview.module.scss';
import { formatDate } from '../../utils/formatDate';
import { getLogoUrl } from '../../utils/company';
import FiltersNew from './Components/Filters/FiltersNew';
import { useAd } from '../../queries/ad';
import { OfferProduct, OfferProductStatus, OffersQuery, useUpdateStatus } from '../../queries/offer';
import { OfferCompanyModal } from './Components/Modals/OfferCompanyModal';
import { ResolveOfferModal } from './Components/Modals/ResolveOfferModal';
import { httpGet } from '../../services/http';
import { Tab, Tabs } from 'react-bootstrap';
import { OfferStatusDisplay } from '../../components/OfferStatusDisplay';
import ProductOffersTab from './ProductOffersTab';
import GlobalLoader from '../../components/UI/GlobalLoader/GlobalLoader';

const Back = () => {
  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage];
  });

  const navigate = useNavigate();

  return (
    <div className={classes.header_content_container}>
      <button type="button" className={classes['back_btn']} onClick={() => navigate(-1)}>
        &#60; {t.global['back']}
      </button>
    </div>
  );
};

export function OffersPreviewNew({ own }: { own?: boolean }) {
  const { id } = useParams();

  const [products, setProducts] = useState<any>(null);
  const [currentTab, setCurrentTab] = useState<number>();

  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage];
  });

  useEffect(() => {
    fetchProducts();
    return () => {
      hideNotifications();
    };
  }, []);

  const ad = useAd(id!);
  const fetchProducts = async () => {
    const response = await httpGet<any>(`ads/${id}/products`);
    setProducts(response.data);
  };
  const update = useUpdateStatus();

  const t_my_adds_offers = t.my_adds_offers;

  const [showConfirmModal, setShowConfirmModal] = useState<
    { show: false } | { show: true; status: OfferProductStatus; id: string }
  >({ show: false });

  const action = showConfirmModal.show
    ? showConfirmModal.status === OfferProductStatus.DECLINED
      ? { button: t_my_adds_offers.reject_offer, body: t_my_adds_offers.reject_modal_body }
      : showConfirmModal.status === OfferProductStatus.AUCTION
      ? { button: t_my_adds_offers.send_to_auction, body: t_my_adds_offers.offer_send_to_auction }
      : { button: t_my_adds_offers.accept_offer, body: t_my_adds_offers.accept_modal_body }
    : { button: '', body: '' };
  if (update.isLoading) return <GlobalLoader show />;

  return (
    <React.Fragment>
      <Page showBackBbtn={Back}>
        <OfferHeader
          Info={{
            date: ad?.data?.created_at!,
            number: ad?.data?.id!,
          }}
        />
        <div className={classes.product_wrapper}>
          <div className={classes.product_info}>
            <div className={classes.title_wrapper}>
              <div className={classes.image_wrapper}>
                <img
                  src={ad.data?.company?.logo ? getLogoUrl(ad.data.company) : ''}
                  alt="Company Logo"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = NoImageIcon;
                  }}
                />
              </div>
              <div className={classes.info_wrapper}>
                <div className={classes.title}>{ad.data?.title}</div>
                <div className={classes.category}>{ad.data?.industry?.name}</div>
              </div>
            </div>
          </div>
        </div>
        <Tabs onSelect={key => setCurrentTab(Number(key))} activeKey={currentTab} unmountOnExit transition={false}>
          {products &&
            ad.data &&
            products?.map((product, index) => (
              <Tab title={`${t.product.product} ${index + 1}`} eventKey={product.id} key={product.id}>
                <ProductOffersTab
                  ad={ad}
                  id={Number(product.id)}
                  own={!!own}
                  showConfirmModal={showConfirmModal}
                  setShowConfirmModal={setShowConfirmModal}
                  update={update}
                />
              </Tab>
            ))}
        </Tabs>
      </Page>
    </React.Fragment>
  );
}
