import React, { useEffect, useMemo, useState } from 'react';
import { OfferProduct, OffersQuery, useProductOffers } from '../../queries/offer';
import { OfferStatusDisplay } from '../../components/OfferStatusDisplay';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import classes from './OffersPreview.module.scss';
import dayjs from 'dayjs';
import { OfferProductStatus } from '../../queries/offer';
import { PrintOffersModal } from '../../components/Printing/MyAdvertisementsPDF/PrintOffersModal';
import FiltersNew from './Components/Filters/FiltersNew';
import { OfferCompanyModal } from './Components/Modals/OfferCompanyModal';
import { UseQueryResult } from '@tanstack/react-query';
import { Ad } from '../../queries/ad';
import GlobalLoader from '../../components/UI/GlobalLoader/GlobalLoader';
import Button from '../../components/UI/Button/Button';
import { Badge } from 'react-bootstrap';

type Props = {
  id: number;
  ad: UseQueryResult<Ad, unknown>;
  own: boolean;
  showConfirmModal:
    | {
        show: false;
      }
    | {
        show: true;
        status: OfferProductStatus;
        id: string;
      };
  setShowConfirmModal: React.Dispatch<
    React.SetStateAction<
      | {
          show: false;
        }
      | {
          show: true;
          status: OfferProductStatus;
          id: string;
        }
    >
  >;
  update: any;
};

const CustomActionCol = props => {
  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage];
  });

  const t_advertisements = t.advertisements;
  const pendingOffers = props?.row?.pending_offer_count || 0;

  return (
    <>
      <Button
        onClick={props.onClick}
        style={{
          background: '#0044bb',
          borderRadius: '6px',
          height: 'auto',
          padding: '10px 8px',
          fontStyle: 'normal',
          fontWeight: '500',
          fontSize: '12px',
          color: '#fbfbfb',
          textDecoration: 'none',
          marginRight: '27px',
        }}
        label={
          <div className={classes.separator}>
            <span>{t_advertisements.see_offers}</span>
            <Badge bg="primary">{pendingOffers}</Badge>
          </div>
        }
      ></Button>
    </>
  );
};

const ProductOffersTab = ({ id, ad, own, showConfirmModal, setShowConfirmModal, update }: Props) => {
  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage];
  });
  const t_global = t.global;

  const [query, setQuery] = useState<OffersQuery>({
    pagination: { page: 1, size: 10 },
  });
  const [print, setPrint] = useState(false);
  const [showConfirmModalCompany, setShowConfirmModalCompany] = useState<
    { show: false } | { show: true; data: OfferProduct }
  >({ show: false });

  let products = useProductOffers(id.toString(), query);

  const total = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {`${t_global['table_total_text']} ${from} ${t_global['table_total_text_1']} ${to} ${t_global['table_total_text_2']} ${size} ${t_global['table_total_text_3']}`}
    </span>
  );

  const onTableChange = (_type, { page, sizePerPage, sortField, sortOrder }) => {
    setQuery(prev => ({ ...prev, pagination: { page, size: sizePerPage }, sort: { sortBy: sortField, sortOrder } }));
  };

  const columns = useMemo(
    () => [
      {
        dataField: 'product',
        text: t.product.product,
        formatter: () => ad.data?.title,
        sort: true,
      },
      {
        dataField: 'company.naziv',
        text: t.offers.company,
        sort: true,
      },
      // {
      //   dataField: 'pending_offer_count',
      //   text: t.offers.submitted_offers,
      //   sort: true,
      // },
      {
        dataField: 'updated_at',
        text: t.offers.date,
        formatter: (cell, row) => {
          return (
            <div>
              {dayjs(cell).format('DD.MM.YYYY')} <br /> {dayjs(cell).format('HH:mm')}
            </div>
          );
        },
        sort: true,
      },
      {
        dataField: 'status',
        text: t.offers.status,
        formatter: (cell, row) => <OfferStatusDisplay status={cell} />,
        sort: true,
      },
      {
        dataField: 'action',
        formatter: (cell, row) => (
          <CustomActionCol
            row={row}
            cell={cell}
            onClick={() => {
              setShowConfirmModalCompany({ show: true, data: row });
            }}
          />
        ),
      },
      // {
      //   dataField: 'product.quantity',
      //   text: t.auction_bidding.quantity,
      //   sort: true,
      // },

      // {
      //   dataField: 'updated_at',
      //   text: t.offers.date,
      //   sort: true,
      //   formatter: value => formatDate(value),
      // },
      // {
      //   dataField: 'actions',
      //   text: '',
      //   hidden: ad && !dayjs().isAfter(dayjs(ad?.data?.auction_end_date)),
      //   formatter: (cell, row) =>
      //     !own ? <CustomActionCol row={row} setShowConfirmModal={setShowConfirmModal} /> : <></>,
      // },
    ],
    [t, id]
  );

  const paginationOptions = {
    custom: true,
    totalSize: products.data?.total ?? 0,
    page: query.pagination.page,
    sizePerPage: query.pagination.size,
    paginationSize: 4,
    pageStartIndex: 1,
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    hideSizePerPage: false,
    hidePageListOnlyOnePage: false,
    firstPageText: '<<',
    prePageText: '<',
    nextPageText: '>',
    lastPageText: '>>',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: total,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
    ],
  };

  function caret(order, column) {
    if (!order) {
      return (
        <span style={{ display: 'inline-block', position: 'relative', top: '-5px' }}>
          <span className={classes.sort_wrapper}>
            <span className={`${classes.caret} ${classes.caret_up}`}></span>
            <span className={classes.caret}></span>
          </span>
        </span>
      );
    } else if (order === 'asc') {
      return (
        <span style={{ display: 'inline-block', position: 'relative', top: '-2px' }}>
          <span className={classes.sort_wrapper}>
            <span className={`${classes.caret} ${classes.caret_up}`}></span>
          </span>
        </span>
      );
    } else if (order === 'desc') {
      return (
        <span style={{ display: 'inline-block', position: 'relative', top: '-2px' }}>
          <span className={classes.sort_wrapper}>
            <span className={classes.caret}></span>
          </span>
        </span>
      );
    } else {
      return null;
    }
  }

  const action = showConfirmModal.show
    ? showConfirmModal.status === OfferProductStatus.DECLINED
      ? { button: t.reject_offer, body: t.reject_modal_body }
      : showConfirmModal.status === OfferProductStatus.AUCTION
      ? { button: t.send_to_auction, body: t.offer_send_to_auction }
      : { button: t.accept_offer, body: t.accept_modal_body }
    : { button: '', body: '' };

  return (
    <div className={classes.table_wrapper}>
      <div className={classes.table_container}>
        <FiltersNew onPrint={() => setPrint(true)} onChange={filter => setQuery(prev => ({ ...prev, filter }))} />

        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <div>
              <BootstrapTable
                keyField="id"
                remote={{ pagination: true }}
                onTableChange={onTableChange}
                data={products.data?.data ?? []}
                columns={columns}
                classes={classes.offers_table}
                noDataIndication={products.isLoading ? t_global.loading : t_global.no_data}
                sort={{ sortCaret: caret }}
                {...paginationTableProps}
              />
              <div className={classes.pagination_wrapper}>
                <PaginationTotalStandalone {...paginationProps} />
                <div>
                  <PaginationListStandalone {...paginationProps} />
                  <SizePerPageDropdownStandalone {...paginationProps} />
                </div>
              </div>
            </div>
          )}
        </PaginationProvider>
        {print && <PrintOffersModal data={products.data?.data ?? []} onCancel={() => setPrint(false)} />}
        {showConfirmModalCompany.show && (
          <OfferCompanyModal
            show
            data={showConfirmModalCompany.data}
            ad={ad.data!}
            productId={id}
            // product={offers.data?.data[0]}
            onHandleClose={() => setShowConfirmModalCompany({ show: false })}
            onHandleConfirm={(status, id) => {
              setShowConfirmModalCompany({ show: false });
              setShowConfirmModal({ show: true, status, id: id?.toString() });
              update.mutate({ status, id });
            }}
            own={own}
          />
        )}
      </div>
    </div>
  );
};

export default ProductOffersTab;
