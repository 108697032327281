import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';

import { IRangeDate } from './model';
import { RootState } from '../../../../store';

import Search from './../../../../assets/icons/Search.svg';
import Download from './../../../../assets/icons/Download.svg';
import Calendar from './../../../../assets/icons/Calendar.svg';
import CaretDownGray from './../../../../assets/icons/CaretDownGray.svg';

import 'react-datepicker/dist/react-datepicker.css';
import './datePickerCustomStyle.scss';
import classes from './Filters.module.scss';
import { OffersQuery } from '../../../../queries/offer';
import dayjs from 'dayjs';

type Filter = NonNullable<OffersQuery['filter']>;

interface Props {
  onChange(value: Filter): void;
  onPrint(): void;
}

const CustomDateInput = React.forwardRef(({ value, onClick }: any, ref: any) => {
  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage];
  });

  return (
    <button className={classes.date_wrapper} onClick={onClick} ref={ref}>
      <img src={Calendar} alt="Calendar" />
      <span>{t.my_adds_offers.date}</span>
      <img src={CaretDownGray} alt="Caret down" />
    </button>
  );
});

const FiltersNew = ({ onChange, onPrint }: Props) => {
  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage];
  });

  const t_my_adds_offers = t.my_adds_offers;
  const t_global = t.global;

  const [filters, setFilters] = useState<Filter>({
    search: '',
    dateFrom: '',
    dateTo: '',
  });

  const isMounted = useRef(false);

  let pickerRef: any = React.createRef();

  const [selectedDateRange, setSelectedDateRange] = useState<IRangeDate>({
    start: null,
    end: null,
  });

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    const triggerTimeout = setTimeout(() => {
      onChange(Object.fromEntries(Object.entries(filters).filter(([, value]) => value)) as Filter);
    }, 500);

    return () => clearTimeout(triggerTimeout);
  }, [filters]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters(state => {
      return {
        ...state,
        search: event.target.value,
      };
    });
  };

  return (
    <React.Fragment>
      <div className={`${classes.header_container}`}>
        <div className={`${classes.title} text-center`}>{t_my_adds_offers.offers}</div>

        <div className={`${classes.search_bar}`}>
          <img src={Search} alt="search" className={classes.icon} />
          <input type="text" placeholder={t_my_adds_offers.search} onChange={onChangeHandler} />
        </div>

        <div className={classes.actions}>
          <button className={classes.download_list} onClick={onPrint}>
            <img src={Download} alt="download" />
            <span className="">{t_my_adds_offers.download_list}</span>
          </button>
          <div className={classes.date_container}>
            <DatePicker
              ref={pickerRef}
              selected={selectedDateRange.start}
              startDate={selectedDateRange.start}
              endDate={selectedDateRange.end}
              onChange={dates => {
                const [start, end] = dates;
                setSelectedDateRange({ start, end });
              }}
              selectsRange
              shouldCloseOnSelect={false}
              showMonthDropdown
              showYearDropdown
              scrollableYearDropdown
              dropdownMode="select"
              popperClassName="date_picker_custom_style"
              customInput={<CustomDateInput />}
            >
              <div>
                <button
                  onClick={() => {
                    setSelectedDateRange({ start: null, end: null });
                    setFilters(state => {
                      return {
                        ...state,
                        dateFrom: '',
                        dateTo: '',
                      };
                    });
                    pickerRef.current.setOpen();
                  }}
                >
                  {t_global.cancel}
                </button>
                <button
                  onClick={() => {
                    const { start, end } = selectedDateRange;

                    if (start && end) {
                      const pattern = 'YYYY-MM-DD';

                      setFilters(state => ({
                        ...state,
                        dateFrom: dayjs(start).format(pattern),
                        dateTo: dayjs(end).format(pattern),
                      }));
                    }
                    pickerRef.current.setOpen();
                  }}
                >
                  {t_global.confirm_date}
                </button>
              </div>
            </DatePicker>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FiltersNew;
