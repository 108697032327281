import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';

import { RootState } from '../../../../../../../store';

import classes from './ConfirmModal.module.scss';

const ConfirmModal = props => {
  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage];
  });

  return (
    <React.Fragment>
      <Modal show={props.show} className={classes.modal_wrapper} centered>
        <Modal.Header className={classes.custom_header}>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={classes.custom_body}>{props.confirmation}</Modal.Body>
        <Modal.Footer className={classes.custom_footer}>
          <button className={classes.cancel_btn} onClick={props.toggleModal}>
            {t.global.cancel}
          </button>
          <button className={classes.confirm_btn} onClick={props.confirm}>
            {t.global.delete}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default ConfirmModal;
