import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { IChatListItem } from './model.ts/chat-list-item.model';
import Avatar from '../../components/Avatar/Avatar';
import { RoutesEnum } from '../../../../enums/routes';
import { RootState } from '../../../../store';
import { daysHash } from '../../../../components/UI/Datepicker/constants/constants';

import classes from './ChatListItem.module.scss';

const ChatListItem = (props: IChatListItem) => {
  const translations = useSelector(
    (state: RootState) => state.translations.translations[state.translations.appLanguage]['components']
  );

  const navigate = useNavigate();
  const loggedUser = useSelector((state: RootState) => state.auth.user.user);
  const userId = loggedUser.id;

  const openChat = () => {
    let receiverId: string = '';
    if (userId === props.chat.senderId) {
      receiverId = props.chat.receiverId;
    } else {
      receiverId = props.chat.senderId;
    }

    navigate(`${RoutesEnum.Messages}/${props.chat.chatId}?receiverId=${receiverId}`, {
      state: {
        company: props?.chat?.receiver?.naziv,
      },
    });
  };

  let lastMessage = props.chat.messages[props.chat.messages.length - 1];
  const now = new Date();

  const messageDateWeekDay = now.getDay();

  const naziv: string = props.chat.receiver.naziv! || '';

  return (
    <React.Fragment>
      <div
        className={`${classes.Container} ${!props.seen ? classes.NotRead : ''} ${
          props?.isSelected ? classes.selected_chat : ''
        }`}
        onClick={openChat}
      >
        <div className={classes.Avatar}>
          <Avatar initials={naziv?.substring(0, 2)} />
        </div>
        <div className={classes.ChatSummary}>
          <div className={classes.Sender}>{naziv}</div>
          <div
            className={classes.LastMessage}
            dangerouslySetInnerHTML={{
              __html: lastMessage.text.length > 25 ? lastMessage.text.substring(0, 25) + '...' : lastMessage.text,
            }}
          ></div>
        </div>
        <div className={classes.ChatTime}>{translations[daysHash[messageDateWeekDay]]}</div>
      </div>
    </React.Fragment>
  );
};

export default ChatListItem;
