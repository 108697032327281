import { forwardRef, InputHTMLAttributes } from 'react';
import classes from './Input.module.scss';

interface BaseInputProps {
  label: string;
  error?: string;
  customInputWidth?: string;
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement>, BaseInputProps {}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ label, required, error, customInputWidth, ...props }, ref) => {
    return (
      <div
        className={[classes.input_wrapper, error && classes.has_error].filter(Boolean).join(' ')}
        style={customInputWidth ? { width: customInputWidth } : {}}
      >
        <label>
          {label}
          {required && <span>*</span>}
        </label>
        <input ref={ref} {...props} />
        <div className={classes.error}>{error}</div>
      </div>
    );
  }
);
