import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import Avatar from '../../components/Avatar/Avatar';
import classes from './ChatHeader.module.scss';
import { IChatHeader } from './model';

dayjs.extend(relativeTime);

const ChatHeader = (props: IChatHeader) => {
  const translations = useSelector(
    (state: RootState) => state.translations.translations[state.translations.appLanguage]['chat']
  );

  if (!props.lastMessage) return null;

  return (
    <React.Fragment>
      <div className={classes.Container}>
        <div className={classes.Avatar}>
          <Avatar initials={props?.company?.substring(0, 2)} />
        </div>

        <div className={classes.ChatDetails}>
          <div className={classes.Sender}>{props?.company}</div>
          <div className={classes.LastMessage}>
            {translations['Последна порака']}: &nbsp;
            {props?.lastMessage?.created_at && dayjs(props.lastMessage.created_at).isValid()
              ? dayjs().to(dayjs(props.lastMessage.created_at))
              : props?.lastMessage?.created_at || ''}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ChatHeader;
