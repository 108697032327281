import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import ConfirmModal from '../Modals/ConfirmRemoveModal/ConfirmModal';
import classes from './AdvertisementForm.module.scss';
import AttachImagesModal from '../Modals/AttachImages/AttachImages';
import { toBase64 } from '../../../../../../utils/toBase64';
import { showNotification } from '../../../../../../notification_functions/notifications_functions';

const AdvertisementForm = props => {
  const { REACT_APP_FILES_TYPE }: any = process.env;
  const index = props?.formIndex;
  const values = props?.values;
  const errors = props?.errors;

  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage];
  });

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [showAttachImageModal, setShowAttachImageModal] = useState(false);

  const checkIfErrorExists = errors.adsFormValues && errors.adsFormValues.length > 0;

  const handleDeleteImage = (currentImage: any) => {
    props.update(index, {
      ...values,
      images: values.images.filter((image, id) => image.file_url !== currentImage.file_url),
    });
  };

  const addMoreAdvertisements = () => {
    props.append({
      title: '',
      industryId: '',
      quantity: 1,
      description: '',
      images: [],
      additional_documents: [],
    });
  };

  const deleteForm = (index: number) => {
    props.remove(index);
  };

  const onDocumentChange = e => {
    const file = e.target.files[0];

    let filename = file.name;

    toBase64(file).then(result => {
      if (result) {
        props.update(index, {
          ...values,
          additional_documents: [
            ...values.additional_documents,
            {
              file_name: filename,
              file_url: result,
              existing: false,
            },
          ],
        });
      } else {
        showNotification(t.product.attach_problem, 'danger');
      }
    });

    e.target.value = '';
  };

  const onDocumentRemove = docIndex => {
    const findSpecificDocument = values.additional_documents.filter((doc, id) => docIndex !== id);

    props.update(index, {
      ...values,
      additional_documents: findSpecificDocument,
    });
  };

  return (
    <React.Fragment>
      <div className={classes.ad_form}>
        <div
          className={`${classes.input_wrapper} ${
            checkIfErrorExists && errors?.adsFormValues[index]?.title ? classes.has_error : ''
          }`}
        >
          <label>{t.create_ad.product_title}</label>
          <input
            type="text"
            required
            {...props.register(`adsFormValues.${index}.title`)}
            onChange={e => {
              props.setValue(`adsFormValues.${index}.title`, e.target.value, { shouldValidate: true });
              props.update(index, { ...values, title: e.target.value });
            }}
          />
          <div className={classes.error}>{checkIfErrorExists && errors?.adsFormValues[index]?.title?.message}</div>
        </div>

        <div
          className={`${classes.input_wrapper} ${
            checkIfErrorExists && errors?.adsFormValues[index]?.description ? classes.has_error : ''
          }`}
        >
          <label>{t.create_ad.description_title}</label>
          <div className={classes.input_container}>
            <textarea
              required
              rows={4}
              {...props.register(`adsFormValues.${index}.description`)}
              onChange={e => {
                props.setValue(`adsFormValues.${index}.description`, e.target.value, { shouldValidate: true });
                props.update(index, { ...values, description: e.target.value });
              }}
            />
            <div className={classes.count_characters}>
              <span>{values?.description?.length || 0}</span> / 1000 {t.create_ad.characters}
            </div>
          </div>
          <div className={classes.error}>
            {checkIfErrorExists && errors?.adsFormValues[index]?.description?.message}
          </div>
        </div>
        <div className={classes.attach_images_wrapper}>
          <div className={classes.images_wrapper}>
            {values.images &&
              values?.images.map((value, i) => {
                return (
                  <div className={classes.image_wrapper} key={i}>
                    <img
                      src={
                        props.advertisement &&
                        !value.uploaded &&
                        !value.file_url.includes('https://') &&
                        !value.file_url.includes('data')
                          ? `${process.env.REACT_APP_STATIC_URL}/${value.file_url}`
                          : value.file_url
                      }
                      alt=""
                    />
                    <div className={classes.icon_wrapper}>
                      <div className={classes.icon} onClick={() => handleDeleteImage(value)}></div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className={classes.attach_btn_wrapper}>
            <div>
              <label>{t.create_ad.attach_photos_desc}</label>
              <button type="button" onClick={() => setShowAttachImageModal(true)} className={classes.attach_button}>
                <div className={classes.icon}></div>
                {t.global.attach}
              </button>
              <div
                className={`${classes.input_wrapper} ${
                  checkIfErrorExists && errors?.adsFormValues[index]?.quantity ? classes.has_error : ''
                }`}
              ></div>
            </div>

            <div
              className={`${classes.input_wrapper} ${
                checkIfErrorExists && errors?.adsFormValues[index]?.quantity ? classes.has_error : ''
              }`}
            >
              <label>{t.create_ad.quantity_title}</label>
              <input
                type="number"
                required
                {...props.register(`adsFormValues.${index}.quantity`)}
                onChange={e => {
                  props.setValue(`adsFormValues.${index}.quantity`, e.target.value, { shouldValidate: true });
                  props.update(index, { ...values, quantity: e.target.value });
                }}
              />
              <div className={classes.error}>
                {checkIfErrorExists && errors?.adsFormValues[index]?.quantity?.message}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.error} style={{ color: 'red', fontSize: '12px' }}>
          {checkIfErrorExists && errors?.adsFormValues[index]?.images?.message}
        </div>

        <div className={classes.additional_documents_wrapper}>
          <div className={classes.label_title}>
            {props.translations.additional_docs}&nbsp;
            <span>{props.translations.max_docs_attach}</span>
          </div>
          <div className={classes.uploaded_docs}>
            {values.additional_documents &&
              values.additional_documents?.map((value, i) => {
                return (
                  <div className={classes.uploaded_doc} key={i}>
                    <div className={classes.document_icon}></div>
                    <div
                      className={classes.file_name}
                      onClick={() => props.downloadFile(value, !value.hasOwnProperty('existing'))}
                    >
                      {value.file_name}
                    </div>
                    <div
                      className={classes.close_icon}
                      onClick={() => {
                        onDocumentRemove(i);
                      }}
                    ></div>
                  </div>
                );
              })}
          </div>
          <div className={classes.button_wrapper}>
            <label className={classes.add_additional_docs_btn}>
              <input
                className={classes.add_additional_docs_btn}
                type="file"
                name="additional_docs"
                id="additional_docs"
                onChange={e => {
                  onDocumentChange(e);
                }}
                accept={REACT_APP_FILES_TYPE}
                style={{ display: 'none' }}
              />
              <div className={classes.icon}></div>
              <div className={classes.text}>{props.translations.add_new_docs}</div>
            </label>
          </div>
        </div>
        {(!props.getValues('aukcija') || props.forms > 1 || index !== 0) && (
          <div className={classes.horizontal_line}></div>
        )}
        <div className={classes.action_btns_wrapper}>
          {props.forms > 1 && index !== 0 && (
            <button
              type="button"
              className={`${classes.remove} ${classes.base_btn}`}
              onClick={() => setShowConfirmModal(!showConfirmModal)}
            >
              {t.create_ad.delete_product}
            </button>
          )}
          {!props.getValues('aukcija') && (
            <button type="button" className={`${classes.add_more} ${classes.base_btn}`} onClick={addMoreAdvertisements}>
              <div className={classes.icon}></div>
              {t.create_ad.add_new_ad}
            </button>
          )}
        </div>
      </div>

      <ConfirmModal
        show={showConfirmModal}
        toggleModal={() => setShowConfirmModal(!showConfirmModal)}
        confirm={() => {
          setShowConfirmModal(!showConfirmModal);
          deleteForm(index);
        }}
        title={t.create_ad.delete_product}
        confirmation={t.create_ad.remove_product_confirmation}
      />

      {showAttachImageModal && (
        <AttachImagesModal
          show={showAttachImageModal}
          images={props?.values?.images}
          index={index}
          toggleModal={() => {
            setShowAttachImageModal(false);
          }}
          confirm={(formIndex, images) => {
            props.update(index, { ...values, images: images });
            props.setValue(`adsFormValues.${index}.images`, images, { shouldValidate: true });
            setShowAttachImageModal(false);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default AdvertisementForm;
