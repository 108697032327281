import { useFetch } from './hooks/useFetch';
import { client } from './services/http/instance';

export interface Industry {
  id: number;
  name: string;
}

export function useIndustries() {
  return useFetch(() => client.get<{ data: Industry[] }>('/industry/get').then(({ data }) => data));
}
