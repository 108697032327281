import React from 'react';
import { ITabs } from './model/tabs.model';
import Tab from './Tab/Tab';
import classes from './Tabs.module.scss';

const Tabs = (props: ITabs) => {
  const onClickHandler = (tabId: number) => {
    props.onClick(tabId);
  };

  return (
    <React.Fragment>
      <nav className={classes.Container}>
        <ul className={classes.Tabs}>
          {props.tabs.map((tab, index) => {
            return (
              <Tab
                id={tab.id}
                label={tab.label}
                labelBadge={tab.labelBadge}
                active={tab.active}
                key={index}
                forceBadge={props.forceBadge}
                onClick={onClickHandler}
              />
            );
          })}
        </ul>
      </nav>
    </React.Fragment>
  );
};

export default Tabs;
