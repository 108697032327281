import React from 'react';
// import Footer from "./PageFooter/PageFooter";
import PrimaryNavbar from './PrimaryNavbar/PrimaryNavbar';
import SecondaryNavbar from './SecondaryNavbar/SecondaryNavbar';
import Sidemenu from '../Sidemenu/Sidemenu';
import { IPage } from './models/page.model';
import classes from './Page.module.scss';
import PageContent from './PageContent/PageContent';

const Page = (props: IPage) => {
  let classList = [classes.Page, 'page'];

  if (props.hideSidebar) {
    classList.push('sidebar-hidden');
  }

  if (props.fullscreen) {
    classList.push('fullscreen');
  }

  if (props.borderlessFullscreen) {
    classList.push('borderless-fullscreen');
  }

  if (props.hideSecondaryNav) {
    classList.push(classes.hide_secondary_nav);
  }

  return (
    <React.Fragment>
      <div className={`${classList.join(' ')}`}>
        {!props.hideSidebar && <Sidemenu />}
        {!props.fullscreen && <PrimaryNavbar hideSidebar={props.hideSidebar} />}
        {!props.fullscreen && !props.hideSecondaryNav && <SecondaryNavbar showBackBtn={props?.showBackBtn || null} />}
        <PageContent {...props} hideSecondaryNav={props.hideSecondaryNav} />
        {props.footerComponent}
      </div>
    </React.Fragment>
  );
};

export default Page;
