import * as Yup from 'yup';
import classes from '../Profile.module.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import { forwardRef, InputHTMLAttributes, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import ModalComponent from '../../../components/UI/ModalComponent/ModalComponent';
import { client } from '../../../services/http/instance';
import { showNotification } from '../../../notification_functions/notifications_functions';
import GlobalLoader from '../../../components/UI/GlobalLoader/GlobalLoader';

type Props = {
  show: boolean;
  toggleChangePasswordModal: () => void;
};

interface BaseInputProps {
  label: string;
  error?: string;
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement>, BaseInputProps {}

// if similar component is used in other forms, extract this component as a global one
const Input = forwardRef<HTMLInputElement, InputProps>(({ label, required, error, ...props }, ref) => {
  return (
    <div className={[classes.input_wrapper, error && classes.has_error].filter(Boolean).join(' ')}>
      <label>
        {label}
        {required && <span>*</span>}
      </label>
      <input ref={ref} {...props} />
      <div className={classes.error}>{error}</div>
    </div>
  );
});

export default function ChangePasswordModal({ show, toggleChangePasswordModal }: Props) {
  const t = useSelector((state: RootState) => state.translations.translations[state.translations.appLanguage]);

  const validationSchema = useMemo(
    () =>
      Yup.object({
        password: Yup.string()
          .trim()
          .required(t.profile.input_required)
          .min(6, t.profile.more_than_6)
          .notOneOf([Yup.ref('currentPassword')], t.profile.same_current_password),
        confirmPassword: Yup.string()
          .required(t.profile.input_required)
          .oneOf([Yup.ref('password')], t.profile.password_confirm_password),
        currentPassword: Yup.string().trim().required(t.profile.input_required),
      }),
    [t]
  );

  const {
    formState: { errors, isValid, isSubmitting },
    register,
    handleSubmit,
  } = useForm<Yup.InferType<typeof validationSchema>>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      password: '',
      confirmPassword: '',
      currentPassword: '',
    },
  });

  return (
    <form
      onSubmit={handleSubmit(async values => {
        try {
          client
            .post('/profile/change-password', values)
            .then(response => {
              showNotification(t.global.success, 'success');
              toggleChangePasswordModal();
            })
            .catch(error => {
              if (error.response.status === 403) {
                return showNotification(t.profile.incorrect_current_password, 'danger');
              }
              return showNotification(t.auth.error_occurred, 'danger');
            });
        } catch {
          showNotification(t.auth.error_occurred, 'danger');
        }
      })}
    >
      <GlobalLoader show={isValid && isSubmitting} />

      <ModalComponent
        show={show}
        size="sm"
        title={t.auth.change_password}
        type="submit"
        onCancel={toggleChangePasswordModal}
        component={
          <div className={classes.change_password_modal_wrapper}>
            <div className={classes.tabs_content_wrapper}>
              <div className={classes.tab_one}>
                <div className={classes.col_one}>
                  <div className={classes.input_wrapper}>
                    <Input
                      label={t.profile.current_password}
                      type="password"
                      {...register('currentPassword')}
                      error={errors.currentPassword?.message}
                    />
                    <Input
                      label={t.auth.new_password}
                      type="password"
                      {...register('password')}
                      error={errors.password?.message}
                    />
                    <Input
                      label={t.profile.confirm_password}
                      type="password"
                      {...register('confirmPassword')}
                      error={errors.confirmPassword?.message}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
    </form>
  );
}
