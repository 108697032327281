import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IRegisterActiveStep,
  IRegisterFieldClearError,
  IRegisterFieldError,
  IRegisterHttpError,
  IRegisterUpdateFieldState,
  IRegisterUpdateInput,
  IStep1FieldError,
  IStep1FieldTouched,
  IStep1FieldValue,
} from './actions';
import { IRegister } from './register.model';

const initialState: IRegister = {
  formData: {
    step1: {
      maticen_broj_ekonomski_operator: {
        value: '',
        validators: {
          required: true,
        },
        errorMessage: 'Задолжително поле',
      },
      edinstven_danocen_broj: {
        value: '',
        validators: {
          required: true,
        },
        errorMessage: 'Задолжително поле',
      },
      naziv: {
        value: '',
        validators: {
          required: true,
        },
        errorMessage: 'Задолжително поле',
      },
      address: {
        value: '',
        validators: {
          required: true,
        },
        errorMessage: 'Задолжително поле',
      },
      city: {
        value: '',
        validators: {
          required: true,
        },
        errorMessage: 'Задолжително поле',
      },
      municipality: {
        value: '',
        validators: {
          required: true,
        },
        errorMessage: 'Задолжително поле',
      },
      postal_code: {
        value: '',
        validators: {
          required: true,
        },
        errorMessage: 'Задолжително поле',
      },
      phone_number: {
        value: '',
        validators: {
          required: true,
        },
        errorMessage: 'Задолжително поле',
      },
      fax: {
        value: '',
        validators: {
          required: true,
        },
        errorMessage: 'Задолжително поле',
      },
      web_location: {
        value: '',
        validators: {
          required: false,
        },
        errorMessage: '',
      },
      country: {
        value: 0,
        validators: {
          required: false,
        },
        errorMessage: '',
      },
      company_info: {
        value: '',
        validators: {
          required: false,
        },
        errorMessage: '',
      },
      company_industries: {
        value: [],
        validators: {
          required: true,
        },
        errorMessage: 'Задолжително поле',
      },
      document_file: {
        value: null,
        validators: {
          required: true,
        },
        errorMessage: 'Задолжително поле',
      },
      company_email: {
        value: '',
        validators: {
          required: true,
        },
        errorMessage: 'Задолжително поле',
      },
      logo: {
        value: null,
        validators: {
          required: true,
        },
        errorMessage: 'Задолжително поле',
      },
    },
    step2: {
      first_name: {
        value: '',
        validators: {
          required: true,
        },
        errorMessage: 'Задолжително поле',
      },
      last_name: {
        value: '',
        validators: {
          required: true,
        },
        errorMessage: 'Задолжително поле',
      },
      // username: {
      // 	value: 'user1',
      // 	validators: {
      // 		required: true
      // 	},
      // 	status: {
      // 		$dirty: false,
      // 		$touched: false,
      // 		$error: null
      // 	}
      // },
      // ssn: {
      // 	value: '',
      // 	validators: {
      // 		required: true
      // 	},
      // 	errorMessage: 'Задолжително поле'
      // },
      // cell_phone_number: {
      //     value: "",
      //     validators: {
      //         required: true,
      //     },
      //     errorMessage: "Задолжително поле",
      // },
      user_phone_number: {
        value: '',
        validators: {
          required: true,
        },
        errorMessage: 'Задолжително поле',
      },
      user_email: {
        value: '',
        validators: {
          required: true,
        },
        errorMessage: 'Задолжително поле',
      },
      password: {
        value: '',
        validators: {
          required: true,
        },
        errorMessage: 'Задолжително поле',
      },
      confirm_password: {
        value: '',
        validators: {
          required: true,
        },
        errorMessage: 'Задолжително поле',
      },
    },
    newsletter_email: true,
  },
  moreInfoCheckboxes: [
    {
      value: 1,
      checked: false,
      label: 'Земјоделски и прехрамбени производи',
      displayLabel: '',
    },
    {
      value: 2,
      checked: false,
      label: 'Лекови, медицински/санитетски потрошен материјал, опрема, помагала и поврзани здравствени услуги',
      displayLabel: '',
    },
    {
      value: 3,
      checked: false,
      label:
        'Информатички и телекомуникациски производи (хардвер и софтвер), компјутерски потрошен материјал и поврзани услуги',
      displayLabel: '',
    },
    {
      value: 4,
      checked: false,
      label:
        'Градежни работи, работи поврзани со животната средина, комунални услуги, ХТЗ опрема/производи и останати поврзани стоки/услуги',
      displayLabel: '',
    },
    {
      value: 5,
      checked: false,
      label: 'Транспортни и складишни услуги',
      displayLabel: '',
    },
    {
      value: 6,
      checked: false,
      label: 'Индустриски и рударски машини, опрема, нивно одржување, резервни делови и поврзани стоки/услуги',
      displayLabel: '',
    },
    {
      value: 7,
      checked: false,
      label:
        'Основни средства - мебел, моторни возила, санитарна опрема, нивно одржување и резервни делови и текстилни производи',
      displayLabel: '',
    },
    {
      value: 8,
      checked: false,
      label: 'Потрошен и канцелариски материјал, печатарски/издавачки услуги и средства за хигиена',
      displayLabel: '',
    },
    {
      value: 9,
      checked: false,
      label: 'Енергетика, нафта, нафтени деривати и поврзани услуги',
      displayLabel: '',
    },
    {
      value: 10,
      checked: false,
      label: 'Финансиски услуги и осигурување',
      displayLabel: '',
    },
    {
      value: 11,
      checked: false,
      label: 'Образовни, правни, советодавни, маркетинг услуги и услуги за привремено вработување',
      displayLabel: '',
    },
    {
      value: 12,
      checked: false,
      label: 'Хотелски, угостителски, рекреативни, културни, безбедносни и спортски услуги',
      displayLabel: '',
    },
  ],
  isReCaptchaValid: false,
  step1IsInvalid: true,
  step2IsInvalid: true,
  activeStep: 1,
  openModal: false,
  openCancelModal: false,
};

const registerSlice = createSlice({
  name: 'register',
  initialState: initialState,
  reducers: {
    checkCompanyIndustry: (state, action: PayloadAction<number>) => {
      const index = state.moreInfoCheckboxes.findIndex(industry => industry.value === action.payload);

      state.moreInfoCheckboxes[index].checked = !state.moreInfoCheckboxes[index].checked;
      state.formData.step1.company_industries.value = state.moreInfoCheckboxes.filter(industry => industry.checked);

      if (state.formData.step1.company_industries.validators?.required) {
        if (!state.formData.step1.company_industries.value.length) {
          state.formData.step1.company_industries.errorMessage = 'Задолжителен избор';
        } else {
          state.formData.step1.company_industries.errorMessage = null;
        }
      }
    },
    updateInputValue: (state, action: PayloadAction<IRegisterUpdateInput>) => {
      const fieldName = action.payload.name;

      if (action.payload.step === 1) {
        state.formData.step1[fieldName as keyof IRegister['formData']['step1']]!.value = action.payload.value;
      } else {
        state.formData.step2[fieldName as keyof IRegister['formData']['step2']]!.value = action.payload.value;
      }
    },
    updateStep1FieldValue: (state, action: PayloadAction<IStep1FieldValue>) => {
      state.formData.step1[action.payload.name].value = action.payload.value;
    },
    updateStep2FieldValue: (state, action: PayloadAction<IStep1FieldValue>) => {
      state.formData.step2[action.payload.name].value = action.payload.value;
    },
    updateStep1FieldErrorMessage: (state, action: PayloadAction<IStep1FieldError>) => {
      state.formData.step1[action.payload.name].errorMessage = action.payload.errorMessage;
    },
    updateStep2FieldErrorMessage: (state, action: PayloadAction<IStep1FieldError>) => {
      state.formData.step2[action.payload.name].errorMessage = action.payload.errorMessage;
    },
    markStep1FieldAsTouched: (state, action: PayloadAction<IStep1FieldTouched>) => {
      state.formData.step1[action.payload.name].touched = true;

      if (state.formData.step1[action.payload.name].validators?.required) {
        const value = state.formData.step1[action.payload.name].value;
        if (value === null || value === undefined || value === '' || !value.length) {
          state.formData.step1[action.payload.name].errorMessage = 'Задолжително поле';
        }
      }
    },
    markStep2FieldAsTouched: (state, action: PayloadAction<IStep1FieldTouched>) => {
      state.formData.step2[action.payload.name].touched = true;

      if (state.formData.step2[action.payload.name].validators?.required) {
        const value = state.formData.step2[action.payload.name].value;
        if (value === null || value === undefined || value === '' || !value.length) {
          state.formData.step2[action.payload.name].errorMessage = 'Задолжително поле';
        }
      }
    },
    markStep1InvalidFields: state => {
      const fields = state.formData.step1;
      let useAnchorJump = false;

      Object.keys(fields).forEach(field => {
        state.formData.step1[field].touched = true;

        if (state.formData.step1[field].validators?.required) {
          const value = state.formData.step1[field].value;
          if (value === null || value === undefined || value === '' || !value.length) {
            // Jump to the first non valid input on the screen
            if (useAnchorJump == false) {
              window.location.href = '#' + field;
              useAnchorJump = true;
            }
            state.formData.step1[field].errorMessage = 'Задолжително поле';
          }
        }
      });
    },
    markStep2InvalidFields: state => {
      const fields = state.formData.step2;
      let useAnchorJump = false;

      Object.keys(fields).forEach(field => {
        state.formData.step2[field].touched = true;

        if (state.formData.step2[field].validators?.required) {
          const value = state.formData.step2[field].value;
          if (value === null || value === undefined || value === '' || !value.length) {
            // Jump to the first non valid input on the screen
            if (useAnchorJump == false) {
              window.location.href = '#' + field;
              useAnchorJump = true;
            }
            state.formData.step2[field].errorMessage = 'Задолжително поле';
          }
        }
      });
    },
    markFieldAsTouched: (state, action: PayloadAction<IRegisterUpdateFieldState>) => {},
    markFieldAsDirty: (state, action: PayloadAction<IRegisterUpdateFieldState>) => {},
    setFieldError: (state, action: PayloadAction<IRegisterFieldError>) => {},
    clearFieldError: (state, action: PayloadAction<IRegisterFieldClearError>) => {},
    setHttpFieldError: (state, action: PayloadAction<IRegisterHttpError>) => {},
    reset: state => {
      Object.keys(state).forEach(key => {
        let typedKey = key as keyof IRegister;
        (state[typedKey] as any) = initialState[typedKey];
      });
    },
    setActiveStep: (state, action: PayloadAction<IRegisterActiveStep>) => {
      state.activeStep = action.payload.step;
    },
    openModal: state => {
      state.openModal = true;
    },
    closeModal: state => {
      state.openModal = false;
    },
    openCancelModal: state => {
      state.openCancelModal = true;
    },
    closeCancelModal: state => {
      state.openCancelModal = false;
    },
    setReCaptchaValid: (state, action: PayloadAction<boolean>) => {
      state.isReCaptchaValid = action.payload;
    },
    toggle: state => {
      state.formData.newsletter_email = !state.formData.newsletter_email;
    },
  },
});

export const registerActions = registerSlice.actions;
export default registerSlice.reducer;
