import classes from './SubmitOffer.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import PlusIcon from '../../../assets/icons/Plus.svg';
import CloseIcon from '../../../assets/icons/CloseAccent.svg';
import FileText from '../../../assets/icons/FileText.svg';
import { toBase64 } from '../../../utils/toBase64';
import { showNotification } from '../../../notification_functions/notifications_functions';
import { useFieldArray } from 'react-hook-form';
import FileSaver from 'file-saver';

type Props = {
  control: any;
  productId: number;
  offerId: number;
};

function Documents({ control, productId, offerId }: Props) {
  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage];
  });

  const t_product = t.product;
  const { REACT_APP_FILES_TYPE }: any = process.env;

  const { fields, append, remove, update }: any = useFieldArray({
    control: control,
    name: `productItems.${productId}.offers.${offerId}.documents`,
    keyName: '_id',
  });

  const onDocumentChange = e => {
    const file = e.target.files[0];

    let filename = file.name;

    toBase64(file).then(result => {
      if (result) {
        append({
          name: filename,
          file: result,
        });
      } else {
        showNotification(t.product.attach_problem, 'danger');
      }
    });

    e.target.value = '';
  };

  const onDokumentiRemove = i => {
    remove(i);
  };

  return (
    <div className={classes.additional_docs_wrapper}>
      <div className={classes.label}>{t_product.additional_docs}</div>
      <label
      // htmlFor={`additionl_doc_${offerId}_${productId}`}
      // className={`${classes.add_doc_btn} ${isLoading ? classes.disabled : ''}`}
      >
        <img src={PlusIcon} alt="Add document" />
        {t_product.add_new_documents}
        <input
          type="file"
          //   name={`additionl_doc_${offerId}_${productId}`}
          //   id={`additionl_doc_${offerId}_${productId}`}
          style={{ display: 'none' }}
          accept={'.pdf,image/png,image/jpeg'}
          onChange={e => onDocumentChange(e)}
        />
      </label>
      <div className={classes.docs_wrapper}>
        {fields &&
          fields?.map((doc, i) => {
            return (
              <div key={i} className={classes.doc_wrapper} onClick={() => FileSaver.saveAs(doc.file, doc.name)}>
                <img className={classes.file_icon} src={FileText} alt="File" />
                <div className={classes.file_name}>{doc.name}</div>
                <img
                  className={classes.close_icon}
                  src={CloseIcon}
                  onClick={e => {
                    e.stopPropagation();
                    onDokumentiRemove(i);
                  }}
                  alt="Close"
                />
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default Documents;
