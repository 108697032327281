import React, { useState } from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import { Link, NavLink } from 'react-router-dom';

import { RootState } from './../../../store';
import Page from './../../../components/Layout/Page/Page';
import { httpGet } from './../../../services/http';
import { IPagination, ISortingParameters } from './offers.model';
import { ISearchParameters } from '../../../components/Tools/Filters/model';
import Filters from '../../../components/Tools/Filters/Filters';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import classes from './Offers.module.scss';
import { RoutesEnum } from '../../../enums/routes';
import { Badge } from 'react-bootstrap';

const OfferStatus = ({ cell, translate }) => {
  let offerStatus = 'default';
  switch (cell) {
    case '1':
      offerStatus = 'in_progress';
      break;
    case '2':
      offerStatus = 'auction';
      break;
    case '3':
      offerStatus = 'accepted';
      break;
    case '4':
      offerStatus = 'declined';
      break;
    case '5':
      offerStatus = 'canceled';
      break;
    case '6':
      offerStatus = 'finished';
      break;
    default:
      break;
  }
  return (
    <div className={`${classes.status} ${classes[offerStatus]}`}>
      <span></span>
      {translate[offerStatus]}
    </div>
  );
};

const Offers = () => {
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [pagination, setPagination] = useState<IPagination>({
    sizePerPage: 10,
    page: 1,
  });
  const [myOffers, setMyOffers]: any = useState<[]>([]);
  const [totalData, setTotalData] = useState<number>(0);
  const [sorting, setSorting] = useState<ISortingParameters>({
    sortField: null,
    sortOrder: undefined,
  });
  const [searchParameters, setSearchparameters] = useState<ISearchParameters>({
    searchValue: '',
    datumOd: '',
    datumDo: '',
    category_id: '',
  });
  const [activeTab, setActiveTab]: any = useState('active');

  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage];
  });

  const t_offers = t.offers;
  const t_global = t.global;
  const t_current_ads = t.current_advertisements;

  const fetchMyOffers = async (
    page = 1,
    sizePerPage = 10,
    sortField = null,
    sortOrder = undefined,
    selectedTab = 'active',
    filter = searchParameters
  ) => {
    setMyOffers([]);
    setPagination({
      page,
      sizePerPage,
    });
    setLoadingData(true);
    try {
      const query = {
        page: page ?? 1,
        size: sizePerPage ?? 10,
        status: ['active', 'inactive'].includes(selectedTab) ? `&status=${selectedTab}` : '',
        search: filter.searchValue ? `&search=${filter.searchValue}` : '',
        order: sortField && sortOrder ? `&sortBy=${sortField}&sortOrder=${sortOrder}` : '',
        category: filter.category_id ? `&industryId=${filter.category_id}` : '',
      };

      const response = await httpGet<any>(
        `/ads?own=offers&offers=1${query.status}&page=${query.page}&size=${query.size}${query.search}${query.order}${query.category}`
      );
      setMyOffers(response?.data?.data || []);
      setTotalData(response?.data?.total || 0);
      setLoadingData(false);
    } catch (error) {
      console.error(error);
      setLoadingData(false);
    }
  };

  const onSearchChangeHandler = (params: ISearchParameters) => {
    setSearchparameters(params);
    const { page, sizePerPage } = pagination;
    const { sortField, sortOrder } = sorting;
    fetchMyOffers(page, sizePerPage, sortField, sortOrder, activeTab, params);
  };

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    fetchMyOffers(page, sizePerPage, sortField, sortOrder, activeTab);
  };

  const onTabChange = (active: string) => {
    const { page, sizePerPage } = pagination;
    const { sortField, sortOrder } = sorting;
    setActiveTab(active);
    fetchMyOffers(page, sizePerPage, sortField, sortOrder, active);
  };

  const tableColumns = [
    {
      dataField: 'id',
      text: t_offers.offer_number,
      formatter: (cell, row) => {
        return <Link to={`/my-offers/preview/${row?.id}`}>{cell}</Link>;
      },
      sort: true,
    },
    {
      dataField: 'title',
      text: t_offers.offer_name,
      sort: true,
    },
    {
      dataField: 'company.naziv',
      text: t_current_ads['company'],
      formatter: (cell, row) => {
        if (row.company.id) {
          return (
            <NavLink to={`/company/${row.company.id}`} className={classes.AdLink}>
              {row.company.naziv}
            </NavLink>
          );
        } else {
          return null;
        }
      },
      sort: true,
    },
    {
      dataField: 'industry.name',
      text: t_current_ads.categories,
      sort: true,
    },
    // {
    //   dataField: 'price_offer',
    //   text: t_offers.offered_price,
    //   sort: true,
    // },
    // {
    //   dataField: 'status',
    //   text: t_offers.status,
    //   formatter: (cell, row) => {
    //     return <OfferStatus cell={row.active.toString()} translate={t_offers} />;
    //   },
    //   sort: true,
    // },
  ];

  const customCaret = (order, column) => {
    if (!order) {
      return (
        <span style={{ display: 'inline-block', position: 'relative', top: '-5px' }}>
          <span className={classes.sort_wrapper}>
            <span className={`${classes.caret} ${classes.caret_up}`}></span>
            <span className={classes.caret}></span>
          </span>
        </span>
      );
    } else if (order === 'asc') {
      return (
        <span style={{ display: 'inline-block', position: 'relative', top: '-2px' }}>
          <span className={classes.sort_wrapper}>
            <span className={`${classes.caret} ${classes.caret_up}`}></span>
          </span>
        </span>
      );
    } else if (order === 'desc') {
      return (
        <span style={{ display: 'inline-block', position: 'relative', top: '-2px' }}>
          <span className={classes.sort_wrapper}>
            <span className={classes.caret}></span>
          </span>
        </span>
      );
    } else {
      return null;
    }
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {`${t_global['table_total_text']} ${from} ${t_global['table_total_text_1']} ${to} ${t_global['table_total_text_2']} ${size} ${t_global['table_total_text_3']}`}
    </span>
  );

  const paginationOptions = {
    custom: true,
    totalSize: totalData,
    paginationSize: 4,
    pageStartIndex: 1,
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    hideSizePerPage: false,
    hidePageListOnlyOnePage: false,
    firstPageText: '<<',
    prePageText: '<',
    nextPageText: '>',
    lastPageText: '>>',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
    ],
  };

  return (
    <React.Fragment>
      <Page>
        <div className={classes.offers_screen_wrapper}>
          <Filters onSearchChange={onSearchChangeHandler} advertisements={myOffers} title={t.offers.my_offers} />
          <div className={classes.table_wrapper}>
            <div className={classes.table_container}>
              <div className={classes.buttons_wrapper}>
                <div
                  className={`${classes.button} ${activeTab === 'active' ? classes.active : ''}`}
                  onClick={() => onTabChange('active')}
                >
                  {t_current_ads['active']}{' '}
                  {activeTab === 'active' ? (
                    <Badge bg="primary">{activeTab === 'active' ? totalData : ''}</Badge>
                  ) : (
                    <span className={classes.text}>{activeTab === 'active' ? totalData : ''}</span>
                  )}
                </div>
                <div
                  className={`${classes.button} ${activeTab === 'inactive' ? classes.active : ''}`}
                  onClick={() => onTabChange('inactive')}
                >
                  {t_current_ads['finished']}{' '}
                  {activeTab === 'inactive' ? (
                    <Badge bg="primary">{activeTab === 'inactive' ? totalData : ''}</Badge>
                  ) : (
                    <span className={classes.text}>{activeTab === 'inactive' ? totalData : ''}</span>
                  )}
                </div>
              </div>
              <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => (
                  <div>
                    <BootstrapTable
                      keyField="id"
                      remote={true}
                      onTableChange={onTableChange}
                      data={myOffers}
                      columns={tableColumns}
                      classes={classes.offers_table}
                      noDataIndication={loadingData ? t_global.loading : t_global.no_data}
                      sort={{
                        sortCaret: customCaret,
                      }}
                      {...paginationTableProps}
                    />
                    <div className={classes.pagination_wrapper}>
                      <PaginationTotalStandalone {...paginationProps} />
                      <div>
                        <PaginationListStandalone {...paginationProps} />
                        <SizePerPageDropdownStandalone {...paginationProps} />
                      </div>
                    </div>
                  </div>
                )}
              </PaginationProvider>
            </div>
          </div>
        </div>
      </Page>
    </React.Fragment>
  );
};

export default Offers;
