import React from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { RootState } from '../../../../store';
import { IAdHeader } from './model/header.model';

import classes from './AdHeader.module.scss';

const AdHeader = (props: IAdHeader) => {
  const translations = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage]['create_ad'];
  });

  return (
    <React.Fragment>
      <div className={classes.Container}>
        <div className={classes.page_title_wrapper}>
          <div className={classes.AdFormTitleRow}>{translations['edit_advertisement']}</div>
          <div className={classes.ad_details_wrapper}>
            <div className={classes.AdFormTitleRowRightDate}>
              {dayjs(props?.adInfo?.date).isValid()
                ? dayjs(props?.adInfo?.date).format('DD.MM.YYYY')
                : props?.adInfo?.date}
            </div>
            <div className={classes.separator}></div>
            <div className={classes.AdFormTitleRowRightInvoiceNo}>
              {translations['number_od_advertisment']}: <span>{props?.adInfo?.number}</span>
            </div>
          </div>
        </div>
        <div className={classes.Separator}></div>
      </div>
    </React.Fragment>
  );
};

export default AdHeader;
