import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../../../store';
import { IAdHeader } from './model/header.model';

import TermsAndConditionsModal from '../BaseInfo/Modals/TermsAndConditions/TermsAndConditions';

import classes from './AdHeader.module.scss';

const AdHeader = (props: IAdHeader) => {
  const translations = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage]['create_ad'];
  });

  const [showModal, setShowModal] = useState(false);

  return (
    <React.Fragment>
      <div className={classes.Container}>
        <div className={classes.AdHeaderTitleContainer}>
          {translations['read_terms_and_codititons']}&nbsp;
          <div onClick={() => setShowModal(true)}>{translations['terms_and_conditions']}</div>.
        </div>
        <div className={classes.page_title_wrapper}>
          <div className={classes.AdFormTitleRow}>{translations['create_advertisment']}</div>
          {/* <div className={classes.ad_details_wrapper}>
                        <div className={classes.AdFormTitleRowRightDate}>
                            08.10.2022
                        </div>
                        <div className={classes.separator}></div>
                        <div className={classes.AdFormTitleRowRightInvoiceNo}>
                            {translations["number_od_advertisment"]}: <span>1234</span>
                        </div>
                    </div> */}
        </div>
        <div className={classes.Separator}></div>
      </div>
      <TermsAndConditionsModal show={showModal} toggleModal={() => setShowModal(!showModal)} />
    </React.Fragment>
  );
};

export default AdHeader;
