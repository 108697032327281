import React from 'react';
import { IButton } from './model/Button.model';

import classes from './Button.module.scss';
import { Loader } from '../Loader/Loader';

const Button = (props: IButton) => {
  let classList = [classes.Button, 'btn'];
  let classListAsLink = [classes.Link, 'btn'];
  let labelClasses: any = [];

  if (props.fullWidth) {
    classList.push('w-100');
  }

  if (props.autoHeight) {
    classList.push(classes.ButtonAutoHeight);
  }

  if (props.borderless) {
    classList.push(classes.Borderless);
  }

  classList.push(props.class ? classes[props.class] : classes.accent);
  classListAsLink.push(props.class ? classes[props.class] : classes.accent);

  if (props.icon) {
    labelClasses.push(classes.IconLabel);
  }

  if (props.hideLabelOnSmallScren) {
    labelClasses.push(classes.InnerLabel);
  }

  let button = (
    <button
      type={props.type ?? 'button'}
      className={classList.join(' ')}
      onClick={props.onClick}
      disabled={props.disabled || props.loading}
      style={props.style}
    >
      <div className={classes.ButtonContent}>
        {props.loading && <Loader />}
        {props.icon && !props.iconPosition && <img src={props.icon} alt={props.icon} />}
        <span className={labelClasses.join(' ')}>{props.label}</span>
        {props.icon && props.iconPosition === 'right' && <img src={props.icon} alt={props.icon} />}
      </div>
    </button>
  );

  if (props.asLink) {
    if (props.asNormalLink) {
      classListAsLink.push(classes.AsNormalLink);
    }

    if (props.asNormalWeight) {
      classListAsLink.push(classes.AsNormalWeight);
    }

    button = (
      <button
        type="button"
        className={props.noDefaultStyle ? '' : classListAsLink.join(' ')}
        onClick={props.onClick}
        form={props.form}
        style={props.style ? props.style : {}}
      >
        {props.icon && !props.iconPosition && <img src={props.icon} alt={props.icon} />}
        <span className={labelClasses.join(' ')}>{props.label}</span>
        {props.icon && props.iconPosition === 'right' && <img src={props.icon} alt={props.icon} />}
      </button>
    );
  }

  return (
    <React.Fragment>
      {props.labelAbove && <label className={classes.Label}>{props.labelAbove}</label>}
      {button}
    </React.Fragment>
  );
};

export default Button;
