import { createSlice } from '@reduxjs/toolkit';
import { IChat } from './chat.model';
import { v4 as uuid } from 'uuid';

const initialState: IChat = {
  unreadCounter: 0,
};

const chatSlice = createSlice({
  name: 'chat',
  initialState: initialState,
  reducers: {
    setUnreadMessages: (state, action) => {
      return (state.unreadCounter = action.payload);
    },
  },
});

export const chatActions = chatSlice.actions;
export default chatSlice.reducer;
