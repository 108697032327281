import React, { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge';

import Countdown from '../../../components/UI/Countdown/Countdown';
import { RootState } from '../../../store';
import { RoutesEnum } from '../../../enums/routes';
import Page from '../../../components/Layout/Page/Page';
import { IPaginationParameters, ISortingParameters } from './current-ads.model';
import { httpGet } from '../../../services/http';
import Filters from '../../../components/Tools/Filters/Filters';
import { ISearchParameters } from '../../../components/Tools/Filters/model';

import BidIcon from '../../../assets/icons/BidIcon.svg';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import classes from './CurrentAdvertisements.module.scss';
import { TimeFormatter } from '../../../components/Tools/TableTimeCountdownFormatter/TableTimeCountdownFormatter';

const ActionTableContent = props => {
  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage]['current_advertisements'];
  });
  return t[props.cell ? 'yes' : 'no'];
};

const CurrentAdvertisements = () => {
  const [tableData, setTableData]: any = useState([]);
  const [activeTab, setActiveTab]: any = useState('active');
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [searchParameters, setSearchparameters] = useState<ISearchParameters>({
    searchValue: '',
    category_id: '',
  });
  const [pagination, setPagination] = useState<IPaginationParameters>({
    sizePerPage: 10,
    page: 1,
  });
  const [sorting, setSorting] = useState<ISortingParameters>({
    sortField: null,
    sortOrder: undefined,
  });

  const [totalData, setTotalData] = useState<number>(0);

  const translations = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage];
  });

  const t_current_ads = translations.current_advertisements;
  const t_global = translations.global;

  const fetchAds = async (
    page = 1,
    sizePerPage = 10,
    sortField = null,
    sortOrder = undefined,
    selectedTab = 'active',
    filter = searchParameters
  ) => {
    setTableData([]);
    setPagination({
      page,
      sizePerPage,
    });
    setLoadingData(true);
    try {
      const query = {
        page: page ?? 1,
        status: ['active', 'inactive'].includes(selectedTab) ? `status=${selectedTab}` : '',
        size: sizePerPage ?? 10,
        search: filter.searchValue ? `&search=${filter.searchValue}` : '',
        order: sortField && sortOrder ? `&sortBy=${sortField}&sortOrder=${sortOrder}` : '',
        category: filter.category_id ? `&industryId=${filter.category_id}` : '',
      };

      const response = await httpGet<any>(
        `/ads?${query.status}&page=${query.page}&size=${query.size}${query.search}${query.order}${query.category}`
      );
      setTableData(response.data.data);
      setTotalData(response.data.total);
      setLoadingData(false);
    } catch (error) {
      setLoadingData(false);
    }
  };

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    fetchAds(page, sizePerPage, sortField, sortOrder, activeTab);
  };

  const onTabChange = (active: string) => {
    const { page, sizePerPage } = pagination;
    const { sortField, sortOrder } = sorting;
    setActiveTab(active);
    fetchAds(page, sizePerPage, sortField, sortOrder, active);
  };

  const tableColumns = [
    {
      dataField: 'already_bid',
      text: '',
      headerFormatter: () => {
        return <img src={BidIcon} alt="Already bid" />;
      },
      formatter: (cell, row) => {
        if (cell && cell === true) {
          return <img src={BidIcon} alt="Already bid" />;
        } else {
          return '';
        }
      },
      sort: false,
    },
    {
      dataField: 'id',
      text: t_current_ads.offer_num,
      formatter: (cell, row) => {
        return (
          <NavLink to={`/advertisements/${row.id}`} className={classes.AdLink}>
            {row.id}
          </NavLink>
        );
      },
      sort: true,
    },
    {
      dataField: 'company.naziv',
      text: t_current_ads['company'],
      formatter: (cell, row) => {
        if (row.company.id) {
          return (
            <NavLink to={`/company/${row.company.id}`} className={classes.AdLink}>
              {row.company.naziv}
            </NavLink>
          );
        } else {
          return null;
        }
      },
      sort: true,
    },
    {
      dataField: 'title',
      text: t_current_ads.offer_name,
      sort: true,
    },
    {
      dataField: 'industry.name',
      text: t_current_ads.categories,
      sort: true,
    },
    {
      dataField: 'auction_start_date',
      text: t_current_ads.start,
      formatter: (cell, row) => {
        if (!row.aukcija) return <div style={{ textAlign: 'center' }}>-</div>;

        return TimeFormatter(cell);
      },
      sort: true,
    },
    {
      dataField: 'auction_end_date',
      text: t_current_ads.closure,
      formatter: (cell, row) => {
        if (!row.aukcija) return <div style={{ textAlign: 'center' }}>-</div>;

        return TimeFormatter(cell);
      },
      sort: true,
    },
    {
      dataField: 'aukcija',
      text: t_current_ads.auction,
      sort: true,
      formatter: (cell, row) => <ActionTableContent cell={cell} />,
    },
  ];

  const customCaret = (order, column) => {
    if (!order) {
      return (
        <span style={{ display: 'inline-block', position: 'relative', top: '-5px' }}>
          <span className={classes.sort_wrapper}>
            <span className={`${classes.caret} ${classes.caret_up}`}></span>
            <span className={classes.caret}></span>
          </span>
        </span>
      );
    } else if (order === 'asc') {
      return (
        <span style={{ display: 'inline-block', position: 'relative', top: '-2px' }}>
          <span className={classes.sort_wrapper}>
            <span className={`${classes.caret} ${classes.caret_up}`}></span>
          </span>
        </span>
      );
    } else if (order === 'desc') {
      return (
        <span style={{ display: 'inline-block', position: 'relative', top: '-2px' }}>
          <span className={classes.sort_wrapper}>
            <span className={classes.caret}></span>
          </span>
        </span>
      );
    } else {
      return null;
    }
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {`${t_global['table_total_text']} ${from} ${t_global['table_total_text_1']} ${to} ${t_global['table_total_text_2']} ${size} ${t_global['table_total_text_3']}`}
    </span>
  );

  const paginationOptions = {
    custom: true,
    totalSize: totalData,
    page: pagination.page,
    sizePerPage: pagination.sizePerPage,
    paginationSize: 4,
    pageStartIndex: 1,
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    hideSizePerPage: false,
    hidePageListOnlyOnePage: false,
    firstPageText: '<<',
    prePageText: '<',
    nextPageText: '>',
    lastPageText: '>>',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
    ],
  };

  const onSearchChangeHandler = (params: ISearchParameters) => {
    setSearchparameters(params);
    const { page, sizePerPage } = pagination;
    const { sortField, sortOrder } = sorting;
    fetchAds(page, sizePerPage, sortField, sortOrder, activeTab, params);
  };

  return (
    <React.Fragment>
      <Page>
        <Filters
          onSearchChange={onSearchChangeHandler}
          advertisements={tableData}
          noDatePicker
          title={translations.sidemenu['Тековни огласи']}
        />
        <div className={classes.table_wrapper}>
          <div className={classes.buttons_wrapper}>
            <div
              className={`${classes.button} ${activeTab === 'active' ? classes.active : ''}`}
              onClick={() => onTabChange('active')}
            >
              {t_current_ads['active']}{' '}
              {activeTab === 'active' ? (
                <Badge bg="primary">{activeTab === 'active' ? totalData : ''}</Badge>
              ) : (
                <span className={classes.text}>{activeTab === 'active' ? totalData : ''}</span>
              )}
            </div>
            <div
              className={`${classes.button} ${activeTab === 'inactive' ? classes.active : ''}`}
              onClick={() => onTabChange('inactive')}
            >
              {t_current_ads['finished']}{' '}
              {activeTab === 'inactive' ? (
                <Badge bg="primary">{activeTab === 'inactive' ? totalData : ''}</Badge>
              ) : (
                <span className={classes.text}>{activeTab === 'inactive' ? totalData : ''}</span>
              )}
            </div>
          </div>
          <div className={classes.table_container}>
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
              {({ paginationProps, paginationTableProps }) => (
                <div>
                  <BootstrapTable
                    keyField="id"
                    remote={{
                      pagination: true,
                    }}
                    onTableChange={onTableChange}
                    data={tableData}
                    columns={tableColumns}
                    classes={classes.offers_table}
                    noDataIndication={loadingData ? t_global.loading : t_global.no_data}
                    sort={{
                      sortCaret: customCaret,
                    }}
                    {...paginationTableProps}
                  />
                  <div className={classes.pagination_wrapper}>
                    <PaginationTotalStandalone {...paginationProps} />
                    <div>
                      <PaginationListStandalone {...paginationProps} />
                      <SizePerPageDropdownStandalone {...paginationProps} />
                    </div>
                  </div>
                </div>
              )}
            </PaginationProvider>
          </div>
        </div>
      </Page>
    </React.Fragment>
  );
};

export default CurrentAdvertisements;
