import React, { useEffect, useState } from 'react';
import classes from './MenuChart.module.scss';

import { Bar, Line, Chart } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

const MenuChart = () => {
  const [chartData, setChartData] = useState<any>({});

  useEffect(() => {
    const fetchPrices = async () => {
      const res = await fetch('https://api.coincap.io/v2/assets/?limit=5');
      const response = await res.json();

      const labels = [10, 15, 12, 18, 14, 12, 13];
      const data = [10, 15, 12, 18, 14, 12, 13];

      const canvas: HTMLCanvasElement = document.getElementById('dummy') as HTMLCanvasElement;
      var ctx = canvas.getContext('2d');
      var gradient = ctx!.createLinearGradient(0, 0, 0, 300);
      gradient.addColorStop(0, 'rgba(255, 255, 255, 1)');
      gradient.addColorStop(0.5, 'rgba(100, 100, 0,0)');

      setChartData({
        labels,
        datasets: [
          {
            label: 'Price in USD',
            data,
            fill: true,
            borderColor: 'white',
            backgroundColor: gradient,
          },
        ],
      });
    };
    fetchPrices();
  }, []);

  const plugin = {
    id: 'custom_canvas_background_color',
    beforeDraw: chart => {
      const { ctx } = chart;
      ctx.save();
      ctx.globalCompositeOperation = 'destination-over';
      ctx.fillStyle = '#0044bb';
      ctx.fillRect(0, 0, chart.width, chart.height);
      ctx.restore();
    },
  };

  const chartOptions = {
    backgroundColor: 'red',
    lineTension: 0.5,
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: 0,
      },
    },
    scales: {
      x: {
        display: false,
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
      y: {
        display: false,
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
  };

  const translations = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage]['sidemenu'];
  });

  return (
    <>
      <canvas id="dummy" className={classes.Dummy}></canvas>
      <div className={classes.MainContainer}>
        <div className={classes.ChartSummary}>
          <div className={classes.Info}>
            <div className={classes.Label}>{translations['Вкупно компании']}</div>
            <div className={classes.Value}>1484</div>
          </div>
          <div className={classes.InfoDimmed}>
            <div className={classes.Label}>+28 {translations['нови компании']}</div>
          </div>
        </div>
        <div className={classes.Container}>
          {Object.keys(chartData).length > 0 && (
            <Line id="line-chart" data={chartData} plugins={[plugin]} options={chartOptions} />
          )}
        </div>
      </div>
    </>
  );
};

export default MenuChart;
