import React from 'react';
import classes from './Select.module.scss';
import Select from 'react-select';

import CaretDownAccent from './../../../assets/icons/CaretDownAccent.svg';
import { ISelect } from './model/select.model';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

const SelectComponent = (props: ISelect) => {
  const translations = useSelector(
    (state: RootState) => state.translations.translations[state.translations.appLanguage]['components']
  );

  const onInputChangeHandler = e => {
    if (e) {
      props.onItemSelected(e);
    }
  };

  const onInputBlurHandler = () => {
    props.onInputBlur && props.onInputBlur();
  };

  let classList = ['custom-select'];
  let prefixClassList = ['custom-select'];
  if (props.class) {
    classList.push(props.class);
  }

  if (props.borderless) {
    classList.push('borderless-select');
  }

  if (props.isInInputGroup) {
    classList.push('select-input-group');
  }

  let containerClassList = [classes.Container];
  if (props.marginless) {
    containerClassList.push(classes.Marginless);
  }

  return (
    <React.Fragment>
      <div
        className={`${containerClassList.join(' ')} ${
          props.field?.touched && props.field?.errorMessage ? classes.ErrorInput : null
        }`}
      >
        {props.label && (
          <label htmlFor={props.name}>
            {props.label} <span className={classes.RequiredIndicator}>{props.field?.validators?.required && '*'}</span>{' '}
          </label>
        )}
        <Select
          options={props.options}
          className={classList.join(' ')}
          classNamePrefix={prefixClassList.join(' ')}
          value={props.field?.value}
          name={props.name}
          placeholder=""
          menuPlacement="auto"
          onChange={onInputChangeHandler}
          onBlur={onInputBlurHandler}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: () => {
              if (props.noDropdownArrow) return null;
              return <img className={classes.DropdownIndicator} src={CaretDownAccent} alt="CaretDownAccent" />;
            },
          }}
        />
        {props.field?.touched && props.field?.errorMessage && (
          <small id="emailHelp" className={`${classes.ErrorText} form-text text-muted`}>
            {translations[props.field?.errorMessage]}
          </small>
        )}
      </div>
    </React.Fragment>
  );
};

export default SelectComponent;
