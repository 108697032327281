import dayjs from 'dayjs';

export const createDaysForPreviousMonth = (year, month, currentMonthDays) => {
  const firstDayOfTheMonthWeekday = getWeekday(currentMonthDays[0].date);

  const previousMonth = dayjs(`${year}-${month}-01`).subtract(1, 'month');

  // Cover first day of the month being sunday (firstDayOfTheMonthWeekday === 0)
  const visibleNumberOfDaysFromPreviousMonth = !firstDayOfTheMonthWeekday ? 0 : firstDayOfTheMonthWeekday;

  const previousMonthLastMondayDayOfMonth = dayjs(currentMonthDays[0].date)
    .subtract(visibleNumberOfDaysFromPreviousMonth, 'day')
    .date();

  return [...Array(visibleNumberOfDaysFromPreviousMonth)].map((day, index) => {
    let date = new Date();
    date.setDate(date.getDate() - 1);

    return {
      date: dayjs(
        `${previousMonth.year()}-${previousMonth.month() + 1}-${previousMonthLastMondayDayOfMonth + index}`
      ).format('YYYY-MM-DD'),
      dayOfMonth: previousMonthLastMondayDayOfMonth + index,
      isCurrentMonth: false,
      today: false,
      isBeforeToday: dayjs(`${year}-${month}-${index + 1}`).isBefore(date),
    };
  });
};

export const createDaysForNextMonth = (year, month, currentMonthDays, previousMonthDays) => {
  const lastDayOfTheMonthWeekday = getWeekday(`${year}-${month}-${currentMonthDays.length}`);

  const nextMonth = dayjs(`${year}-${month}-01`).add(1, 'month');

  const totalDispayedDaysPreviously = previousMonthDays.length + currentMonthDays.length;
  const totalRows = Math.round(totalDispayedDaysPreviously / 6); // 6 -> total rows per month in the calendar
  const totalDaysRepresentable = 42; // 6 * 7, total rows * days in week

  const visibleNumberOfDaysFromNextMonth = totalDaysRepresentable - totalDispayedDaysPreviously;

  return [...Array(visibleNumberOfDaysFromNextMonth)].map((day, index) => {
    return {
      date: dayjs(`${nextMonth.year()}-${nextMonth.month() + 1}-${index + 1}`).format('YYYY-MM-DD'),
      dayOfMonth: index + 1,
      isCurrentMonth: false,
      today: false,
      isBeforeToday: false,
    };
  });
};

export const getWeekday = date => {
  return dayjs(date).day();
};

export const getNumberOfDaysInMonth = (year, month) => {
  return dayjs(`${year}-${month}-01`).daysInMonth();
};

export const createDaysForCurrentMonth = (year, month) => {
  return [...Array(getNumberOfDaysInMonth(year, month))].map((day, index) => {
    let date = new Date();
    date.setDate(date.getDate() - 1);
    return {
      date: dayjs(`${year}-${month}-${index + 1}`).format('YYYY-MM-DD'),
      dayOfMonth: index + 1,
      isCurrentMonth: true,
      today: dayjs(new Date()).date() === index + 1 && +dayjs(new Date()).month() + 1 === +month,
      isBeforeToday: dayjs(`${year}-${month}-${index + 1}`).isBefore(date),
    };
  });
};
