import { Store } from 'react-notifications-component';

import classes from './style.module.scss';

const customMessageContent = ({ id, notificationConfig }, type) => {
  return (
    <div
      className={`${classes.notification_wrapper} ${classes[type]}`}
      style={notificationConfig.dismiss.click ? {} : { cursor: 'default' }}
    >
      <div className={classes.message} dangerouslySetInnerHTML={{ __html: notificationConfig?.message }}></div>
      {notificationConfig.dismiss.showIcon && (
        <div className={classes.icon_wrapper} onClick={() => Store.removeNotification(id)}>
          <div className={classes.close_button}></div>
        </div>
      )}
    </div>
  );
};

export const showNotification = (message, type: 'success' | 'danger') => {
  Store.addNotification({
    message,
    type,
    insert: 'bottom',
    container: 'bottom-right',
    dismiss: {
      click: true,
      duration: 5000,
      showIcon: false,
    },
    content: props => customMessageContent(props, type),
  });
};

export const hideNotifications = () => {
  Store.removeAllNotifications();
};
