import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import * as FileSaver from 'file-saver';

import Page from '../../components/Layout/Page/Page';
import Header from './Header/Header';
import ProductList from './ProductList/ProductList';
import { RootState } from '../../store';
import SubmitOfferModal from './SubmitOfferModal/SubmitOffer';
import { httpGet } from '../../services/http';

import BidIconGreen from '../../assets/icons/BidIconGreen.svg';

import classes from './AdvertisementPreview.module.scss';
import { download } from '../../utils/download';

const AdvertisementPreview = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage];
  });

  const t_global = t.global;
  const t_product = t.product;

  const [values, setValues]: any = useState({
    advertisement: {
      id: '',
      broj_oglas: '',
      dogovoren_organ: '',
      rok_dostavuvanje: '',
      rok_dostavuvanje_vreme: '',
      rok_prasanja_datum: '',
      rok_prasanja_vreme: '',
      rok_odgovori_datum: '',
      rok_odgovori_vreme: '',
      rok_na_isporaka: '',
      reated_at: '',
      contact_name: '',
      contact_phone: '',
      contact_email: '',
      criteria: '',
      auction: '',
      auction_date: '',
      auction_time: '',
      already_bid: '',
      products: [],
      documents: [
        {
          file_name: '',
          file_url: '',
        },
      ],
    },
  });
  const [openSidebar, setOpenSidebar] = useState<boolean>(false);
  const [isAuctionFinished, setIsAuctionFinished] = useState<boolean>(true);
  const [alreadyBid, setAlreadyBid] = useState(false);

  const fetchTender = async () => {
    try {
      const response = await httpGet<any>(`/tender/view?id=${id}&XDEBUG_SESSION_START=phpstorm`);
      const data = response?.data?.data;
      setValues({ advertisement: data });

      const already_bid = data?.already_bid;
      const offerDueDate = dayjs(data.offer_due_date);
      setAlreadyBid(already_bid);
      if (offerDueDate && dayjs(offerDueDate).isValid() && !already_bid && offerDueDate.isAfter(dayjs())) {
        return setIsAuctionFinished(false);
      }
      return setIsAuctionFinished(true);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchTender();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const BackBtn = () => {
    return (
      <div className={classes.header_content_container}>
        <button type="button" className={classes['back_btn']} onClick={() => navigate(-1)}>
          &#60; {t_global['back']}
        </button>
        {values?.advertisement?.already_bid && (
          <div className={classes.info}>
            <div className={classes.image_wrapper}>
              <img src={BidIconGreen} alt="Bid icon" />
            </div>
            {t_product.already_bid}
          </div>
        )}
      </div>
    );
  };

  return (
    <Page showBackBtn={BackBtn}>
      <div className={classes.ad_preview_wrapper}>
        <React.Fragment>
          <Header products={values} />
          <div className={classes.contact_info_wrapper}>
            <div className={classes.title}>{t_product.contact_person}</div>
            <div className={classes.info_wrapper}>
              <div className={classes.name}>{values.advertisement.contact_name}</div>
              <div className={classes.email}>{values.advertisement.contact_email}</div>
              <div className={classes.phone}>{values.advertisement.contact_phone}</div>
            </div>
          </div>
          <div className={classes.time_criteria_wrapper}>
            <div className={classes.title}>{t_product.time_criteria_title}</div>
            <div className={classes.content_wrapper}>
              <div className={classes.date_wrapper}>
                <div className={classes.title}>{t_product.delivery_deadline}</div>
                <div className={classes.date}>{values.advertisement.delivery_due_days}</div>
              </div>
              <div className={classes.criteria_wrapper}>
                <div className={classes.title}>{t_product.criteria_title}</div>
                <div className={classes.criteria}>{t_global.criteria[`criteria_${values.advertisement.criteria}`]}</div>
              </div>
            </div>
          </div>
          <div className={classes.auction_wrapper}>
            <div className={classes.title}>{t_product.auction}</div>
            <div className={classes.content_wrapper}>
              <div className={classes.auction_container}>
                <div className={classes.title}>{t_product.auction}</div>
                <div className={classes.on_auction}>{values.advertisement.auction ? t_global.yes : t_global.no}</div>
              </div>
              <div className={classes.delivery_deadline_wrapper}>
                <div className={classes.title}>{t_product.auction_date}</div>
                <div className={classes.date_time}>
                  {dayjs(values.advertisement.auction_start_date).isValid()
                    ? dayjs(values.advertisement.auction_start_date).format('DD.MM.YYYY')
                    : values.advertisement.auction_start_date}
                  <span className={classes.vertical_line}>|</span>
                  {dayjs(values.advertisement.auction_start_date).isValid() &&
                    dayjs(values.advertisement.auction_start_date).format('HH:mm')}
                  -
                  {dayjs(values.advertisement.auction_end_date).isValid() &&
                    dayjs(values.advertisement.auction_end_date).format('HH:mm')}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.admin_info_wrapper}>
            <div className={classes.title}>{t_product.administrative_info_title}</div>
            <div className={classes.content_wrapper}>
              <div className={classes.info_container}>
                <div className={classes.title}>{t_product.offers_submit}</div>
                <div className={classes.date_time}>
                  {dayjs(values.advertisement.offer_due_date).isValid()
                    ? dayjs(values.advertisement.offer_due_date).format('DD.MM.YYYY | HH:mm')
                    : values.advertisement.offer_due_date}
                </div>
              </div>
              <div className={classes.questions_wrapper}>
                <div className={classes.title}>{t_product.questions_dedline}</div>
                <div className={classes.date_time}>
                  {dayjs(values.advertisement.rok_prasanja_datum).isValid()
                    ? dayjs(values.advertisement.rok_prasanja_datum).format('DD.MM.YYYY')
                    : values.advertisement.rok_prasanja_datum}
                  <span className={classes.vertical_line}>|</span>
                  {values.advertisement.rok_prasanja_vreme}
                </div>
              </div>
              <div className={classes.answers_wrapper}>
                <div className={classes.title}>{t_product.answers_dedline}</div>
                <div className={classes.date_time}>
                  {dayjs(values.advertisement.rok_odgovori_datum).isValid()
                    ? dayjs(values.advertisement.rok_odgovori_datum).format('DD.MM.YYYY')
                    : values.advertisement.rok_odgovori_datum}
                  <span className={classes.vertical_line}>|</span>
                  {values.advertisement.rok_odgovori_vreme}
                </div>
              </div>
            </div>
          </div>
          {values?.advertisement.documents && values.advertisement.documents.length > 0 && (
            <div className={classes.tehnical_info_wrapper}>
              <div className={classes.title}>{t_product.tehnical_info_title}</div>
              <div className={classes.docs_wrapper}>
                {values.advertisement.documents.map((doc, index) => {
                  return (
                    <div key={index} className={classes.doc_wrapper}>
                      <div className={classes.icon}></div>
                      <div className={classes.file_name} onClick={() => download(`/ads/${id}/files/${doc.id}`)}>
                        {doc.file_name}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          <div className={classes.ads_wrapper}>
            <ProductList
              adId={id}
              products={values.advertisement.products}
              advertisement={values.advertisement}
              isAuctionFinished={isAuctionFinished}
            />
          </div>
        </React.Fragment>
      </div>

      {!isAuctionFinished && (
        <div className={classes.submit_offer}>
          <div className={classes.cancel_btn} onClick={() => navigate(-1)}>
            {t_global.cancel}
          </div>
          <div
            className={classes.submit_btn}
            onClick={() => {
              setOpenSidebar(true);
            }}
          >
            {t_product.submit_offer}
          </div>
        </div>
      )}

      {alreadyBid && (
        <div className={classes.submit_offer}>
          <div className={classes.submit_btn} onClick={() => navigate(`/my-offers/preview/${id}`)}>
            {t.advertisements.see_offers}
          </div>
        </div>
      )}
      {values.advertisement.products && openSidebar && (
        <SubmitOfferModal
          isPaneOpen={openSidebar}
          onSidebarClose={() => setOpenSidebar(false)}
          onConfirm={() => {
            fetchTender();
          }}
          products={values.advertisement.products}
          documents={values.advertisement.documents}
          advertisment={values.advertisement}
          deliveryDueDays={values.advertisement.delivery_due_days}
        />
      )}
    </Page>
  );
};

export default AdvertisementPreview;
