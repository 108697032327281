import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Badge } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import { NavLink } from 'react-router-dom';

import Page from '../../components/Layout/Page/Page';
import Filters from '../../components/Tools/Filters/Filters';
import { ISearchParameters, IPaginationParameters, ISortingParameters } from '../../components/Tools/Filters/model';
import { httpGet } from '../../services/http';
import { RootState } from '../../store';
import { RoutesEnum } from '../../enums/routes';

import Pencil from '../../assets/icons/Pencil.svg';

import classes from './Advertisements.module.scss';
import { TimeFormatter } from '../../components/Tools/TableTimeCountdownFormatter/TableTimeCountdownFormatter';
import { formatDate } from '../../utils/formatDate';
import { OfferStatusDisplay } from '../../components/OfferStatusDisplay';

const CustomActionCol = props => {
  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage];
  });

  const t_advertisements = t.advertisements;
  const t_global = t.global;
  const auction = props?.cell || false;
  const totalOffers = props?.row?.total_offer_count || 0;
  const pendingOffers = props?.row?.pending_offer_count || 0;
  const id = props?.row?.id || null;

  return (
    <>
      <span>{auction ? t_global.yes : t_global.no}</span>
      {totalOffers > 0 && (
        <NavLink to={`/my-advertisements/offers/${id}`}>
          {t_advertisements.see_offers}
          {pendingOffers > 0 && <Badge bg="primary">{pendingOffers}</Badge>}
        </NavLink>
      )}
    </>
  );
};

const Advertisements = (props: any) => {
  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage];
  });

  const t_advertisements = t.advertisements;
  const t_global = t.global;

  const [myAds, setMyAds] = useState<[]>([]);
  const [activeTab, setActiveTab] = useState<string>('active');
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [searchParameters, setSearchparameters] = useState<ISearchParameters>({
    searchValue: '',
    datumOd: '',
    datumDo: '',
    category_id: '',
  });
  const [pagination, setPagination] = useState<IPaginationParameters>({
    sizePerPage: 10,
    page: 1,
  });
  const [sorting, setSorting] = useState<ISortingParameters>({
    sortField: null,
    sortOrder: undefined,
  });
  const [adsCount, setAdsCount] = useState({
    active: 0,
    inactive: 0,
    all: 0,
  });
  const [totalData, setTotalData] = useState<number>(0);

  const onSearchChangeHandler = (params: ISearchParameters) => {
    setSearchparameters(params);
    const { page, sizePerPage } = pagination;
    const { sortField, sortOrder } = sorting;
    setTimeout(() => {
      fetchAds(page, sizePerPage, sortField, sortOrder, activeTab, params);
    }, 500);
  };

  const fetchAds = async (
    page = 1,
    sizePerPage = 10,
    sortField = null,
    sortOrder = undefined,
    selectedTab = 'active',
    filter = searchParameters
  ) => {
    setMyAds([]);
    setPagination({
      page,
      sizePerPage,
    });
    setLoadingData(true);
    try {
      const query = {
        page: page ?? 1,
        status: ['active', 'inactive'].includes(selectedTab) ? `&status=${selectedTab}` : '',
        size: sizePerPage ?? 10,
        search: filter.searchValue ? `&search=${filter.searchValue}` : '',
        order: sortField && sortOrder ? `&sortBy=${sortField}&sortOrder=${sortOrder}` : '',
        category: filter.category_id ? `&industryId=${filter.category_id}` : '',
      };

      const response = await httpGet<any>(
        `/ads?own=1&offers=1${query.status}&page=${query.page}&size=${query.size}${query.search}${query.order}${query.category}`
      );
      setMyAds(response.data.data);
      setTotalData(response.data.total);
      setLoadingData(false);
      setAdsCount(state => ({
        ...state,
        [selectedTab]: response.data.total,
      }));
    } catch (error) {
      console.error(error);
      setLoadingData(false);
    }
  };

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    fetchAds(page, sizePerPage, sortField, sortOrder, activeTab);
  };

  const onTabChange = tab => {
    setActiveTab(tab);
    const { sizePerPage } = pagination;
    setSorting({ sortField: null, sortOrder: undefined });
    setPagination({
      page: 1,
      sizePerPage,
    });
    fetchAds(1, sizePerPage, null, undefined, tab);
  };

  const tableColumns = [
    {
      dataField: 'id',
      text: t_advertisements.offer_num,
      formatter: (cell, row) => {
        return (
          <NavLink to={`${RoutesEnum.Advertisements}/${row.id}`} className={classes.AdLink}>
            <img src={Pencil} alt="Pencil icon" />
            <span>{cell}</span>
          </NavLink>
        );
      },
      sort: true,
    },
    {
      dataField: 'created_at',
      text: t_global.creation_date,
      formatter: cell => formatDate(cell),
      sort: true,
    },
    {
      dataField: 'title',
      text: t_advertisements.offer_name,
      sort: true,
    },
    {
      dataField: 'industry.name',
      text: t_advertisements.categories,
      sort: true,
    },
    {
      dataField: 'auction_start_date',
      text: t_global.start,
      formatter: (cell, row) => {
        if (!row.aukcija) return <div style={{ textAlign: 'center' }}>-</div>;

        return TimeFormatter(cell);
      },
      sort: true,
    },
    {
      dataField: 'auction_end_date',
      text: t_advertisements.closure,
      formatter: (cell, row) => {
        if (!row.aukcija) return <div style={{ textAlign: 'center' }}>-</div>;

        return TimeFormatter(cell);
      },
      sort: true,
    },
    {
      dataField: 'aukcija',
      text: t_advertisements.auction,
      formatter: (cell, row) => <CustomActionCol row={row} cell={cell} />,
      sort: true,
    },
    {
      dataField: 'status',
      text: t_global.status,
      formatter: (cell, row) => <OfferStatusDisplay status={cell} />,
      sort: true,
    },
  ];

  const customCaret = (order, column) => {
    if (!order) {
      return (
        <span style={{ display: 'inline-block', position: 'relative', top: '-5px' }}>
          <span className={classes.sort_wrapper}>
            <span className={`${classes.caret} ${classes.caret_up}`}></span>
            <span className={classes.caret}></span>
          </span>
        </span>
      );
    } else if (order === 'asc') {
      return (
        <span style={{ display: 'inline-block', position: 'relative', top: '-2px' }}>
          <span className={classes.sort_wrapper}>
            <span className={`${classes.caret} ${classes.caret_up}`}></span>
          </span>
        </span>
      );
    } else if (order === 'desc') {
      return (
        <span style={{ display: 'inline-block', position: 'relative', top: '-2px' }}>
          <span className={classes.sort_wrapper}>
            <span className={classes.caret}></span>
          </span>
        </span>
      );
    } else {
      return null;
    }
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {`${t_global['table_total_text']} ${from} ${t_global['table_total_text_1']} ${to} ${t_global['table_total_text_2']} ${size} ${t_global['table_total_text_3']}`}
    </span>
  );

  const paginationOptions = {
    custom: true,
    totalSize: totalData,
    page: pagination.page,
    sizePerPage: pagination.sizePerPage,
    paginationSize: 4,
    pageStartIndex: 1,
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    hideSizePerPage: false,
    hidePageListOnlyOnePage: false,
    firstPageText: '<<',
    prePageText: '<',
    nextPageText: '>',
    lastPageText: '>>',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
    ],
  };

  return (
    <React.Fragment>
      <Page>
        <Filters onSearchChange={onSearchChangeHandler} advertisements={myAds} title={t_advertisements.my_ads} />
        <div className={classes.table_wrapper}>
          <div className={classes.buttons_wrapper}>
            <div
              className={`${classes.button} ${activeTab === 'active' ? classes.active : ''}`}
              onClick={() => {
                if (!loadingData) {
                  onTabChange('active');
                }
              }}
            >
              {t_advertisements['active']}{' '}
              {activeTab === 'active' && !loadingData && <Badge bg="primary">{adsCount.active}</Badge>}
            </div>
            <div
              className={`${classes.button} ${activeTab === 'inactive' ? classes.active : ''}`}
              onClick={() => {
                if (!loadingData) {
                  onTabChange('inactive');
                }
              }}
            >
              {t_advertisements['finished']}{' '}
              {activeTab === 'inactive' && !loadingData && <Badge bg="primary">{adsCount.inactive}</Badge>}
            </div>
            <div
              className={`${classes.button} ${activeTab === 'all' ? classes.active : ''}`}
              onClick={() => {
                if (!loadingData) {
                  onTabChange('all');
                }
              }}
            >
              {t_advertisements['all']}{' '}
              {activeTab === 'all' && !loadingData && <Badge bg="primary">{adsCount.all}</Badge>}
            </div>
          </div>
          <div className={classes.table_container}>
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
              {({ paginationProps, paginationTableProps }) => (
                <div>
                  <BootstrapTable
                    keyField="id"
                    remote={{
                      pagination: true,
                    }}
                    onTableChange={onTableChange}
                    data={myAds}
                    columns={tableColumns}
                    classes={classes.my_ads_table}
                    noDataIndication={loadingData ? t_global.loading : t_global.no_data}
                    sort={{
                      sortCaret: customCaret,
                    }}
                    {...paginationTableProps}
                  />
                  <div className={classes.pagination_wrapper}>
                    <PaginationTotalStandalone {...paginationProps} />
                    <div>
                      <PaginationListStandalone {...paginationProps} />
                      <SizePerPageDropdownStandalone {...paginationProps} />
                    </div>
                  </div>
                </div>
              )}
            </PaginationProvider>
          </div>
        </div>
      </Page>
    </React.Fragment>
  );
};

export default Advertisements;
