import { forwardRef, TextareaHTMLAttributes } from 'react';
import classes from './TextArea.module.scss';

interface BaseInputProps {
  label: string;
  error?: string;
}
interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement>, BaseInputProps {}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(({ label, error, ...props }, ref) => {
  return (
    <div className={[classes.input_wrapper, error && classes.has_error].filter(Boolean).join(' ')}>
      <label>{label}</label>
      <textarea ref={ref} {...props} />
      <div className={classes.error}>{error}</div>
    </div>
  );
});
