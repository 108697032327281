import React from 'react';
import classes from './Verification.module.scss';

const Verification = () => {
  return (
    <React.Fragment>
      <div className={`${classes.CancelContainer} text-center`}>
        <p>Профилот е успешно верификуван. Продолжете со најава</p>
      </div>
    </React.Fragment>
  );
};

export default Verification;
