const pattern = /^[A-Za-z0-9+/]+={0,2}$/;

export function isBase64(value: string) {
  const content = value.replace(/^data:image\/\w+;base64,/, '');

  if (content.length % 4 !== 0) {
    return false;
  }

  if (!pattern.test(content)) {
    return false;
  }

  try {
    atob(content);
  } catch {
    return false;
  }

  return true;
}
