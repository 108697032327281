import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../components/UI/Button/Button';
import CustomEditor from '../../../components/UI/Editor/Editor';
import { IFile } from '../../../components/UI/Upload/UploadButton/model/UploadButton.model';
import UploadedFile from '../../../components/UI/Upload/UploadButton/UploadedFileList/UploadedFile';
import { IBaseField } from '../../../models/field-validation.model';
import { RootState } from '../../../store';
import classes from './ChatInput.module.scss';
import { IChatProps } from './model';

const ChatInput = (props: IChatProps) => {
  const [editorRef, setEditorRed]: any = useState(null);

  const [files, setFiles] = useState<IFile[]>([]);

  const translations = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage]['chat'];
  });

  const [field, setField] = useState({
    value: '',
    status: {
      $dirty: false,
      $touched: false,
      $error: null,
    },
    name: 'message_input',
  } as IBaseField<string>);

  const [textLength, setTextLength] = useState<number>(0);

  useEffect(() => {
    if (props?.chatChanged) {
      setFiles([]);
      resetField();
      setTextLength(0);
    }
  }, [props?.chatChanged]);

  const onInputChangeHandler = (value, editor_ref) => {
    setField(() => {
      return {
        ...field,
        value: value,
      };
    });
    setEditorRed(editor_ref);
  };

  const resetField = () => {
    setField(state => {
      return {
        ...field,
        value: '',
      };
    });
  };

  const sendMessageHandler = (value = '', totalCharacters = textLength) => {
    if (!totalCharacters && !files.length) return;

    let text = '';

    if (value.length > 0) {
      text = value.trim();
    } else if (field.value.length > 0) {
      text = field.value.trim();
    }

    props.onMessageSend(text, files);

    setTimeout(() => {
      resetField();
      setFiles([]);
      setTextLength(0);
    });
    if (editorRef) {
      editorRef.current.focusEditor();
    }
  };

  const onFileUploadHandler = (files: File[]) => {
    if (files.length) {
      setFiles((state: any) => {
        return [...state, ...files];
      });
    }
  };

  const onFileRemoveHandler = index => {
    setFiles(files => {
      let newFiles = [...files];
      newFiles.splice(index, 1);

      return newFiles;
    });
  };

  return (
    <React.Fragment>
      <div className={classes.Container}>
        <CustomEditor
          field={field}
          placeholder={translations['Пополни порака']}
          maxCharacters={1000}
          small={props.small}
          borderless
          showDropArea={false}
          onValueChange={(value, editorRef) => onInputChangeHandler(value, editorRef)}
          textLength={text_length => setTextLength(text_length)}
          onFileUpload={onFileUploadHandler}
          removeFiles={files.length === 0}
          files={files.length}
          sendMessage={sendMessageHandler}
        />
        <div className={classes.chat_footer_wrapper}>
          {files.length > 0 && (
            <div className={classes.UploadedFilesContainer}>
              {files?.map((file, index) => {
                return (
                  <UploadedFile file={file} key={index} chatOneLine removeFile={() => onFileRemoveHandler(index)} />
                );
              })}
            </div>
          )}

          <div className={classes.Send}>
            <Button
              label={translations['Испрати']}
              onClick={sendMessageHandler}
              disabled={files.length === 0 && textLength === 0}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ChatInput;
